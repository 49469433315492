import { action, observable } from 'mobx';

import AppConsts from './../lib/appconst';
import LoginModel from '../models/Login/loginModel';
import tokenAuthService from '../services/tokenAuth/tokenAuthService';
import { Roles } from '../services/domain/enums';
import { jwtCheckIfExpired } from '../utils/authUtils';

declare var abp: any;

class AuthenticationStore {
  @observable loginModel: LoginModel = new LoginModel();

  get isAuthenticated(): boolean {
    if (!abp.session.userId) return false;

    return true;
  }

  get isLoggedIn(): boolean {
    let token = null;
    try { 
      token = abp.utils.getCookieValue(abp.auth.tokenCookieName);

      if (typeof token === 'string' && !jwtCheckIfExpired(token)) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  @action
  public async login(model: LoginModel) {
    let result = await tokenAuthService.authenticate({
      userNameOrEmailAddress: model.userNameOrEmailAddress,
      password: model.password,
      rememberClient: model.rememberMe,
    });
    if (result.roles.includes(Roles.Parent) || result.roles.includes(Roles.Player)) {

      // todo Add Fluent Modal 
      // console.error(L('SportingDuetAcademyPersonnelOnly'))
      // Modal.error({ title: L('PrivateArea'), content: L('SportingDuetAcademyPersonnelOnly') });
    }
    else {
      var tokenExpireDate = model.rememberMe ? new Date(new Date().getTime() + 1000 * result.expireInSeconds) : undefined;
      abp.auth.setToken(result.accessToken, tokenExpireDate);
      abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, result.encryptedAccessToken, tokenExpireDate, abp.appPath);
    }

    return result.roles
  }

  @action
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    abp.auth.clearToken();
    abp.auth.clearRefreshToken();
    abp.utils.deleteCookie(abp.auth.tokenCookieName, abp.appPath);
  }
}

export default AuthenticationStore;

const authStore = new AuthenticationStore();
export { authStore };