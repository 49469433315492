import { Icon, mergeStyleSets, SearchBox, ThemeProvider } from '@fluentui/react';
import { L } from '../../lib/abpUtility';
import AppConsts from '../../lib/appconst';
import { CountryFluentListDto } from '../../services/country/countryFluentListDto';
import { fluentTableClassNames } from '../../styles/fluentTableStyles';
import { myTheme } from '../../styles/theme';
import { FluentTableBase } from '../Fluent/base/fluentTableBase';
import { ICrudPermissons } from './commandBarBase';
import { ITableColumn } from './ITableColumn';
import { LabelComponent } from './labelComponent';
import { LabelContainerComponent } from './labelContainerComponent';

var _ = require('lodash');

const classNames = mergeStyleSets({
  requiredMarker: {
      color: 'rgb(164, 38, 44)',
      marginLeft: '5px',
  }
});

export class CountryFluentListBase extends FluentTableBase<CountryFluentListDto> {
  disableGetAllOnMount = (this.props.customData && this.props.customData.disableGetAllOnMount && this.props.customData.disableGetAllOnMount === true) ? true : false;

  private debouncedOnSearchboxChange: any = _.debounce((e: any, newValue: string | undefined, customPayload?: any) => {
    newValue = typeof newValue === 'undefined' || newValue.length === 0 ? " " : newValue;
    if(this.props.customOnSearchTextChanged) {
      this.props.customOnSearchTextChanged(newValue);
    } else {
      this.overrideFilter(newValue);
    }
  }, AppConsts.defaultSerachBarDelay, []);

  getItemDisplayNameOf(item: CountryFluentListDto): string {
    if(!!item.name) {
      return item.name;
    } else if(!!item.code) {
      return `${item.code}`; 
    } else {
      return item.id;
    }
  }

  getColumns(): ITableColumn[] {
    return this.getTableColumns(this.props);
  }

  private getTableColumns(props: any): ITableColumn[] {
    return [          
      {
        name: L('Name'),
        fieldName: 'name',
        isSortedDescending: false,
      },
      // {
      //   name: L('Code'),
      //   fieldName: 'code',
      // },
    ];
  }

  getCrudPermission(): ICrudPermissons {
    return {
      create: false,
      update: false,
      delete: false,
      customActions: false,
    };
  }

  renderAll(pageInfo: string, values: any, columns: any) {
    return <>
      <LabelContainerComponent marginTop={'0'}>
        <LabelComponent label={L('Select countries')} />
        <SearchBox
          theme={myTheme}
          styles={{
            root: {
              flex: 1,
              maxWidth: '252px',
              height: '32px', 
              backgroundColor: myTheme.palette.white,
              border: `1px solid ${myTheme.palette.black}`,
              boxSizing: 'border-box',
            },
            field: { borderRadius: '2px' },
          }}
          placeholder={ L('Search') }
          onChange={ (e: any, newValue: string | undefined) => {
            this.debouncedOnSearchboxChange(e, newValue);
          }}
        />
        {(this.props.customData.required && this.props.customData.required === true) && <span className={classNames.requiredMarker}>*</span>}

        {(this.props.customData.selectedItems && typeof this.props.customData.selectedItems !== 'undefined') &&
          <div className={fluentTableClassNames.summaryAttributeWrapper}>
            {(Array.isArray(this.props.customData.selectedItems)) &&
                this.props.customData.selectedItems.map((item: any) => {
                  return <p className={fluentTableClassNames.summaryAttribute}>
                    <span>{item.name ? item.name : ''}</span>
                    <Icon iconName="Cancel" style={{marginRight: '0', marginLeft: '10px', cursor: 'pointer'}} title={L("Delete")} 
                      onClick={() => { if(this.props.customOnSelectionChanged) this.props.customOnSelectionChanged({action: 'deleteCountry', id: item.id}) }} />
                  </p>
                }
              )
            }
          </div>
        }
      </LabelContainerComponent>

      <ThemeProvider theme={myTheme}>
        {this.renderAnnounced(pageInfo)}

        {this.renderListScrollablePane(values, columns)}
      </ThemeProvider>
    </>;
  }
}