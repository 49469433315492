import { CrudStoreBase } from './crudStoreBase';
import { AgentClaimHistoryDto } from '../services/agentClaim/dto/agentClaimHistoryDto';
import AgentClaimHistoryService from '../services/agentClaim/agentClaimHistoryService';

class AgentClaimHistoryStore extends CrudStoreBase<AgentClaimHistoryDto>{
  constructor() {
    super(AgentClaimHistoryService, defaultAgentClaimHistory)
  }

    public async getByClaimId(claimId: string) {
        await AgentClaimHistoryService.getByClaimId(claimId).then((result: any) => {
            this.dataSet = {
                totalCount: result.items.length, 
                items: result.items
            };
        }).catch((error: any) => {
            console.error(error);
        });
    }

    public async getAll(request: any) {
        if(request && request.claimId) {
            return this.getByClaimId(request.claimId);
        }
    }

    public async create(createClaimHistoryInput: AgentClaimHistoryDto) {
		return AgentClaimHistoryService.create(createClaimHistoryInput);
	}
}

export const defaultAgentClaimHistory = {
    id: '',
    claimId: 0,
    status: '',
    userId: 0,
    note: '',
    noteDate: '',
    lastModificationTime: '',
    lastModifierUserId: 0,
    creationTime: '',
    creatorUserId: 0,
}
export default AgentClaimHistoryStore;