import AppConsts from "../lib/appconst";

const today = new Date();
export const AboutAppText = `©${today.getFullYear()} A-Soft  ver. ${AppConsts.version}`;
export const HeaderHeight = '60px';
export const FooterHeight = '40px';
export const SiderMargin = '60px';
export const SiderWidth = '256px';
export const SiderWidthWithMargines = '376px';
export const LogoHeight = '2.5rem';
