import { CrudStoreBase } from './crudStoreBase';
import { OcTerminationDto } from '../services/ocTermination/dto/ocTerminationDto';
import ocTerminationService from '../services/ocTermination/ocTerminationService';
import { OcTerminationCreationWay } from '../services/ocTermination/dto/ocTerminationCreationWayEnums';
import { getPartialModel } from '../utils/modelUtils';
import { defaultUser } from './userCrudStore';
import { OcTerminationReason } from '../services/calculation/dto/ocTerminationReasonEnums';
import { defaultClient } from './clientStore';

class OcTerminationStore extends CrudStoreBase<OcTerminationDto>{
  constructor() {
    super(ocTerminationService, defaultOcTermination)
  }

  // public async getAll(request: any) {
  //   return ocTerminationService.getTerminationOCContractList(request);
  // }

  public async create(request: any) {
    const filteredRequest: any = getPartialModel(request, [], ['id', 'agentId', 'address', 'agent', 'client', 'creationTime', 'isSended', 'linkToFile', 'peselRegon', 'policyId', 'status']);

    return ocTerminationService.generateTerminationOcContract(filteredRequest);
  }
}

export const defaultOcTermination = {
  id: '',
  agent: defaultUser,
  agentId: 0,
  client: defaultClient,
  clientId: 0,
  registrationNumber: '',
  status: '',
  linkToFile: '',
  isSended: false,
  creationWay: OcTerminationCreationWay.Manual,
  policyId: 0,
  clientFullName: '',
  address: '',
  peselRegon: '',
  insurerName: '',
  policyNumber: '',
  terminationGround: OcTerminationReason.After_vehicle_acquiring,
  terminationDate: '',
  creationTime: '',
  oldInsurerName: '',
  oldPolicyNumber: '',
}

export default OcTerminationStore;