import { IsTenantAvaibleInput } from './dto/isTenantAvailableInput';
import { RegisterInput } from './dto/registerInput';
import { RegisterCustomerDto } from './dto/registerCustomerDto';
import IsTenantAvaibleOutput from './dto/isTenantAvailableOutput';
import { RegisterOutput } from './dto/registerOutput';
import http from '../httpService';
import { EntityDto } from '../dto/entityDto';
import { RequestResetPassword } from './dto/requestResetPassword';
import ResetPasswordInput from './dto/resetPasswordInput';

class AccountService {
  public async isTenantAvailable(isTenantAvaibleInput: IsTenantAvaibleInput): Promise<IsTenantAvaibleOutput> {
    let result = await http.post('api/services/app/Account/IsTenantAvailable', isTenantAvaibleInput);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async register(registerInput: RegisterInput): Promise<RegisterOutput> {
    let result = await http.post('api/services/app/Account/Register', registerInput);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }
  
  public async searchByNip(nip: string): Promise<any> {
    let result = await http.post('api/services/app/Account/ValidateNip', { Nip: nip });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async registerCustomer(registerInput: RegisterCustomerDto): Promise<RegisterCustomerDto> {
    let result = await http.post('api/services/app/Account/Register', registerInput);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getCustomer(entityDto: EntityDto): Promise<RegisterCustomerDto> {
    let result = await http.get('api/services/app/Account/Profile', { params: entityDto });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async updateCustomer(registerInput: RegisterCustomerDto): Promise<RegisterCustomerDto> {
    let result = await http.put('api/services/app/Account/Profile', registerInput);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async confirmEmail(search: string) {
    await http.post(`api/services/app/Account/ConfirmEmail${search}`);
  }

  public async requestResetPassword(email: RequestResetPassword) {
    await http.post(`api/services/app/Account/RequestResetPassword`, email);
  }

  public async resetPassword(input: ResetPasswordInput) {
    await http.post(`api/services/app/Account/ResetPassword`, input);
  }

  public async getHtml() {
    var html = await http.get(`/html/h1.html`);
    return html.data;
  }

  public async getSettings(userId: number): Promise<any> {
    let result = await http.get(`api/services/app/Account/GetSettings?userId=${userId}`);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getMobileAgreements(userId: number): Promise<any> {
    let result = await http.get(`api/services/app/Account/getMobileAgreements?userId=${userId}`);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }
}

export default new AccountService();
