import { PrizeDto } from '../services/prize/dto/prizeDto';
import PrizeService from '../services/prize/prizeService';
import { CrudStoreBase } from './crudStoreBase';

class PrizeStore extends CrudStoreBase<PrizeDto>{
  constructor() {
    super(PrizeService, defaultPrize)
  }

  public async create(createUserInput: PrizeDto) {
    return PrizeService.create(createUserInput);
  }

  public resetDataSet() {
    this.dataSet = {
        totalCount: 0,
        items: [],
    };
  }
}

export const defaultPrize = {
  id: '',
  name: '',
  imageUrl: '',
  isEnabled: false,
}

export default PrizeStore;