import { CrudServiceBaseTransformed } from '../base/crudServiceBaseTransformed';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { LanguageDto } from './languageDto';

export class LanguageService extends CrudServiceBaseTransformed<LanguageDto> {
    constructor() {
        super(Endpoint.Language);
        this.internalHttp = httpApi;
    }
}

export default new LanguageService();