import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { UserDto } from './dto/userDto';

export class UserService extends CrudServiceBase<UserDto> {
    constructor() {
        super(Endpoint.User);
    }
}

export default new UserService();