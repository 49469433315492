import ClientContestService from "../services/clientContest/clientContestService";
import { ClientContestDto } from "../services/clientContest/dto/clientContestDto";
import { CrudStoreBase } from "./crudStoreBase";

class ClientContestStore extends CrudStoreBase<ClientContestDto> {
    constructor() {
        super(ClientContestService, defaultClientContest);
    }

    public async update(updateUserInput: ClientContestDto) {
        return ClientContestService.update(updateUserInput);
    }

    public async getAll(request: any) {
        if(request && request.customerId) {
            return this.getByClientId(request.customerId);
        }
    }

    public async getByClientId(customerId: number) {
        await ClientContestService.getByClientId(customerId).then((result: any) => {
			this.dataSet = result;
		}).catch((error: any) => {
			console.error(error);
		});
	}

    public async getDetails(id: number) {
		await ClientContestService.getDetails(id).then((result: any) => {
			this.dataSet = {
				totalCount: result.totalCount,
				items: result.items
			};
		}).catch((error: any) => {
			console.error(error);
		});
	}

    public resetDataSet() {
        this.dataSet = {
            totalCount: 0,
            items: [],
        };
    }
}

export const defaultClientContest = {
    clientId: 0,
    agreement: false,
    contestId: null,
    contest: null,
    isReceived: false,
    isPrizeSentToClient: false,
    receivedPrizeId: null,
    receivedPrize: null,
    isConditionsMeet: false,
    policyId: null,
    policy: null,
    prizeNumber: "",
    postDate: "",
    isParticipates: false,
    oloPoints: 0,
    id: "",
}

export default ClientContestStore;