const config = (window as any)._app_;
const AppConfig = {
    defaultLoadDataTimeout: config.LOAD_DATA_TIMEOUT,
    defaultLoadDataInterval: config.LOAD_DATA_INTERVAL,
    pagePathToIcon: config.PAGE_PATH_TO_ICON_PAIRS,
    calculationAllSteps: config.CALCULATION_ALL_STEPS,
    policyFormAllSteps: config.POLICY_FORM_ALL_STEPS,
    policyFormInsurerDataTabIndex: config.POLICY_FORM_INSURER_DATA_TAB_INDEX,
    apkFormAllSteps: config.APK_FORM_ALL_STEPS,
    tabNumberForCustomInputsInMotorInsurance: config.TAB_NUMBER_FOR_CUSTOM_INPUT_IN_MOTOR_INSURANCE,
    eurotaxCollectDataMaxConnectionErrorsCount: config.EUROTAX_COLLECT_DATA_MAX_CONNECTION_ERRORS_COUNT,
    countOfSuggestedCountriesInDropdown: config.COUNT_OF_SUGGESTED_COUNTRIES_IN_DROPDOWN,
    testsAdminPassword: config.TESTS_ADMIN_PASSWORD,
    uniqaNnwStep3InsuranceOptionsPdfUrl: config.UNIQA_NNW_STEP_3_INSURANCE_OPTIONS_PDF_URL,
    generaliNnwStep3InsuranceOptionsPdfUrl: config.GENERALI_NNW_STEP_3_INSURANCE_OPTIONS_PDF_URL,
};
export default AppConfig;