import { Route, Switch, withRouter } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import utils from '../../utils/utils';
import { hasPermissionsToPage, isUserLoggedIn } from '../../utils/authUtils';
import { useEffect } from 'react';

const Router = ({ history }: {history: any}) => {
  const UserLayout = utils.getRoute('/user').component;
  const AppLayout = utils.getRoute('/').component;

  useEffect(() => {
    history.listen((location: any, action: any) => {
      // location is an object like window.location // console.info(action, location.pathname, location.state);
      isUserLoggedIn();

      if(!hasPermissionsToPage()) {
        window.location.href = '/exception';
      }
    });
    // eslint-disable-next-line
  }, []);

  const pathsToProtect: string[] = ["/account", "/user", "/apk-file", "/policy-calculation-apk", "/apk-file/SendPasswordBySms", "/policy-calculation-apk/SendPasswordBySms", "/policy/SendPasswordBySms"];

  return (
    <Switch>
      <ProtectedRoute exact path={pathsToProtect} render={(props: any) => <AppLayout {...props} exact />} />

      <Route path={["/account", "/user", "/apk-file/:uuid", "/policy-calculation-apk/:uuid", "/policy/:uuid"]} render={(props: any) => <UserLayout {...props} />} />
      <ProtectedRoute path="/" render={(props: any) => <AppLayout {...props} exact />} />
    </Switch>
  );
};

export default withRouter(Router);
