import { TestSetDto } from './dto/testSetDto';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';

export class TestSetService extends CrudServiceBase<TestSetDto> {
  constructor() {
    super(Endpoint.TestSet);
    this.internalHttp = httpApi;
  }

  public async getHistory(entityDto: TestSetDto): Promise<TestSetDto> {
    let result = await this.internalHttp.get(this.endpoint.Custom(`GetHistory?Id=${entityDto.id}`));
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<TestSetDto>> {
    let result = await this.internalHttp.get(Endpoint.TestSet.GetAll(), { params: pagedFilterAndSortedRequest });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async get(entityDto: TestSetDto): Promise<TestSetDto> {
    let result = await this.internalHttp.get(this.endpoint.Custom(`Get?Id=${entityDto.id}`));
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async create(createUserInput: TestSetDto) {
    let result: any = {};
    let userInputClone: any = {...createUserInput};
  
    delete userInputClone['id'];
    delete userInputClone['lastTestDate'];
  
    result = await this.internalHttp.post(Endpoint.TestSet.Create(), userInputClone as TestSetDto);
        
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async sendTestCalculation(sendTestId: number) {
    let result = await this.internalHttp.put(this.endpoint.Custom(`SendTestCalculation?testSetId=${sendTestId}`));
    return result;
}
}

export default new TestSetService();