import { MessageBarType } from '@fluentui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import FloatingMessageBarDismissable, { AsyncActionMessage } from '../../scenes/BaseComponents/floatingMessageBarDismissable';
import { AzureB2CStorage } from '../../scenes/Login/AzureB2C/azureResponse';
import { AzureB2CStorageKey } from '../../scenes/Login/AzureB2C/signInButton';
import { Container } from '../../stores/storeInitializer';

declare var abp: any;

const ProtectedRoute = observer(({ path, component: Component, permission, render, ...rest }: any) => {
  const [asyncActionMessage, setAsyncActionMessage] = useState<AsyncActionMessage>({ text: "", show: false, type: MessageBarType.info });

  if(!!Container.EventBus.HttpError && Container.EventBus.HttpError !== asyncActionMessage.text) {
    setAsyncActionMessage({ 
      text: !!Container.EventBus.HttpError ? Container.EventBus.HttpError : "", 
      show: !!Container.EventBus.HttpError ? true : false, 
      type: MessageBarType.error,
    });
    
    setTimeout(() => {
      Container.EventBus.setHttpError(undefined);
    }, 1000);
  }

  return (
    <Route
      {...rest}
      render={props => {
        let json = localStorage.getItem(AzureB2CStorageKey);
        if (json) {
          let obj: AzureB2CStorage = JSON.parse(json) as AzureB2CStorage;
          abp.session.userId = obj.user.id;
        }
        let componentRendered = Component ? <Component {...props} /> : render(props);

        if (abp.session.userId) {
          return <>
            <FloatingMessageBarDismissable displayOnTheRight={true} text={asyncActionMessage.text} show={asyncActionMessage.show}
              type={asyncActionMessage.type} addOutline={true}
              onShowChange={(value: boolean) => {
                setAsyncActionMessage({ ...asyncActionMessage, show: value });
              }}
            />

            {componentRendered}
          </>;
        }

        return <>
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        </>
      }}
    />
  );
});

export default ProtectedRoute;