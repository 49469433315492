import { IDropdownOption } from '@fluentui/react';

export const Roles = {
    Coach: "Coach",
    ProductList: "ProductList",
    Parent: "Parent",
    Player: "Player",
    Options: (): IDropdownOption[] => { return [Roles.Player, Roles.Coach, Roles.ProductList, Roles.Parent].map(Enums.mapToOption) }
}

export class Enums {

    static pointInTypeOptions: IDropdownOption[] = [];
    static GetPointInTypeOptions(): IDropdownOption[] {
        return Enums.pointInTypeOptions;
    }

    static statusOptions: IDropdownOption[] = [];
    static GetStatusOptions(): IDropdownOption[] {
        return Enums.statusOptions;
    }

    static qualificationOptions: IDropdownOption[] = [];
    static GetQualificationOptions(): IDropdownOption[] {
        return Enums.qualificationOptions;
    }

    static squadCategoryOptions: IDropdownOption[] = [];
    static GetSquadCategoryOptions(): IDropdownOption[] {
        return Enums.squadCategoryOptions;
    }
    static mapToOption(item: string): IDropdownOption {
        return {
            key: item,
            text: item
        }
    }
}