import { action, observable } from 'mobx';

class EventStore {
    @observable Event!: any
    @observable HttpError!: any
    @observable customErrorHandling: any;

    public getErrorMessageFor(id?: string | undefined) {
        let message = undefined;
        if (this.Event) {
            let currentEvent = this.Event[id || ""] || { errors: [] };
            let error = currentEvent.errors[0];
            message = error ? error.message : undefined;
        }
        return message;
    }

    @action
    setFormError(event: any) {
        this.Event = event;
    }

    @action
    clearFormError() {
        this.Event = undefined;
    }

    @action
    setHttpError(errorObj: any) {
        this.HttpError = typeof errorObj !== 'undefined' && (errorObj.title || errorObj.content) ? `${(errorObj.title || "")}${(errorObj.content ? '\n\r'+errorObj.content : "")}` : undefined;
    }

    @action
    clearHttpError() {
        this.HttpError = undefined;
    }
}
export default EventStore;