import * as React from 'react';
import { ComboBox, IComboBox, IComboBoxOption, ITextFieldProps, mergeStyleSets } from '@fluentui/react';
import { inject } from 'mobx-react';
import Stores from '../../stores/storeIdentifier';
import CountryStore from '../../stores/countryStore';
import { getSourceDataFromUserFields } from '../../utils/storeUtils';
import LanguageStore from '../../stores/languageStore';
import { getGrandNodeLanguage } from '../../utils/languageUtils';
import { LabelContainerComponent } from './labelContainerComponent';
import { LabelComponent } from './labelComponent';
import { filterBySome } from '../../utils/utils';
import { L } from '../../lib/abpUtility';

const classNames = mergeStyleSets({
    comboBoxStyles: {
        width: '300px',
    },
    requiredMarker: {
        color: 'rgb(164, 38, 44)',
        marginLeft: '5px',
    }
});

export interface ICountriesComboBoxProps extends ITextFieldProps {
    key: string;
    label: string;
    inputText?: string;
    value?: string;
    countryStore?: CountryStore;
    languageStore?: LanguageStore;
    errorMessage?: string;
    disabled?: boolean;
    required?: boolean;
    allowFreeform?: boolean;
    asyncActionInProgress?: boolean;
    onInputChange: (id: string | number | undefined, value: any) => void,
    getCountryId?:(value: string) => void,
    validationData?: any;
    labelContainerCustomStyles?: any;
    customLabelStyles?: any;
}

type ICountriesComboBoxState = { 
    countryOptions: IComboBoxOption[],
    errorMessage: string,
    gnLanguage: any,
    removePresettedValue: boolean,
};

@inject(Stores.LanguageStore)
@inject(Stores.CountryStore)
export class CountriesComboBox extends React.Component<ICountriesComboBoxProps, ICountriesComboBoxState> {
    constructor(props: ICountriesComboBoxProps) {
        super(props);
        
        this.state = {
            ...this.state,
            countryOptions: [] as IComboBoxOption[],
            errorMessage: '',
            gnLanguage: undefined,
            removePresettedValue: false,
        };
    }

    async componentDidMount() {
        if(!this.props.languageStore!.dataSet || this.props.languageStore!.dataSet.totalCount <= 0) {
            await this.props.languageStore?.getAll(this.props.languageStore?.defaultRequest);
        }
        let gnLanguage = getGrandNodeLanguage(this.props.languageStore?.dataSet);

        if(!this.props.countryStore!.dataSet || this.props.countryStore!.dataSet.items.length <= 0) {
			await this.props.countryStore!.getAll(this.props.countryStore!.defaultRequest);
		}

        let allCountriesOptions: IComboBoxOption[] = getSourceDataFromUserFields(this.props.countryStore, "Name", gnLanguage, this.props.value, 'comboBox', 'Name', true, false);

        if(typeof this.props.getCountryId !== 'undefined') {
            this.setIdOfPoland(allCountriesOptions);
        }

        this.setState({ countryOptions: allCountriesOptions, gnLanguage: gnLanguage });
    }

    componentDidUpdate(prevProps: Readonly<ICountriesComboBoxProps>, prevState: Readonly<ICountriesComboBoxState>, snapshot?: any): void {
        if(this.state.countryOptions.filter(x => x.selected).length === 0 && this.props.value) {
            let allCountriesOptions: IComboBoxOption[] = getSourceDataFromUserFields(this.props.countryStore, "Name", this.state.gnLanguage, this.props.value, 'comboBox', 'Name', true, false);

                if(allCountriesOptions && allCountriesOptions.find(x => x.selected)) {
                    if(typeof this.props.getCountryId !== 'undefined') {
                        this.setIdOfPoland(allCountriesOptions);
                    }
        
                    this.setState({ countryOptions: allCountriesOptions });
                }
        } 
        else if(!!this.props.value && (!this.state.countryOptions || this.state.countryOptions.length === 0)) {
            let filteredCountryOption: any = filterBySome(this.state.countryOptions, 'selected', true);

            if(!filteredCountryOption || !filteredCountryOption.key) {
                let allCountriesOptions: IComboBoxOption[] = getSourceDataFromUserFields(this.props.countryStore, "Name", this.state.gnLanguage, this.props.value, 'comboBox', 'Name', true, false);

                if(allCountriesOptions) {
                    if(typeof this.props.getCountryId !== 'undefined') {
                        this.setIdOfPoland(allCountriesOptions);
                    }
        
                    this.setState({ countryOptions: allCountriesOptions });
                }
            }
        } else if(!this.state.countryOptions || this.state.countryOptions.length === 0) {
            let allCountriesOptions: IComboBoxOption[] = getSourceDataFromUserFields(this.props.countryStore, "Name", this.state.gnLanguage, this.props.value, 'comboBox', 'Name', true, false);
            
            if(allCountriesOptions) {
                if(typeof this.props.getCountryId !== 'undefined') {
                    this.setIdOfPoland(allCountriesOptions);
                }
    
                this.setState({ countryOptions: allCountriesOptions });
            }
        }
    }

    private mapValidationData(validationData: any): any {
        let mappedValidationData: any = {};
    
        let notDefaultBehaviourForKeys: string[] = ['multiline', 'errorMessage', 'readOnly', 'disabled'];
        
        for(const key in validationData) {
            if (Object.prototype.hasOwnProperty.call(validationData, key)) {
                if(key === 'multiline') {
                    mappedValidationData['multiline'] = 'true';
                    mappedValidationData['rows'] = validationData[key];
                }
                
                if(key === 'errorMessage') {
                    mappedValidationData[key] = L(validationData[key]);
                }
                if(key === 'readOnly' || key === 'disabled') {
                    mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
                }
        
                if(!notDefaultBehaviourForKeys.includes(key)) {
                    mappedValidationData[key] = validationData[key];
                }
            }
        }
    
        return mappedValidationData;
    }

    private setIdOfPoland(countryOptions: IComboBoxOption[]) {
        if(countryOptions && Array.isArray(countryOptions)) {
            countryOptions.some((country: any) => {
                if(country.text && (country.text.toLowerCase() === "poland" || country.text.toLowerCase() === "polska")) {
                    this.props.getCountryId!(country.key);
                    return true;
                }
                return false;
            });
        }
    }

    private getSelectedOptionText(countryOptions: IComboBoxOption[]): string {
        if(this.state.removePresettedValue === true) {
            return "";
        }

        let foundText: string = "";
        
        let filteredCountryOption: any = filterBySome(countryOptions, 'selected', true);
        if(!!filteredCountryOption && !!filteredCountryOption.key) {
            foundText = filteredCountryOption.text;
        }
    
        return foundText;
    } 

    render() {
        const { key, label, errorMessage, disabled, customLabelStyles, labelContainerCustomStyles, asyncActionInProgress, required, allowFreeform, inputText, validationData } = this.props;
        const { countryOptions, removePresettedValue } = this.state;

        const presettedInputText: string = this.getSelectedOptionText(countryOptions);
        let mappedValidationData = this.mapValidationData(validationData);

        return <LabelContainerComponent customStyles={labelContainerCustomStyles}>
                    <LabelComponent customStyles={customLabelStyles ? customLabelStyles : {}} label={label || ''} required={required} />

                    <ComboBox
                        label={''}
                        text={!!inputText ? inputText : (!!presettedInputText ? presettedInputText : undefined)}
                        required={required}
                        allowFreeform={!!allowFreeform ? allowFreeform : false}
                        autoComplete={'on'}
                        options={countryOptions}
                        className={classNames.comboBoxStyles}
                        key={`${key}CountriesComboBox`}
                        errorMessage={!!errorMessage ? errorMessage : this.state.errorMessage}
                        disabled={disabled || asyncActionInProgress}
                        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => this.props.onInputChange(option?.key, option?.text)}
                        onPendingValueChanged={(option?: IComboBoxOption, index?: number, value?: string) => {
                            if(removePresettedValue === false) {
                                this.setState({ removePresettedValue: true });
                            }
                        }}
                        {...mappedValidationData}
                    />
                    {(required && required === true) && <span className={classNames.requiredMarker}>*</span>}
                </LabelContainerComponent>;
    }
}