import { PolicyType } from "../services/policy/policyTypeEnums";
import { modifyFirstLetter } from "./utils";

export function getPartialModel(model: any, keysToReturn: string[], keysToObey?: string[], deepSearch: boolean = true): any { 
    let partialModel: any = {};
    
    if(keysToObey && keysToObey.length > 0) {
        for (let key in model) {
            if(typeof model[key] === 'object' && deepSearch === true) {
                model[key] = getPartialModel(model[key], [], keysToObey);
            }

            if(!keysToObey.includes(key)) {
                partialModel[key] = Array.isArray(model[key]) ? [...model[key]] : model[key];
            }
        }
    } else if(keysToReturn && keysToReturn.length > 0) {
        for (let key in model) {
            if(keysToReturn.includes(key)) {
                partialModel[key] = Array.isArray(model[key]) ? [...model[key]] : model[key];
            }
        }
    } else {
        partialModel = model;
    }

    return partialModel;
}

export function transformPropsFirstLetterToUpperCase(model: any): any {
    const modifiedModel: any[] = [];

    const deepCheckItem = (obj: any) => {
        let objToReturn: any = Array.isArray(obj) ? [] : {};

        Object.keys(obj).forEach((key: any) => {
            if(Array.isArray(obj[key])) {
                objToReturn[modifyFirstLetter(key, 'toUpperCase')] = [];

                obj[key].forEach((deeperItem: any) => {
                    objToReturn[modifyFirstLetter(key, 'toUpperCase')].push(deepCheckItem(deeperItem));
                });
            } else if(typeof obj[key] === 'object' && obj[key] !== null) {
                objToReturn[modifyFirstLetter(key, 'toUpperCase')] = deepCheckItem(obj[key]);
            } else {
                objToReturn[modifyFirstLetter(key, 'toUpperCase')] = obj[key];
            }
        });

        return objToReturn;
    }

    const loopItems = (item: any) => {
        const tempNewItem: any = Array.isArray(item) ? [] : {};

        for(const key in item) {
            if(Object.prototype.hasOwnProperty.call(item, key)) {
                if(typeof item[key] === 'object' && item[key] !== null && Object.keys(item[key]).length > 0) {
                    const testResult = deepCheckItem(item[key]);
                    tempNewItem[modifyFirstLetter(key, 'toUpperCase')] = testResult;
                } else {
                    tempNewItem[modifyFirstLetter(key, 'toUpperCase')] = item[key];
                }
            }
        }
        
        if(tempNewItem['Id']) {
            tempNewItem['id'] = tempNewItem['Id'];
        }
        
        return tempNewItem;
    }

    if(model && typeof model === 'object' && (Object.prototype.hasOwnProperty.call(model, 'items') && Object.prototype.hasOwnProperty.call(model, 'totalCount'))) {
        modifiedModel['totalCount'] = model.totalCount;
        modifiedModel['items'] = [];

        model.items.forEach((item: any) => {
            modifiedModel['items'].push(loopItems(item));
        });

        return modifiedModel['items'].length === model.items.length ? modifiedModel : model;
    } else if(model && typeof model === 'object' && Object.keys(model).length > 0 && !Array.isArray(model)) {
        const modifiedModel: any = loopItems(model);

        return (Object.keys(modifiedModel).length - 1) === Object.keys(model).length ? modifiedModel : model;
    } else if(model && Array.isArray(model)) {
        model.forEach((item: any) => {
            modifiedModel.push(loopItems(item));
        });
        
        return modifiedModel.length === model.length ? modifiedModel : model;
    } else {
        return model;
    }
}

export function mapProductSeNameToPolicyType(seName: string): string {
    let policyTypeToReturn: string = '';

    switch(seName) {
        case 'ubezpieczenie-na-życie':
            policyTypeToReturn = PolicyType[PolicyType.Life];
            break;
        case 'ubezpieczenie-auta':
            policyTypeToReturn = PolicyType[PolicyType.Vehicle];
            break;
        case 'ubezpieczenie-domu':
            policyTypeToReturn = PolicyType[PolicyType.Home];
            break;
        case 'podróż':
            policyTypeToReturn = PolicyType[PolicyType.Travel];
            break;
        case 'nnw-dziecka':
            policyTypeToReturn = PolicyType[PolicyType.Children];
            break;
        // case 'transport-pow-35t-dmc':
        //     policyTypeToReturn = PolicyType.;
        //     break;
        // case 'life-indywidual':
        //     policyTypeToReturn = PolicyType.;
        //     break;
    }

    return policyTypeToReturn;
}

type EnumType = { [s: string]: string };
export function parseEnumToStringArray(enumerable: EnumType, valuesToLowerCase?: boolean): string[] {
    const arrayToReturn: string[] = [];

    for(let key in enumerable) {
        if(enumerable.hasOwnProperty(key)) {
            arrayToReturn.push(valuesToLowerCase === true ? enumerable[key].toLowerCase() : enumerable[key]);
        }
    }

    return arrayToReturn;
}