import { IDropdownOption } from "@fluentui/react";
import { ClaimCauseDto } from "../services/claim/dto/claimCauseDto";
import { ClaimDamageDto } from "../services/claim/dto/claimDamageDto";
import { mapAttributeKeyToMappingId } from "./policyCalculationUtils";
import { getSourceDataFromUserFields } from "./storeUtils";
import { filterBySome } from "./utils";

export function getInputIconData(attribute: any, userFields?: any[]): any { 
    let tempIconData: any = {};
    let userFieldsFound: number = 0;

    let getUserFields: any[] = typeof userFields !== 'undefined' && Array.isArray(userFields) ? userFields : attribute.UserFields;

    getUserFields.some((UserField: any) => {
        if(UserField.Key === 'displayIcon') {
            tempIconData['iconName'] = UserField.Value;
            userFieldsFound++;
        }
        if(UserField.Key === 'iconOnClick') {
            tempIconData['iconOnClick'] = UserField.Value;
            userFieldsFound++;
        }

        if(userFieldsFound === 2) {
            return true;
        }
        return false;
    });

    return tempIconData;
}

export function getDropdownOptionsFromDataSource(attribute: any, dataSource: any, sortBy: string, gnLanguage: any, currentValue?: string, type?: string): any { 
    let tempOptions: IDropdownOption[] = [];

    attribute.UserFields.some((UserField: any) => {
        if(UserField.Key === 'dataSource') {
            tempOptions = getSourceDataFromUserFields(dataSource[UserField.Value], sortBy, gnLanguage, currentValue, type);
            return true;
        }
        return false;
    });

    return tempOptions;
}

export function getInpuValidationData(attribute: any, customInputsData?: any): any { 
    const tempId: string = attribute.attrId && !!attribute.attrId ? attribute.attrId : attribute.Id;
    let tempValidationData: any = {};
    let catchValueForKeys: string[] = ['disabled', 'readOnly', 'required', 'multiline', 'errorMessage', 'maxlength', 'minlength',
                                        'mask', 'maskFormatKey', 'maskFormatValue', 'maskChar', 'placeholder', 'prefix', 'suffix',
                                        'inputType', 'min', 'max', 'autoincrement', 'minDate', 'maxDate', 'tooltipText'];

    if(attribute && attribute.UserFields) {
        attribute.UserFields.forEach((UserField: any) => {
            if(catchValueForKeys.includes(UserField.Key)) {
                tempValidationData[UserField.Key] = UserField.Value;
            }
        });
    }

    if(customInputsData && customInputsData[tempId] && customInputsData[tempId].validationData && Array.isArray(customInputsData[tempId].validationData)) {
        customInputsData[tempId].validationData.forEach((element: any) => {
            if(element.Key && !!element.Key && element.Value) {
                tempValidationData[element.Key] = element.Value;
            }
        });
    }

    return tempValidationData;
}

export function getInputTableData(attribute: any, allUserFields: any, productAttributeMappings: any, product?: any): any { 
    let tempTableData: any = {};
    let catchValueForKeys: string[] = ['tableRowsCountFromInputId', 'tableColumnHeaders', 'tableColumnInputs'];

    attribute.UserFields.forEach((UserField: any) => {
        if(catchValueForKeys.includes(UserField.Key)) {
            tempTableData[UserField.Key] = UserField.Value;

            if(UserField.Key === 'tableColumnInputs') {
                let tempString: string = "";
                let splittedKeys: string[] = UserField.Value.split(';;');

                splittedKeys.forEach((key: string) => {
                    let keyToId: string = mapAttributeKeyToMappingId(allUserFields, productAttributeMappings, key, product);
                    if(!!keyToId) {
                        tempString += keyToId + ';;';
                    }
                });

                if(!!tempString) {
                    tempTableData[UserField.Key] = tempString;
                }
            } else if(UserField.Key === 'tableRowsCountFromInputId') {
                let keyToId: string = mapAttributeKeyToMappingId(allUserFields, productAttributeMappings, UserField.Value, product);
                if(!!keyToId) {
                    tempTableData[UserField.Key] = keyToId;
                }
            }
        }
    });

    return tempTableData;
}

export function isInputInTab(UserFields: any): boolean { 
    let response = false;

    UserFields.some((UserField: any) => {
        if(UserField.Key.substr(0, 4) === "step") {
            response = true;
            return true;
        }
        return false;
    });

    return response;
}

export function isInputForApk(UserFields: any): boolean { 
    let response = false;

    UserFields.some((UserField: any) => {
        if(UserField.Key === "is_for_apk") {
            response = true;
            return true;
        }
        return false;
    });

    return response;
}

export function isInputFastCalculation(UserFields: any): boolean { 
    let response = false;

    UserFields.some((UserField: any) => {
        if(!!UserField && UserField.Key === "fast_calculation") {
            response = true;
            return true;
        }
        return false;
    });

    return response;
}

export function shouldSaveAsTemplateForTable(attrId: string, UserFields: any, savedTemplates: any, productAttributeMappings: any, allUserFields: any, product: any): boolean | string {
    let response: string | boolean = false;

    UserFields.some((UserField: any) => {
        if(!!UserField && UserField.Key === "templateInputForTableId") {
            let value: string = "";
            if(UserField.Value.includes('.')) {
                value = mapAttributeKeyToMappingId(allUserFields, productAttributeMappings, UserField.Value, product);
            } else {
                value = UserField.Value;
            }

            if(!!value) {
                if(savedTemplates[value]) {
                    let attrIdFound: boolean = false;
                    savedTemplates[value].some((element: any) => {
                        if(element.id === attrId) {
                            attrIdFound = true;
                            return true;
                        }
                        return false;
                    });

                    if(!attrIdFound) {
                        response = value;
                    }
                } else {
                    response = value;
                }
            }

            return true;
        }
        return false;
    });

    return response;
}

export function shouldSaveAsTemplateForCalculationAdjust(attrId: string, UserFields: any): boolean | string {
    let response: boolean | string = false;

    UserFields.some((UserField: any) => {
        if(UserField.Key === "allowTuningForInsurer" && UserField.Value.length > 0) {
            response = attrId;
            
            return true;
        }
        return false;
    });

    return response;
}

export function presetTableInputData(jsonValue: string, valuesToPreset: any[]): string {
    let resultObj = {};

    if(jsonValue && typeof jsonValue === 'string') {
        resultObj = JSON.parse(jsonValue);
    }

    let rowsToSet = valuesToPreset.length > 0 ? valuesToPreset[0].values.length : 0;

    for(let i = 0; i < rowsToSet; i++) {
        if(!resultObj[i]) {
            resultObj[i] = {};
        }
        
        valuesToPreset.forEach((valueToPreset: any) => {
            resultObj[i][valueToPreset['inputIdToPreset']] = valueToPreset['values'][i];
        });
    }

    return JSON.stringify(resultObj);
}

export function parseJsonStringToDto(jsonString: string, dtoName: string): any {
    let returnValue: any;
    let parsedValue = JSON.parse(jsonString);

    switch(dtoName) {
        case "ClaimCauseDto":
            returnValue = [] as ClaimCauseDto[];
            
            for(let key in parsedValue) {
                if(parsedValue.hasOwnProperty(key)) {
                    let tempObj: any = {};
                    let name = parsedValue[key] && parsedValue[key]['claimCauseName'] && typeof parsedValue[key]['claimCauseName'] === 'string' 
                                ? parsedValue[key]['claimCauseName'] : "";
                    let description = parsedValue[key] && parsedValue[key]['claimCauseDescription'] && typeof parsedValue[key]['claimCauseDescription'] === 'string'
                                ? parsedValue[key]['claimCauseDescription'] : "";
                    let id = parsedValue[key] && parsedValue[key]['claimCauseId'] && (typeof parsedValue[key]['claimCauseId'] === 'string' || typeof parsedValue[key]['claimCauseId'] === 'number')
                                ? parseInt(parsedValue[key]['claimCauseId']) : -1;

                    tempObj['name'] = name;
                    tempObj['description'] = description;
                    if(id && id >= 0) {
                        tempObj['id'] = id;
                    } 
                    
                    if(name.length > 0) {
                        returnValue.push(tempObj);
                    }
                }
            }
            break;
        case "ClaimDamageDto":
            returnValue = [] as ClaimDamageDto[];
            
            for(let key in parsedValue) {
                if(parsedValue.hasOwnProperty(key) && parsedValue[key]) {
                    let tempObj: any = {};
                    let claim = parsedValue[key] && parsedValue[key]['claimDamageClaim'] && typeof parsedValue[key]['claimDamageClaim'] === 'string' 
                                ? parsedValue[key]['claimDamageClaim'] : "";
                    let value = parsedValue[key] && parsedValue[key]['claimDamageValue'] && typeof parsedValue[key]['claimDamageValue'] === 'number'
                                ? parsedValue[key]['claimDamageValue'] : (typeof parsedValue[key]['claimDamageValue'] === 'string') ? parseFloat(parsedValue[key]['claimDamageValue']) : 0;
                    let id = parsedValue[key] && parsedValue[key]['claimDamageId'] && (typeof parsedValue[key]['claimDamageId'] === 'string' || typeof parsedValue[key]['claimDamageId'] === 'number')
                                ? parseInt(parsedValue[key]['claimDamageId']) : -1;

                    tempObj['claim'] = claim;
                    tempObj['value'] = value;
                    if(id && id >= 0) {
                        tempObj['id'] = id;
                    } 
                    
                    if(claim.length > 0) {
                        returnValue.push(tempObj);
                    }
                }
            }
            break;
    }

    return returnValue;
}

export function parseDtoToJsonString(dto: any, dtoName: string): string {
    let returnValue: string = "";

    if(typeof dto !== 'string') {    
        let newDtoObj: any = [];

        switch(dtoName) {
            case "ClaimCauseDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = { 'claimCauseName': dto[key].name, 'claimCauseDescription': dto[key].description };
                        if(dto[key].id) {
                            newDtoObj[key]['claimCauseId'] = parseInt(dto[key].id);
                        }
                    }
                }

                returnValue = JSON.stringify(newDtoObj);
                break;
            case "ClaimDamageDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = { 'claimDamageClaim': dto[key].claim, 'claimDamageValue': dto[key].value };
                        if(dto[key].id) {
                            newDtoObj[key]['claimDamageId'] = parseInt(dto[key].id);
                        }
                    }
                }
                
                returnValue = JSON.stringify(newDtoObj);
                break;
        }
    } else {
        returnValue = dto as string;
    }

    return returnValue;
}

export function validatePesel(pesel: string): boolean {
    if (pesel.length === 11) {
        let arr: number[] = [1,3,7,9,1,3,7,9,1,3];
        let sum: number = 0;

        for (let i: number = 0; i < 10; i++) {
            sum += arr[i] * parseInt(pesel[i]);
        }

        sum = sum % 10 === 0 ? 0 : 10 - sum % 10;
        return sum === parseInt(pesel[10]);
    } else {
        return false;
    }
}

export function validateNip(nip: string): boolean {
    let nipWithoutDashes = nip.replace(/-/g,"");
    let reg = /^[0-9]{10}$/;

    if(reg.test(nipWithoutDashes) === false) {
        return false;
    } else {
        let digits = ("" + nipWithoutDashes).split("");
        let checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8])) % 11;
        
        return (parseInt(digits[9]) === checksum);
    }
}

export function validateRegon(regon: string): boolean {
    //REGON is a 9 or 14 digit number. Last digit is control digit from equation:
    // [ sum from 1 to (9 or 14) (x[i]*w[i]) ] mod 11; where x[i] is pointed NIP digit and w[i] is pointed digit
    //from [8 9 2 3 4 5 6 7] for 9 and [2 4 8 5 0 9 7 3 6 1 2 4 8] for 14 digits.

    let n = regon.length;
    let w;
    let cd = 0; // Control digit (last digit)
    let isOnlyDigit = /^\d+$/.test(regon);  
    
    if (n !==9 && n !== 14 && !isOnlyDigit) {
        return false;
    }
    
    if(n === 9) {
        w = [8, 9, 2, 3, 4, 5, 6, 7];
    } else {
        w = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
    }
    
    for(let i = 0; i<n-1; i++) {
        cd += w[i]*parseInt(regon.charAt(i));
    }
    
    cd %= 11;
    
    if(cd === 10) {
        cd = 0;
    }
    
    if(cd !== parseInt(regon.charAt(n-1)) ) {
        return false;
    } else {
        return true;
    }
}

export function validateEmail(email: string): boolean {
    let reg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if(reg.test(email) === false) {
        return false;
    } else {
        return true;
    }
}

export function isCountriesInput(attr: any): boolean {
    let isCountriesInput: boolean = false;

    if(!!attr && !!attr.ProductAttribute && !!attr.ProductAttribute.UserFields) {
        const keyUserField: any = filterBySome(attr.ProductAttribute.UserFields, 'Key', 'key');
        if(keyUserField.Value.substr(keyUserField.Value.length - "HomeAddress.Country.Name".length) === "HomeAddress.Country.Name") {
            isCountriesInput = true;
        }
    }

    return isCountriesInput;
}