import { CrudStoreBase } from './crudStoreBase';
import { InsuranceCompanyDto } from '../services/insuranceCompany/insuranceCompanyDto';
import InsuranceCompanyService from '../services/insuranceCompany/insuranceCompanyService';

class InsuranceCompanyStore extends CrudStoreBase<InsuranceCompanyDto>{
	constructor() {
		super(InsuranceCompanyService, defaultInsuranceCompany)
	}
}

export const defaultInsuranceCompany = {
	id: '',
	Id: '',
	Name: '',
	// SeName: string,
	Description: '',
	BottomDescription: '',
	// BrandLayoutId: string,
	// MetaKeywords: string,
	// MetaDescription: string,
	// MetaTitle: string,
	// PictureId: string,
	// PageSize: number,
	// AllowCustomersToSelectPageSize: boolean,
	// PageSizeOptions: string,
	// ShowOnHomePage: boolean,
	// IncludeInMenu: boolean,
	// Icon: string,
	Published: false,
	// DisplayOrder: 0
}

export default InsuranceCompanyStore;