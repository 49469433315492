import { PatternDto } from '../services/pattern/dto/patternDto';
import PatternService from '../services/pattern/patternService';
import { PatternTypeEnum } from '../services/pattern/patternTypeEnums';
import { CrudStoreBase } from './crudStoreBase';

class PatternStore extends CrudStoreBase<PatternDto>{
  constructor() {
    super(PatternService, defaultPattern)
  }
}

export const defaultPattern = {
  id: '',
  patternType: PatternTypeEnum.AboutUs,//{value:PatternTypeEnum.AboutUs},
  value: "",
  description: "",
  culture: "",
}

export default PatternStore;