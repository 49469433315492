import { CrudServiceBaseTransformed } from '../base/crudServiceBaseTransformed';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ProductAttributeDto } from './productAttributeDto';

export class ProductAttributeService extends CrudServiceBaseTransformed<ProductAttributeDto> {
    constructor() {
        super(Endpoint.ProductAttribute);
        this.internalHttp = httpApi;
    }

    async saveUserField(key: string, requestBody: any) {
        return await this.internalHttp.patch(this.endpoint.Custom(`Patch?key='${key}'`, false), requestBody);
    }
}

export default new ProductAttributeService();