export enum InsuranceCompanyNewOffer {
  AGRO = 'AGRO',
  Alianz = 'Alianz',
  Balcia = 'Balcia',
  Benefia = 'Benefia',
  Compensa = 'Compensa',
  Concordia = 'Concordia',
  ErgoHestia = 'Ergo Hestia',
  Euroins = 'Euroins',
  Generali = 'Generali',
  Interrisk = 'Interrisk',
  LINK4 = 'LINK4',
  MTU = 'MTU',
  Proama = 'Proama',
  PZU = 'PZU',
  Trasti = 'Trasti',
  TUW = 'TUW',
  TUZ = 'TUZ',
  UNIQA = 'UNIQA',
  Warta = 'Warta',
  WeFox = 'WeFox',
  Winer = 'Winer'
};