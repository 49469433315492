import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultDto } from '../dto/pagedResultDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { TravelCountryCoverageDto } from './dto/travelCountryCoverageDto';

export class TravelCountryCoverageService extends CrudServiceBase<TravelCountryCoverageDto> {
    constructor() {
        super(Endpoint.TravelCountryCoverage);
        this.internalHttp = httpApi;
    }

    public async getByCountryId(countryId: string): Promise<PagedResultDto<TravelCountryCoverageDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByCountry?countryId=${countryId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByInsurerId(insurerId: string): Promise<PagedResultDto<TravelCountryCoverageDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByInsurerId?insurerId=${insurerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createTravelCountryCoverage: any) {
        let result = await httpApi.post(this.endpoint.Create(), createTravelCountryCoverage);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async createTravelCountryCoverageListForInsurerId(insurerId: number) {
        let result = await httpApi.post(this.endpoint.Custom(`CreateTravelCountryCoverageListForInsurerId?insurerId=${insurerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateTravelCountryCoverage: any) {
        let result = await httpApi.put(this.endpoint.Update(), updateTravelCountryCoverage);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new TravelCountryCoverageService();