import { CrudServiceBaseTransformed } from '../base/crudServiceBaseTransformed';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { InsuranceCompanyDto } from './insuranceCompanyDto';

export class InsuranceCompanyService extends CrudServiceBaseTransformed<InsuranceCompanyDto> {
    constructor() {
        super(Endpoint.InsuranceCompany);
        this.internalHttp = httpApi;
    }
}

export default new InsuranceCompanyService();