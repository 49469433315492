import { CrudStoreBase } from './crudStoreBase';
import { OrderDto } from '../services/order/dto/orderDto';
import orderService from '../services/order/orderService';
import { ShippingStatus } from '../services/order/enums/shippingStatusEnums';
import { PaymentStatus } from '../services/order/enums/paymentStatusEnums';
import { TaxDisplayType } from '../services/order/enums/taxDisplayTypeEnums';

class OrderStore extends CrudStoreBase<OrderDto>{
  constructor() {
    super(orderService, defaultOrder)
  }
}

export const defaultOrder = {
  id: '',
  orderNumber: 0,
  seName: '',
  code: '',
  companyName: '',
  customerEmail: '',
  firstName: '',
  lastName: '',
  orderTotal: 0,
  paymentMethodSystemName: '',
  //billingAddress: null,
  shippingMethod: '',
  //shippingAddress: null,
  orderStatusId: 10,
  orderItems: [],
  checkoutAttributes: [],
  orderGuid: '',
  storeId: '',
  customerId: '',
  ownerId: '',
  seId: '',
  pickUpInStore: false,
  paymentOptionAttribute: '',
  customerCurrencyCode: '',
  primaryCurrencyCode: '',
  currencyRate: 0,
  rate: 0,
  vatNumber: '',
  vatNumberStatusId: 0,
  orderSubtotalInclTax: 0,
  orderSubtotalExclTax: 0,
  orderSubTotalDiscountInclTax: 0,
  orderSubTotalDiscountExclTax: 0,
  orderShippingInclTax: 0,
  orderShippingExclTax: 0,
  paymentMethodAdditionalFeeInclTax: 0,
  paymentMethodAdditionalFeeExclTax: 0,
  orderTax: 0,
  orderDiscount: 0,
  paidAmount: 0,
  refundedAmount: 0,
  loyaltyPointsWereAdded: false,
  redeemedLoyaltyPoints: 0,
  redeemedLoyaltyPointsAmount: 0,
  calcLoyaltyPoints: 0,
  checkoutAttributeDescription: '',
  customerLanguageId: '',
  affiliateId: '',
  customerIp: '',
  shippingRateProviderSystemName: '',
  deleted: false,
  createdOnUtc: '',
  imported: false,
  urlReferrer: '',
  shippingOptionAttributeDescription: '',
  shippingOptionAttribute: '',
  orderTags: [],
  paidDateUtc: '',
  shippingStatusId: ShippingStatus.ShippingNotRequired,
  paymentStatusId: PaymentStatus.Pending,
  customerTaxDisplayTypeId: TaxDisplayType.IncludingTax,
  orderTaxes: [],
}

export default OrderStore;