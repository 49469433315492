import { CrudStoreBase } from './crudStoreBase';
import { CustomerGroupDto } from '../services/customerGroup/customerGroupDto';
import CustomerGroupService from '../services/customerGroup/customerGroupService';

class CustomerGroupStore extends CrudStoreBase<CustomerGroupDto>{
	constructor() {
		super(CustomerGroupService, defaultCustomerGroup)
	}

	public async getFiltered(filterKey: string, filterValue: string) {
		return CustomerGroupService.getFiltered(filterKey, filterValue);
	}
}

export const defaultCustomerGroup = {
	id: '',
	Id: '',
	Name: '',
	FreeShipping: false,
	TaxExempt: false,
	Active: false,
	IsSystem: false,
	SystemName: '',
	EnablePasswordLifetime: false
}

export default CustomerGroupStore;