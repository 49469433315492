import AppConsts from './../lib/appconst';
import { L } from '../lib/abpUtility';
import axios from 'axios';
import './httpService.less'
import { Container } from '../stores/storeInitializer';

const qs = require('qs');

declare var abp: any;

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  headers: {
    "Accept-Language": abp.utils.getCookieValue('Abp.Localization.CultureName')
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

export const httpApi = axios.create({
  baseURL: `${AppConsts.remoteServiceBaseUrl}api/services/app/`,
  timeout: 1000 * 60 * 2,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

export const httpGrandNode = axios.create({
  baseURL: `${AppConsts.grandNodeBaseUrl}`,
  timeout: 1000 * 30 * 1,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

// todo add authorization headers
const httpDownload = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  method: 'GET',
  responseType: 'blob'
});

export let download = async function (resource: string, name: string) {
  let response = await httpDownload.get(resource);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

let requestInterceptor = function (config: any) {
  if (!!abp.auth.getToken()) {
    config.headers.common['Authorization'] = 'Bearer ' + abp.auth.getToken();
  }

  config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
  config.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();

  return config;
}

let errorRequestInterceptor = function (error: any) {
  return Promise.reject(error);
}

let successResponseInterceptor = function (response: any) {
  if (Container.EventBus) {
    Container.EventBus.clearHttpError();
  }

  return response;
}

let errorResponseInterceptor = function (error: any) {
  let errorObj: any = {};
  if(error.response && error.response.data && !!error.response.data.error && !!error.response.data.missingPermission) {
    errorObj = {
      title: error.response.data.error,
      content: error.response.data.missingPermission,
    };
  } else if(error.response && error.response.data && typeof error.response.data.error === 'object' && !!error.response.data.error.message) {
    errorObj = {
      title: error.response.data.error.message,
    };
  } else if(error.response && error.response.data && !!error.response.data.error) {
    errorObj = {
      title: error.response.data.error,
    };
  } else {
    errorObj = { content: L('UnknownError') };
  }

  if (Container && Container.EventBus) {
    Container.EventBus.setHttpError(errorObj);

    if (Container.EventBus.customErrorHandling) {
      return Promise.resolve({ data: { result: undefined } });
    }
  }

  return Promise.reject(error);
}

http.interceptors.request.use(requestInterceptor, errorRequestInterceptor);
httpApi.interceptors.request.use(requestInterceptor, errorRequestInterceptor);
httpGrandNode.interceptors.request.use(requestInterceptor, errorRequestInterceptor);

http.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);
httpApi.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);
httpGrandNode.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);

export default http;
