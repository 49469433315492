import { CrudStoreBase } from './crudStoreBase';
import { SportDisciplineDto } from '../services/sportDiscipline/dto/sportDisciplineDto';
import sportDisciplineService from '../services/sportDiscipline/sportDisciplineService';

class SportDisciplineStore extends CrudStoreBase<SportDisciplineDto>{
  constructor() {
    super(sportDisciplineService, defaultSportDiscipline)
  }
}

export const defaultSportDiscipline = {
  id: '',
  name: '',
}

export default SportDisciplineStore;