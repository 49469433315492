import { transformPropsFirstLetterToUpperCase } from '../../utils/modelUtils';
import { CrudServiceBaseTransformed } from '../base/crudServiceBaseTransformed';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { CustomerGroupDto } from './customerGroupDto';

export class CustomerGroupService extends CrudServiceBaseTransformed<CustomerGroupDto> {
    constructor() {
        super(Endpoint.CustomerGroup);
        this.internalHttp = httpApi;
    }

    public async getFiltered(filterKey: string, filterValue: string) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`?$filter=${filterKey} eq '${filterValue}'`, false));
    
        if(Array.isArray(result.data.value) && result.data.value.length > 0) {
            return transformPropsFirstLetterToUpperCase(result.data.value[0]);
        } else {
            return transformPropsFirstLetterToUpperCase(result.data.value);
        }
    }
}

export default new CustomerGroupService();