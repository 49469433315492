import * as React from 'react';
import { Dropdown, IDropdownOption, IDropdownStyles, Shimmer } from '@fluentui/react';
import Stores from '../../stores/storeIdentifier';
import { inject } from 'mobx-react';
import EventStore from '../../stores/eventStore';
import { myTheme } from '../../styles/theme';
import { LabelContainerComponent } from './labelContainerComponent';
import { LabelComponent } from './labelComponent';
import { getShimmerStyles } from '../../utils/stylesUtils';
import { L } from '../../lib/abpUtility';

export interface IMutliDropdownProps {
  label: string;
  options: IDropdownOption[];
  required?: boolean;
  id?: string | undefined;
  eventStore?: EventStore;
  value?: string[] | undefined;
  disabled?: boolean;
  isDataLoaded?: boolean;
  validationData?: any;
  onChange?: (value: string[]) => void;
  customDropdownWidth?: string;
  labelContainerCustomStyles?: any;
  customLabelStyles?: any;
}

export interface IMultiDropdownState {
  selectedItems: string[];
}
const dropdownStyle: Partial<IDropdownStyles> = {
  dropdownItemSelected: {
    selectors: {
      ':hover .ms-Checkbox-checkbox': {
        background: myTheme.palette.themeDark,
        borderColor: myTheme.palette.themeDark,
      },
      '.ms-Checkbox-checkbox': {
        background: myTheme.palette.themePrimary,
        borderColor: myTheme.palette.themePrimary,
      },
    },
  },
  subComponentStyles: {
    label: {},
    multiSelectItem: {
      checkmark: {
        color: myTheme.palette.white,
      },
    },
    panel: {},
  },
};

@inject(Stores.EventStore)
export class MultiDropdownBase extends React.Component<IMutliDropdownProps, IMultiDropdownState> {
  public state: IMultiDropdownState = {
    selectedItems: [],
  };

  static getDerivedStateFromProps(props: any, state: any) {
    return { selectedItems: props.value || [] };
  }

  componentDidMount() {
    this.saveState(this.props);
  }

  private saveState(props: IMutliDropdownProps) {
    if (props.value && props.value && Object.entries(props.value).length !== 0) {
      this.setState({ selectedItems: props.value || [] });
      if (props.onChange) props.onChange(this.state.selectedItems);
    }
  }

  private mapValidationData(validationData: any): any {
    let mappedValidationData: any = {};

    let notDefaultBehaviourForKeys: string[] = ['multiline', 'errorMessage', 'readOnly', 'disabled'];
    
    for(const key in validationData) {
        if (Object.prototype.hasOwnProperty.call(validationData, key)) {
            if(key === 'multiline') {
                mappedValidationData['multiline'] = 'true';
                mappedValidationData['rows'] = validationData[key];
            }
            
            if(key === 'errorMessage') {
                mappedValidationData[key] = L(validationData[key]);
            }
            if(key === 'readOnly' || key === 'disabled') {
                mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
            }
    
            if(!notDefaultBehaviourForKeys.includes(key)) {
                mappedValidationData[key] = validationData[key];
            }
        }
    }

    return mappedValidationData;
  }

  render() {
    const { options, label, id, required, eventStore, customLabelStyles , disabled, isDataLoaded, validationData, customDropdownWidth, labelContainerCustomStyles } = this.props;
    const { selectedItems } = this.state;

    let mappedValidationData = this.mapValidationData(validationData);

    const multiDropdown = <Dropdown
      disabled={disabled}
      options={options}
      required={required} 
      errorMessage={eventStore!.getErrorMessageFor(id)}
      selectedKeys={selectedItems}
      onChange={this._onChange}
      multiSelect
      styles={dropdownStyle}
      style={{ width: customDropdownWidth ? customDropdownWidth : "300px" }}
      {...mappedValidationData}
    />;

    return label && label.length > 0 ?
      <LabelContainerComponent customStyles={labelContainerCustomStyles}>
        <LabelComponent customStyles={customLabelStyles ? customLabelStyles : {}} label={label}/>
        <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
          { multiDropdown }
        </Shimmer>
      </LabelContainerComponent>
      : 
      <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
        { multiDropdown }
      </Shimmer>
  }

  private _onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined): void => {
    if (!option) return;
    const item: IDropdownOption = option!;

    const newSelectedItems = [...this.state.selectedItems];
    if (item.selected) {
      // add the option if it's checked
      newSelectedItems.push(item.key as string);
    } else {
      // remove the option if it's unchecked
      const currIndex = newSelectedItems.indexOf(item.key as string);
      if (currIndex > -1) {
        newSelectedItems.splice(currIndex, 1);
      }
    }
    this.setState({
      selectedItems: newSelectedItems,
    });

    if (this.props.onChange) this.props.onChange(newSelectedItems);
  };
}
