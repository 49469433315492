import { DirectionalHint, Icon, TooltipHost } from "@fluentui/react";
import React from "react";
import { LabelComponent } from "../../scenes/BaseComponents/labelComponent";
import { LabelContainerComponent } from "../../scenes/BaseComponents/labelContainerComponent";
import { ITextFieldBaseProps, TextFieldBase } from "../../scenes/BaseComponents/textFieldBase";
import { gnInputIconStyles } from "../../styles/gnInputIconStyles";
import { isJsonString } from "../../utils/utils";
import { L } from "../../lib/abpUtility";

export class LabeledTextField extends React.Component<ITextFieldBaseProps> {
    render() {
        let icons: any = <></>;
    
        if(this.props.iconName) {
            if(!isJsonString(this.props.iconName)) {
                icons = <Icon iconName={this.props.iconName} className={gnInputIconStyles.icon} onClick={() => this.props.iconOnClick!()} />;
            } else {
                let parsedJsonString: any = JSON.parse(this.props.iconName);
                if(parsedJsonString && Array.isArray(parsedJsonString)) {
                    icons = parsedJsonString.map((iconName: string, index: number) => {
                                return <Icon iconName={iconName} className={gnInputIconStyles.icon} onClick={() => this.props.iconOnClick!(index)} /> 
                            });
                }
            }
        } else if(!!this.props.tooltipText) {
            icons = <TooltipHost content={L(this.props.tooltipText)} id={`${this.props.id}_tooltip`} directionalHint={DirectionalHint.rightCenter}
                        calloutProps={{ gapSpace: 0 }} styles={{root: { display: 'inline-block' }}}
                    >
                        <Icon aria-describedby={`${this.props.id}__tooltip`} iconName={'Info'} className={gnInputIconStyles.icon} style={{cursor: 'default', marginTop: '4px'}} />
                    </TooltipHost>;
        }

        return <LabelContainerComponent customStyles={this.props.labelContainerCustomStyles}>
            <LabelComponent label={this.props.label || ''} required={this.props.required} customStyles={this.props.customLabelStyles ? this.props.customLabelStyles : {}} />
            <TextFieldBase {...this.props} label={undefined} width={!!this.props.customWidth ? this.props.customWidth : 300} height={33} />
            {icons}
        </LabelContainerComponent>;
    }
}