import http from '../httpService';
import { EntityStringDto } from '../dto/entityStringDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import { PagedResultRequestDto } from "./pagedResultRequestDto";
import { ServiceBase } from './serviceBase';
import Endpoint from '../endpoint';
import { AxiosInstance } from 'axios';
import { CrudConsts } from '../../stores/crudStoreBase';
import { isUserLoggedIn } from '../../utils/authUtils';
import { transformPropsFirstLetterToUpperCase } from '../../utils/modelUtils';

export class CrudServiceBaseTransformed<TDto extends EntityStringDto, TCreateDto = TDto> extends ServiceBase {
    defaultRequest: any = { keyword: "", maxResultCount: CrudConsts.PAGE_SIZE, skipCount: 0 };
    internalHttp: AxiosInstance;

    constructor(endpoint: Endpoint, overrideHttp: AxiosInstance = http ) {
        super(endpoint);
        this.internalHttp = overrideHttp;
    }

    public async get(entityStringDto: EntityStringDto): Promise<TDto> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Get(), { params: entityStringDto });
        return transformPropsFirstLetterToUpperCase(!!result.data && !!result.data.result ? result.data.result : result.data);
    }
    public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<TDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.GetAll(), { params: pagedFilterAndSortedRequest });
        return transformPropsFirstLetterToUpperCase(!!result.data && !!result.data.result ? result.data.result : result.data);
    }
    public async create(createUserInput: TCreateDto) {
        isUserLoggedIn();
        let result = await this.internalHttp.post(this.endpoint.Create(), createUserInput);
        return transformPropsFirstLetterToUpperCase(!!result.data && !!result.data.result ? result.data.result : result.data);
    }
    public async update(updateUserInput: TDto) {
        isUserLoggedIn();
        let result = await this.internalHttp.put(this.endpoint.Update(), updateUserInput);
        return transformPropsFirstLetterToUpperCase(!!result.data && !!result.data.result ? result.data.result : result.data);
    }
    public async delete(entityStringDto: TDto) {
        isUserLoggedIn();
        let result = await this.internalHttp.delete(this.endpoint.Delete(), { params: entityStringDto });
        return transformPropsFirstLetterToUpperCase(result.data);
    }
}


