import { TravelCountryCoverageDto } from '../services/travelCountryCoverage/dto/travelCountryCoverageDto';
import { CrudStoreBase } from './crudStoreBase';
import travelCountryCoverageService from '../services/travelCountryCoverage/travelCountryCoverageService'
import { defaultInsurer } from './insurerStore';
import { defaultTravelCountry } from './travelCountryStore';

class TravelCountryCoverageStore extends CrudStoreBase<TravelCountryCoverageDto>{
  constructor() {
    super(travelCountryCoverageService, defaultTravelCountryCoverage)
  }

  public async getByCountryId(countryId: number) {
		await travelCountryCoverageService.getByCountryId(countryId.toString()).then((result: any) => {
			this.dataSet = result;
		}).catch((error: any) => {
			console.error(error);
		});
	}

  public async getByInsurerId(insurerId: string) {
    this.dataSet = {items: [], totalCount: 0};
		await travelCountryCoverageService.getByInsurerId(insurerId).then((result: any) => {
			this.dataSet = result;
		}).catch((error: any) => {
			console.error(error);
		});
	}
}

export const defaultTravelCountryCoverage = {
    id: '',
    countryId: 0,
    country: defaultTravelCountry,
    insurerId: 0,
    insurer: defaultInsurer,
    insurerCountryId: '',
    geographicalZone: '',
}

export default TravelCountryCoverageStore;