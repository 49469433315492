import { transformPropsFirstLetterToUpperCase } from '../../utils/modelUtils';
import { CrudServiceBaseTransformed } from '../base/crudServiceBaseTransformed';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { LoyaltyPointsHistoryDto } from './loyaltyPointsHistoryDto';

export class LoyaltyPointsHistoryService extends CrudServiceBaseTransformed<LoyaltyPointsHistoryDto> {
    constructor() {
        super(Endpoint.LoyaltyPointsHistory);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: LoyaltyPointsHistoryDto) {
        const filteredUserInput: any = {
            ...createUserInput,
            Id: "",
            CreatedOnUtc: new Date(),
            CustomerId: createUserInput.CustomerId as string,
            Message: createUserInput.Message as string,
            Points: (typeof createUserInput.Points === 'string' ? parseInt(createUserInput.Points) : createUserInput.Points) as number,
        };
        delete filteredUserInput['id'];

        let result = await this.internalHttp.post(this.endpoint.Create(), filteredUserInput);
        result.data.id = result.data.Id;
        return transformPropsFirstLetterToUpperCase(result.data);
    }

    public async get(entityDto: LoyaltyPointsHistoryDto): Promise<LoyaltyPointsHistoryDto> {
        let result = await this.internalHttp.get(this.endpoint.Get(entityDto.CustomerId));

        return transformPropsFirstLetterToUpperCase(result.data.value[result.data.value.length - 1]);
    }
}

export default new LoyaltyPointsHistoryService();