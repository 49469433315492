import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { CrudServiceBase } from '../base/crudServiceBase';
import { ApkAttachedFilesDto } from './apkAttachedFilesDto';
import { isUserLoggedIn } from '../../utils/authUtils';
import { SasResult } from '../azureService';

export class ApkAttachedFilesService extends CrudServiceBase<ApkAttachedFilesDto> {
    constructor() {
        super(Endpoint.ApkAttachedFiles);
        this.internalHttp = httpApi;
    }

    public async createNew(createPolicyCalculationAttachedFileInput: ApkAttachedFilesDto) {
        isUserLoggedIn();
        const copyCreatePolicyCalculationAttachedFileInput = {...createPolicyCalculationAttachedFileInput, id: parseInt(createPolicyCalculationAttachedFileInput.id)};
        let result = await httpApi.post(this.endpoint.Create(), copyCreatePolicyCalculationAttachedFileInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getAllFiles() {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.GetAll());
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getApkByClientId(clientId: string) {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetApkByClientId?clientId=${clientId}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getApkForManualPolicy(clientId: string, productName: string) {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetApkForManualPolicy?clientId=${clientId}&productName=${productName}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByCalculationId(calculationId: number) {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetByCalculationId?calculationId=${calculationId}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByPolicyId(policyId: number) {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetApkByPolicyId?policyId=${policyId}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async sendApk(requestBody: any) {
        isUserLoggedIn();
        let result = await this.internalHttp.post(this.endpoint.Custom('SendApk', false), requestBody);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async reSendApk(apkAttachedFileId: any) {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`ReSendApk?apkAttachedFileId=${apkAttachedFileId}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getApkById(apkAttachedFileId: any) {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetApkById?apkAttachedFileId=${apkAttachedFileId}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getApk(requestBody: any) {
        isUserLoggedIn();
        let result = await this.internalHttp.post(this.endpoint.Custom('GetApk', false), requestBody);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async SendPasswordBySms(uuid: string) {
        let result = await this.internalHttp.post(this.endpoint.Custom(`SendPasswordBySms/${uuid}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getPassword(uuid: string) {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetPassword/${uuid}`, false));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async GenerateSasTokenForSignedAPK(policyId: number): Promise<SasResult> {
        let result = await this.internalHttp.post(this.endpoint.Custom(`GenerateSasTokenForSignedAPK?policyId=${policyId}`));
        return result.data.result;
    }
}

export default new ApkAttachedFilesService();