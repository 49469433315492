import { IComboBoxOption, IDropdownOption, SelectableOptionMenuItemType } from "@fluentui/react";
import { L } from "../lib/abpUtility";
import AppConfig from "../lib/appconfig";
import { CustomerGroupDto } from "../services/customerGroup/customerGroupDto";
import CustomerGroupStore from "../stores/customerGroupStore";
import { isCountriesInput } from "./inputUtils";
import { filterBySome } from "./utils";

export function getCustomerName(customer: any): any {
    if(customer.FirstName && customer.LastName) {
        return {
            FirstName: customer.FirstName,
            LastName: customer.LastName
        };
    } else if(customer.Addresses && customer.Addresses.length > 0) {
        let returnValue = customer.Email;

        customer.Addresses.some((address: any) => {
            if(address.FirstName && address.LastName) {
                returnValue = {
                    FirstName: address.FirstName,
                    LastName: address.LastName
                };
                return true;
            }
            return false;
        });

        return returnValue;
    }

    return null;
}

export function getCustomerAddressData(customer: any, propName: string, forceFromAddress: boolean = false): string {
    if(customer[propName] && typeof customer[propName] === 'string' && customer[propName].length > 0 && !forceFromAddress) {
        return customer[propName];
    } else if(customer.Addresses && customer.Addresses.length > 0) {
        let returnValue = "";

        customer.Addresses.some((address: any) => {
            if(address[propName] && typeof address[propName] === 'string' && address[propName].length > 0) {
                returnValue = address[propName];
                return true;
            }
            return false;
        });

        return returnValue;
    } else if(customer.BillingAddress || customer.billingAddress) {
        return customer.BillingAddress && customer.BillingAddress[propName] ? customer.BillingAddress[propName] : customer.billingAddress[propName];
    }

    return "";
}

export function getSourceDataFromUserFields(dataSource: any, sortBy?: string, gnLanguage?: any, currentValue?: string, type?: string, dataToDisplay?: string, translateNames?: boolean, keyAsText?: boolean): any {
    if(dataSource && dataSource.dataSet && dataSource.dataSet.totalCount > 0) {
        let tempItems = dataSource.dataSet.items;

        if(dataSource.dataSet.totalCount !== tempItems.length) {
            let filteredTempItems: any[] = [];
            dataSource.dataSet.items.forEach((element: any) => {
                const filteredItem = filterBySome(filteredTempItems, 'Id', element.Id);
                if(!filteredItem) {
                    filteredTempItems.push(element);
                }
            });
            tempItems = filteredTempItems;
        }
        
        if(translateNames === true) {
            tempItems = tempItems.map((data: any) => {
                let text = !!dataToDisplay ? data[dataToDisplay] : data.Name;
                if(gnLanguage && data.Locales && data.Locales.length > 0) {
                    data.Locales.some((locale: any) => {
                        if(locale.LanguageId === gnLanguage.Id) {
                            text = locale.LocaleValue;
                            return true;
                        }
                        return false;
                    });
                }

                if(!!dataToDisplay) {
                    data[dataToDisplay] = text;
                }  else {
                    data.Name = text;
                }
                
                return data;
            });
        }

        if(sortBy && sortBy.length > 0) {
            tempItems = tempItems.slice(0).sort((a: any, b: any) => (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0));
        }
        
        return tempItems.map((data: any) => {
            let text = !!dataToDisplay ? data[dataToDisplay] : data.Name;

            if(!!type && type === 'comboBox') {
                return { key: !!keyAsText && keyAsText === true ? text : data.Id, 
                        text: text,
                        selected: !!currentValue && ((!!keyAsText && keyAsText === true && currentValue === text) || currentValue === data.Id) ? true : false } as IComboBoxOption;
            } else {
                return { key: !!keyAsText && keyAsText === true ? text : data.Id, 
                        text: text,
                        isSelected: !!currentValue && ((!!keyAsText && keyAsText === true && currentValue === text) || currentValue === data.Id) ? true : false } as IDropdownOption;
            }
        });
    }
}

export function findHiddenAttributes(attrs: any): any {
    let hiddenAttrIds: string[] = [];
    let hiddenAttrsCount: number = 0;
    let attrsLength: number = 0;

    if(attrs) {
        attrsLength = attrs.length;

        attrs.forEach((attr: any) => {
            if(attr.DisplayOrder < 0) {
                hiddenAttrsCount++;
                hiddenAttrIds.push(attr.Id);
            }
        });
    }

    return { hiddenAttrIds: hiddenAttrIds, hiddenAttrsCount: hiddenAttrsCount, itemsCountWithoutHidden: attrsLength - hiddenAttrsCount };
}

export function getCustomerGroupFromDataSet(customerGroupStore: CustomerGroupStore, name: string): CustomerGroupDto {
    let searchedGroup = customerGroupStore.model;

    if(customerGroupStore.dataSet && customerGroupStore.dataSet.totalCount > 0) {
        customerGroupStore.dataSet.items.some((customerGroup: CustomerGroupDto) => {
            if(customerGroup.Name === name) {
                searchedGroup = customerGroup;
                return true;
            }
            return false;
        });
    }

    return searchedGroup;
}

export function generateDropdownOptionsIfCountriesInput(attr: any, dropdownOptions: IDropdownOption[]): IDropdownOption[] {    
    if(isCountriesInput(attr)) {
        dropdownOptions.splice(AppConfig.countOfSuggestedCountriesInDropdown, 0, {
            key: 'divider', 
            text: '',
            isSelected: false,
            itemType: SelectableOptionMenuItemType.Divider
        });
        dropdownOptions.unshift({
            key: 'headerSuggested', 
            text: L('Suggested'),
            isSelected: false,
            itemType: SelectableOptionMenuItemType.Header
        });
        dropdownOptions.splice(2 + AppConfig.countOfSuggestedCountriesInDropdown, 0, {
            key: 'headerOther', 
            text: L('Other'),
            isSelected: false,
            itemType: SelectableOptionMenuItemType.Header
        });
    }

    return dropdownOptions;
}