import { isUserLoggedIn } from '../../utils/authUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ContestDto } from './dto/contestDto';

export class ContestService extends CrudServiceBase<ContestDto> {
    constructor() {
        super(Endpoint.Contest);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: ContestDto) {
        isUserLoggedIn();
        
        let newCreateUserInput = getPartialModel(createUserInput, [], ['id']);

        if(!Array.isArray(newCreateUserInput.prizeItems)) {
            const clonePrizeItems: any[] = [];
            for(let key in Object.keys(newCreateUserInput.prizeItems)) {
                clonePrizeItems.push(newCreateUserInput.prizeItems[key]);
            }
            newCreateUserInput.prizeItems = clonePrizeItems;
        }

        if(newCreateUserInput.infinitePrizes === true) {
            newCreateUserInput.prizesAmount = 0;
        }

        let result = await this.internalHttp.post(this.endpoint.Create(), newCreateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateUserInput: ContestDto) {
        isUserLoggedIn();
        
        let newUpdateUserInput = getPartialModel(updateUserInput, []);

        if(!Array.isArray(newUpdateUserInput.prizeItems)) {
            const clonePrizeItems: any[] = [];
            for(let key in Object.keys(newUpdateUserInput.prizeItems)) {
                clonePrizeItems.push(newUpdateUserInput.prizeItems[key]);
            }
            newUpdateUserInput.prizeItems = clonePrizeItems;
        }

        if(newUpdateUserInput.infinitePrizes === true) {
            newUpdateUserInput.prizesAmount = 0;
        }

        let result = await this.internalHttp.put(this.endpoint.Update(), newUpdateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new ContestService();