import { CrudServiceBaseTransformed } from '../base/crudServiceBaseTransformed';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ProductTypeDto } from './productTypeDto';

export class ProductTypeService extends CrudServiceBaseTransformed<ProductTypeDto> {
    constructor() {
        super(Endpoint.ProductType);
        this.internalHttp = httpApi;
    }
}

export default new ProductTypeService();