import { isUserLoggedIn } from '../../utils/authUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { PrizeDto } from './dto/prizeDto';

export class PrizeService extends CrudServiceBase<PrizeDto> {
    constructor() {
        super(Endpoint.Prize);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: PrizeDto) {
        isUserLoggedIn();
        
        let newCreateUserInput = getPartialModel(createUserInput, [], ['id']);

        let result = await this.internalHttp.post(this.endpoint.Create(), newCreateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateUserInput: PrizeDto) {
        isUserLoggedIn();
        
        let newUpdateUserInput = getPartialModel(updateUserInput, [], ['lastModificationTime', 'lastModifierUserId']);

        let result = await this.internalHttp.put(this.endpoint.Update(), newUpdateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new PrizeService();