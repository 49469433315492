import Endpoint from '../endpoint';
import { InsurancePolicyDto } from './insurancePolicyDto';
import { httpApi } from '../httpService';
import { CrudServiceBase } from '../base/crudServiceBase';
export class InsurancePolicyService extends CrudServiceBase<InsurancePolicyDto> {
    constructor() {
        super(Endpoint.InsurancePolicy);
        this.internalHttp = httpApi;
    }

    async getPolicy(insurer: string, offerNumber: number) {
        return await this.internalHttp.get(this.endpoint.Custom(`GetInsurerPolicy?insurerName=${insurer}&offerNumber=${offerNumber}`, true));
    }

    async getSingle(policyId: number) {
        return await this.internalHttp.get(this.endpoint.Custom(`Get?Id=${policyId}`, true));
    }

    async getExtended(policyId: number) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetExtended?Id=${policyId}`, true));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async GetPolicyUrl(insurerName: string, offerNumber: string) {
        return await this.internalHttp.get(this.endpoint.Custom(`GetPolicyUrl?insurerName=${insurerName}&policyNumber=${offerNumber}`, true));
    }

    async getPolicyByOrderId(orderId: string) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByOrderId?orderId=${orderId}`, true));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async GetPolicyByCustomerId(customerId: string) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByCustomerId?customerId=${customerId}`, true));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async DownloadPolicyDocuments(policyId: number) {
        let result = await this.internalHttp.post(Endpoint.PolicyPdf.Custom(`Generate`), { abpPolicyId: policyId, calculationType: "standard" });
        return result.data;
    }

    async DownloadCalculationOfferPDF(calculationId: string, insurerName: string) {
        let result = await this.internalHttp.post(Endpoint.PolicyCalculationPdf.Custom(`GenerateCalculation`), { insurerName, calculationId });
        return result.data;
    }

    async manualCreate(requestBody: any) {
        const requestBodyMerged = {
            ...requestBody
        }
        let result = await this.internalHttp.post(this.endpoint.Custom(`ManualCreate`, true), requestBodyMerged);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async CancelPolicy(policyId: number, reason: string, cancelledDate: string, confirmation: boolean) {
        let result = await this.internalHttp.post(this.endpoint.Custom(`Cancel`), { policyId, reason, cancelledDate, confirmation });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async ManualCancelPolicy(policyId: number, reason: string, cancelledDate: string, confirmation: boolean) {
        let result = await this.internalHttp.post(this.endpoint.Custom(`ManualCancel`), { policyId, reason, cancelledDate, confirmation });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new InsurancePolicyService();