import { CrudStoreBase } from './crudStoreBase';
import { ApkAttachedFilesDto } from '../services/apkAttachedFiles/apkAttachedFilesDto';
import apkAttachedFilesService from '../services/apkAttachedFiles/apkAttachedFilesService';

class ApkAttachedFilesStore extends CrudStoreBase<ApkAttachedFilesDto>{
  constructor() {
    super(apkAttachedFilesService, defaultApkAttachedFile)
  }

  public async getAllFiles() {
		return apkAttachedFilesService.getAllFiles();
	}

  public async getApkByClientId(clientId: string) {
		await apkAttachedFilesService.getApkByClientId(clientId).then((result: any) => {
			this.dataSet = result;
		}).catch((error: any) => {
			console.error(error);
		});
	}

  public async getApkForManualPolicy(clientId: string, productName: string) {
		await apkAttachedFilesService.getApkForManualPolicy(clientId, productName).then((result: any) => {
			this.dataSet = result;
		}).catch((error: any) => {
			console.error(error);
		});
	}
}

export const defaultApkAttachedFile = {
  id: "",
  fileUrl: "",
  originalFileName: "",
  blobFileName: "",
  status: "",
  type: "",
  customerId: "",
  clientId: 0,
  payload: "",
  policyId: null,
  policyNumber: null,
  policyIssued: false,
}

export default ApkAttachedFilesStore;