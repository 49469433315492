import { ChangeLanguagaInput } from './dto/changeLanguageInput';
import { CreateOrUpdateUserInput } from './dto/createOrUpdateUserInput';
import { EntityDto } from '../dto/entityDto';
import { GetAllUserOutput } from './dto/getAllUserOutput';
import { PagedResultDto } from '../../services/dto/pagedResultDto';
import { PagedUserResultRequestDto } from "./dto/PagedUserResultRequestDto";
import { UpdateUserInput } from './dto/updateUserInput';
import http from '../httpService';
import { isUserLoggedIn } from '../../utils/authUtils';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import { UserDto } from './dto/userDto';
import { CrudConsts } from '../../stores/crudStoreBase';

class UserService {
  defaultRequest: any = { keyword: "", maxResultCount: CrudConsts.PAGE_SIZE, skipCount: 0 };
  
  public async create(createUserInput: CreateOrUpdateUserInput) {
    isUserLoggedIn();
    let result = await http.post('api/services/app/User/Create', createUserInput);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async update(updateUserInput: UpdateUserInput) {
    isUserLoggedIn();
    let result = await http.put('api/services/app/User/Update', updateUserInput);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async delete(entityDto: EntityDto) {
    isUserLoggedIn();
    let result = await http.delete('api/services/app/User/Delete', { params: entityDto });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getRoles() {
    isUserLoggedIn();
    let result = await http.get('api/services/app/User/GetRoles');
    return !!result.data && !!result.data.result ? result.data.result.items : result.data.items;
  }

  public async changeLanguage(changeLanguageInput: ChangeLanguagaInput) {
    isUserLoggedIn();
    let result = await http.post('api/services/app/User/ChangeLanguage', changeLanguageInput);
    return result.data;
  }

  public async get(entityDto: EntityDto): Promise<CreateOrUpdateUserInput> {
    isUserLoggedIn();
    let result = await http.get('api/services/app/User/Get', { params: entityDto });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedUserResultRequestDto): Promise<PagedResultDto<GetAllUserOutput>> {
    isUserLoggedIn();
    let result = await http.get('api/services/app/User/GetAll', { params: pagedFilterAndSortedRequest });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getNoAssignedUser(pagedFilterAndSortedRequest: PagedUserResultRequestDto): Promise<PagedResultDto<GetAllUserOutput>> {
    isUserLoggedIn();
    let result = await http.get('api/services/app/User/GetNoAssignedUser', { params: pagedFilterAndSortedRequest });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getClientForUser(userId: number): Promise<ClientIdDto[]> {
    isUserLoggedIn();
    let result = await http.get('api/services/app/User/GetClientForUser?userId=' + userId);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async getAllDeletedInLast30Days(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<UserDto>> {
    isUserLoggedIn();
    let result = await http.get('api/services/app/User/GetAllDeletedInLast30Days', { params: pagedFilterAndSortedRequest });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async restore(userId: number) {
    isUserLoggedIn();
    return await http.post(`api/services/app/User/Restore/?userId=${userId}`);
}
}

export default new UserService();

export interface ClientIdDto {
  id: number;
  role: string;
}
