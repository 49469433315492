import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { OrderDto } from './dto/orderDto';

export class OrderService extends CrudServiceBase<OrderDto> {
    constructor() {
        super(Endpoint.Order);
        this.internalHttp = httpApi;
    }
}

export default new OrderService();