import { VehicleDto } from '../services/vehicle/vehicleDto';
import VehicleService from '../services/vehicle/vehicleService';
import { CrudStoreBase } from './crudStoreBase';

class VehicleStore extends CrudStoreBase<VehicleDto>{
	constructor() {
		super(VehicleService, defaultVehicle)
	}

	public async getByClientId(clientId: string) {
		await VehicleService.getByClientId(clientId).then((result: any) => {
			this.dataSet = {
				totalCount: result.items.length, 
				items: result.items
			};
		}).catch((error: any) => {
			console.error(error);
		});
	}
}

export const defaultVehicle = {
	id: '',
	registrationNumber: '',
	eurotaxCarId: '',
	infoExpertId: '',
	productionYear: 0,
	mileage: 0,
	vehicleType: '',
	firstRegistrationDate: '',
	fuelType: '',
	vin: '',
	clientId: '',
	clientName: '',
	vehicleInfo: '',
}

export default VehicleStore;