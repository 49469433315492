import { Icon, mergeStyleSets, SearchBox, ThemeProvider } from '@fluentui/react';
import { L } from '../../lib/abpUtility';
import AppConsts from '../../lib/appconst';
import { SportFluentListDto } from '../../services/sportDiscipline/dto/sportFluentListDto';
import { fluentTableClassNames } from '../../styles/fluentTableStyles';
import {additionalTheme, myTheme} from '../../styles/theme';
import { FluentTableBase } from '../Fluent/base/fluentTableBase';
import { ICrudPermissons } from './commandBarBase';
import { ITableColumn } from './ITableColumn';
import { LabelComponent } from './labelComponent';
import { LabelContainerComponent } from './labelContainerComponent';

var _ = require('lodash');

const classNames = mergeStyleSets({
  requiredMarker: {
      color: 'rgb(164, 38, 44)',
      marginLeft: '5px',
  }
});

export class SportFluentListBase extends FluentTableBase<SportFluentListDto> {
  private debouncedOnSearchboxChange: any = _.debounce((e: any, newValue: string | undefined, customPayload?: any) => {
    newValue = typeof newValue === 'undefined' || newValue.length === 0 ? " " : newValue;
    if(this.props.customOnSearchTextChanged) {
      this.props.customOnSearchTextChanged(newValue);
    } else {
      this.overrideFilter(newValue);
    }
  }, AppConsts.defaultSerachBarDelay, []);
  
  getItemDisplayNameOf(item: SportFluentListDto): string {
    if(!!item.name) {
      return item.name;
    } else {
      return item.id;
    }
  }

  getColumns(): ITableColumn[] {
    return this.getTableColumns(this.props);
  }

  private getTableColumns(props: any): ITableColumn[] {
    return [          
      {
        name: L('Name'),
        fieldName: 'name',
      },
    ];
  }

  getCrudPermission(): ICrudPermissons {
    return {
      create: false,
      update: false,
      delete: false,
      customActions: false,
    };
  }

  renderAll(pageInfo: string, values: any, columns: any) {
    return <>
      <LabelContainerComponent marginTop={'0'}>
        <LabelComponent label={L('Select sport discipline')} />
        <SearchBox
          theme={myTheme}
          styles={{
            root: {
              flex: 1,
              maxWidth: '252px',
              height: '32px', 
              backgroundColor: myTheme.palette.white,
              border: `1px solid ${myTheme.palette.black}`,
              boxSizing: 'border-box',
            },
            field: { borderRadius: '2px' },
          }}
          placeholder={ L('Search') }
          onChange={ (e: any, newValue: string | undefined) => {
            this.debouncedOnSearchboxChange(e, newValue);
          }}
        />
        {(this.props.customData.required && this.props.customData.required === true) && <span className={classNames.requiredMarker}>*</span>}

        {(this.props.customData.selectedItems && typeof this.props.customData.selectedItems !== 'undefined') &&
          <div className={fluentTableClassNames.summaryAttributeWrapper}>
            {(Array.isArray(this.props.customData.selectedItems)) &&
                this.props.customData.selectedItems.map((item: any) => {
                  return <p className={fluentTableClassNames.summaryAttribute}>
                    <span>{item.name ? item.name : ''}</span>
                    <div style={{backgroundColor: additionalTheme.white, borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px'}}>
                      <Icon iconName="Cancel" style={{marginRight: '0', cursor: 'pointer', color: additionalTheme.lighterGrey, fontWeight: 'bold'}} title={L("Delete")}
                        onClick={() => { if(this.props.customOnSelectionChanged) this.props.customOnSelectionChanged({action: 'deleteSport', id: item.id}) }} />
                    </div>
                  </p>
                }
              )
            }
          </div>
        }
      </LabelContainerComponent>

      <ThemeProvider theme={myTheme}>
        {this.renderAnnounced(pageInfo)}

        {this.renderListScrollablePane(values, columns)}
      </ThemeProvider>
    </>;
  }
}