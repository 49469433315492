import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import {
  DefaultButton,
  mergeStyleSets,
  MessageBarType,
  PrimaryButton,
  registerIcons,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { myTheme } from "../../../styles/theme";
import { L } from "../../../lib/abpUtility";
import "./signInButton.css";
import AppConsts from "../../../lib/appconst";
import { Container } from "../../../stores/storeInitializer";
import UserStore from "../../../stores/userStore";
import { AzureB2CStorage, AzureResponse } from "./azureResponse";
import { useCallback, useEffect, useState } from "react";
import { authStore } from "../../../stores/authenticationStore";
import SessionStore from "../../../stores/sessionStore";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
declare var abp: any;
declare var window: any;
var _ = require("lodash");
export const AzureB2CStorageKey = "AzureB2CStorageKey";

const classNames = mergeStyleSets({
  primarySignInButton: {
    padding: "30px 100px !important",
    margin: "50px auto !important",
    fontSize: "20px",
  },
  loadSpinner: {
    display: "flex",
    selectors: {
      "& .ms-Spinner-label": {
        color: myTheme.palette.themePrimary,
      },
    },
  },
  googleWorkspaceSignInButton: {
    padding: "20px 50px 20px 10px !important",
    margin: "0 auto 50px auto !important",
    background: "#fff",
    fontSize: "16px",
  },
});

registerIcons({
  icons: {
    google: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="25"
        height="25"
        viewBox="0 0 48 48"
      >
        <path
          fill="#FFC107"
          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
        ></path>
        <path
          fill="#FF3D00"
          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
        ></path>
        <path
          fill="#4CAF50"
          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
        ></path>
        <path
          fill="#1976D2"
          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
        ></path>
      </svg>
    ),
  },
});

export interface ISignInButtonProps {
  setMessageBarData?: (
    hide: boolean,
    text: string,
    type: MessageBarType
  ) => void;
  messageBarVisibleAndNotSuccess: boolean;
  sessionStore?: SessionStore;
}

interface IIdTokenClaimsProps {
  aud: string;
  auth_time: number;
  emails: string[];
  exp: number;
  extension_Role: string;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  sub: string;
  tfp: string;
  ver: string;
}

export const SignInButton = (props: ISignInButtonProps) => {
  const { instance, accounts } = useMsal();
  const [customActionButtonDisabled, setCustomActionButtonDisabled] =
    useState<boolean>(true);
  const [setTokenCallInitialized, setSetTokenCallInitialized] =
    useState<boolean>(false);

  // eslint-disable-next-line
  const throttledSetToken = useCallback(
    _.throttle((response: AzureResponse) => {
      setToken(response);
    }, 5000),
    []
  );

  const checkRoles = (roles: string): boolean => {
    let response: boolean = true;
    const notAllowedRoles: string[] = ["GUEST", "MOBILEUSER", "CLIENT"];
    const splittedRoles: string[] = roles.split(",");
    let foundNotAllowedRoles: number = 0;

    splittedRoles.forEach((role: string) => {
      if (notAllowedRoles.includes(role.toUpperCase())) {
        foundNotAllowedRoles++;
      }
    });

    if (foundNotAllowedRoles === splittedRoles.length) {
      return false;
    }

    return response;
  };

  const setToken = async (response: AzureResponse) => {
    setCustomActionButtonDisabled(true);

    const idTokenClaims: IIdTokenClaimsProps | undefined = response
      ? response.account
        ? response.account.idTokenClaims
          ? response.account.idTokenClaims
          : undefined
        : undefined
      : undefined;
    if (idTokenClaims) {
      let tokenExpireDate = new Date(
        new Date().getTime() + 1000 * idTokenClaims.exp
      );
      abp.auth.setToken(response.idToken, tokenExpireDate);
      abp.utils.setCookieValue(
        AppConsts.authorization.encrptedAuthTokenName,
        response.idToken,
        tokenExpireDate,
        abp.appPath
      );

      if (props.sessionStore) {
        await props
          .sessionStore!.getCurrentLoginInformations()
          .catch((error: any) => {
            if (props.setMessageBarData) {
              props.setMessageBarData(
                false,
                L("Cannot get login informations, try again later."),
                MessageBarType.error
              );
            } else {
              console.error(L("Cannot get login informations. Error:", error));
            }
            authStore.logout();
            return;
          });
      }

      if (idTokenClaims.emails.length > 0) {
        if (
          !idTokenClaims.extension_Role ||
          idTokenClaims.extension_Role.length === 0
        ) {
          if (props.setMessageBarData) {
            props.setMessageBarData(
              false,
              L(
                "Your account is waiting for approval by the administrator. Please be patient.\r\n\r\n* If you just changed your password, please close this message and login again."
              ),
              MessageBarType.warning
            );
          } else {
            console.warn(
              L(
                "Your account is waiting for approval by the administrator. Please be patient.\r\n\r\n* If you just changed your password, please close this message and login again."
              )
            );
          }
          authStore.logout();
          setCustomActionButtonDisabled(false);
        } else if (!checkRoles(idTokenClaims.extension_Role)) {
          if (props.setMessageBarData) {
            props.setMessageBarData(
              false,
              L(
                "Your permissions do not allow you to log in to the Agent Panel."
              ),
              MessageBarType.warning
            );
          } else {
            console.warn(
              L(
                "Your permissions do not allow you to log in to the Agent Panel."
              )
            );
          }
          authStore.logout();
          setCustomActionButtonDisabled(false);
        } else {
          let user;

          if (
            props.sessionStore &&
            props.sessionStore.currentLogin &&
            props.sessionStore.currentLogin.user
          ) {
            user = { ...props.sessionStore?.currentLogin.user };
          } else {
            const users = await Container.getStore<UserStore>(
              "UserStore"
            ).getAllWithKeyword(response.account.username);
            if (users && users.length > 0) {
              user = users[0];
            }
          }

          if (user && user.id > 0) {
            localStorage.setItem(
              AzureB2CStorageKey,
              JSON.stringify({ b2c: response, user: user } as AzureB2CStorage)
            );
            window.location = "/";
          } else {
            if (props.setMessageBarData) {
              props.setMessageBarData(
                false,
                L("Login failed, user not found."),
                MessageBarType.error
              );
            }
            console.error(
              "Login failed, idTokenClaims | user:",
              idTokenClaims,
              user
            );
          }
        }
      } else {
        if (props.setMessageBarData) {
          props.setMessageBarData(
            false,
            L("Login failed, token not vaild."),
            MessageBarType.error
          );
        }
        console.error("Login failed, idTokenClaims:", idTokenClaims);
        setCustomActionButtonDisabled(false);
      }
    } else {
      if (props.setMessageBarData) {
        props.setMessageBarData(
          false,
          L("Login failed, invalid token."),
          MessageBarType.error
        );
      }
      console.error("Login failed, invalid response (token):", response);
      setCustomActionButtonDisabled(false);
    }
  };

  const login = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        if (setTokenCallInitialized === false) {
          setSetTokenCallInitialized(true);
          throttledSetToken(response as AzureResponse);
        }
      })
      .catch((e) => {
        console.error(e);
        instance
          .acquireTokenPopup(request)
          .then((response) => {
            if (setTokenCallInitialized === false) {
              setSetTokenCallInitialized(true);
              throttledSetToken(response as AzureResponse);
            }
          })
          .catch((e) => {
            console.error(e);
            setCustomActionButtonDisabled(false);
          });
      });
  };

  const handleLogin = (
    loginType: "popup" | "redirect" | "redirectWorkspace"
  ) => {
    setCustomActionButtonDisabled(true);

    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then(() => {
          login();
        })
        .catch((e) => {
          console.error(e);
          setCustomActionButtonDisabled(false);
        });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
        setCustomActionButtonDisabled(false);
      });
    } else if (loginType === "redirectWorkspace") {
      instance
        .loginRedirect({ ...loginRequest, domainHint: "googleWorkspace" })
        .catch((e) => {
          console.error(e);
          setCustomActionButtonDisabled(false);
        });
    }
  };

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((authResult) => {
        if (authResult !== null) {
          if (setTokenCallInitialized === false) {
            setSetTokenCallInitialized(true);
            throttledSetToken(authResult as AzureResponse);
          }
        } else if (customActionButtonDisabled === true) {
          setCustomActionButtonDisabled(false);
          if (window.location.hash !== "" && props && props.setMessageBarData) {
            console.error(`Auth result:`, authResult);
            props.setMessageBarData(
              false,
              L("Login failed, authorization response not valid."),
              MessageBarType.error
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
        if (props && props.setMessageBarData) {
          setCustomActionButtonDisabled(false);
          props.setMessageBarData(
            false,
            L("Login failed, authorization response not valid."),
            MessageBarType.error
          );
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="signInButton">
      <PrimaryButton
        theme={myTheme}
        text={L("Sign in")}
        onClick={() => handleLogin("redirect")}
        className={`${classNames.primarySignInButton} form-btn signInButton__button`}
        disabled={
          customActionButtonDisabled || props.messageBarVisibleAndNotSuccess
        }
      />
      <DefaultButton
        iconProps={{
          iconName: "google",
          style: { marginRight: "20px", fontSize: "20px", height:'auto' },
        }}
        text={L("Continue with Google Workspace")}
        onClick={() => handleLogin("redirectWorkspace")}
        className={`${classNames.googleWorkspaceSignInButton} form-btn signInButton__button`}
        primary={false}
        disabled={
          customActionButtonDisabled || props.messageBarVisibleAndNotSuccess
        }
      />
      {customActionButtonDisabled && (
        <Spinner
          label={L("Please wait...")}
          className={classNames.loadSpinner}
          size={SpinnerSize.large}
          ariaLive="assertive"
          labelPosition="right"
        />
      )}
    </div>
  );
};
