import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBaseTransformed } from '../base/crudServiceBaseTransformed';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ProductDto } from './productDto';

export class ProductService extends CrudServiceBaseTransformed<ProductDto> {
    constructor() {
        super(Endpoint.Product);
        this.internalHttp = httpApi;
    }

    async getProductKeysToIdMapper() {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetProductKeysToIdMapper`, true));

        // return transformPropsFirstLetterToUpperCase(!!result.data && !!result.data.result ? result.data.result : result.data);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async getAllUserFields() {
        isUserLoggedIn();
        let result = await httpApi.get(new Endpoint("Product", "", false).Custom(`GetAllUserFields`));

        // return transformPropsFirstLetterToUpperCase(result.data && result.data.result && result.data.result.items ? result.data.result.items : []);
        return result.data && result.data.result && result.data.result.items ? result.data.result.items : [];
    }
}

export default new ProductService();