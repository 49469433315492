import * as React from 'react';
import { TextField, ITextFieldProps, MaskedTextField, IconButton, mergeStyleSets, Shimmer } from '@fluentui/react';
import { inject } from 'mobx-react';
import EventStore from '../../stores/eventStore';
import Stores from '../../stores/storeIdentifier';
import { getShimmerStyles } from '../../utils/stylesUtils';
import { L } from '../../lib/abpUtility';

const classNames = mergeStyleSets({
  textField: {
    selectors: {
      '& textarea': {
        resize: 'vertical',
      }
    }
  },
  suffix: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'transparent',
    selectors: {
      '& .ms-TextField-suffix': {
        background: 'transparent',
        padding: '0 2px'
      },
      '& .ms-Button': {
        selectors: {
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

export interface ITextFieldBaseProps extends ITextFieldProps {
  eventStore?: EventStore;
  id?: string | undefined;
  isMask?: boolean;
  isDataLoaded?: boolean;
  required?: boolean;
  iconName?: string;
  validationData?: any;
  customClassNames?: string;
  errorMessage?: string;
  customWidth?: number;
  customLabelStyles?: any;
  labelContainerCustomStyles?: any;
  customInputStyles?: any;
  tooltipText?: string;
  customShimmerStyles?: any;
  iconOnClick?: (iconIndex?: number) => void;
  stickyButton?: () => void | undefined;
}

@inject(Stores.EventStore)
export class TextFieldBase extends React.Component<ITextFieldBaseProps> {
  private maskFormat: { [key: string]: RegExp } = {
    '9': /[0-9]/,
    'a': /[a-zA-Z]/,
    '*': /[a-zA-Z0-9-]/,
  };

  private stringToRegExp(str: string): any {
    let regParts = str.match(/^\/(.*?)\/([gim]*)$/);

    if (regParts) {
        // the parsed pattern had delimiters and modifiers. handle them. 
        return new RegExp(regParts[1], regParts[2]);
    } else {
        // we got pattern string without delimiters
        return new RegExp(str);
    }
  }

  private mapValidationData(validationData: any): any {
    let mappedValidationData: any = {};
    let newMaskFormatKey: string = '';
    let newMaskFormatValue: string = '';

    let notDefaultBehaviourForKeys: string[] = ['inputType', 'multiline',  'placeholder', 'errorMessage', 'maxlength', 'minlength',
                                                'maskFormatKey', 'maskFormatValue', 'maskChar', 'autoincrement', 'readOnly', 'disabled'];

    for(const key in validationData) {
      if (Object.prototype.hasOwnProperty.call(validationData, key)) {
        if(key === 'maskFormatKey') {
          newMaskFormatKey = validationData[key];
        }

        if(key === 'maskFormatValue') {
          newMaskFormatValue = validationData[key];
        }

        if(key === 'multiline') {
          mappedValidationData['multiline'] = 'true';
          mappedValidationData['rows'] = validationData[key];
        }

        if(key === 'maxlength') {
          mappedValidationData['maxLength'] = validationData[key];
        }

        if(key === 'minlength') {
          mappedValidationData['minLength'] = validationData[key];
        }

        if(key === 'placeholder' || key === 'errorMessage') {
          mappedValidationData[key] = L(validationData[key]);
        }

        if(key === 'readOnly' || key === 'disabled') {
          mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
        }

        if(key === 'maskChar') {
          if(validationData[key] === "null") {
            mappedValidationData['maskChar'] = '';
          } else {
            mappedValidationData['maskChar'] = validationData[key];
          }
        }

        if(!notDefaultBehaviourForKeys.includes(key)) {
          mappedValidationData[key] = validationData[key];
        }
      }
    }

    if(newMaskFormatKey.length > 0 && newMaskFormatValue.length > 0) {
      this.maskFormat = {};
      this.maskFormat[newMaskFormatKey] = this.stringToRegExp(newMaskFormatValue);
    }

    return mappedValidationData;
  }

  componentDidMount() {
    try {
      if (this.props.value) {
        this.setState({ value: this.props.value });
        if (this.props.onChange) this.props.onChange(`${this.props.value}` as any, `${this.props.value}`);
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  maskedChange = (value: string | undefined) => {
    if(this.props.onChange)
      this.props.onChange(value as any);
  };

  _onRenderPrefix = (): JSX.Element | null => {
    return (
      <IconButton
        split
        iconProps={{ iconName: 'Search' }}
        splitButtonAriaLabel={L("See 2 options")}
        aria-roledescription={L("split button")}
        ariaLabel={L("Find")}
        onClick={() => {
          if(this.props.stickyButton)
            this.props.stickyButton();
        }}
      />
    );
  };

  render() {
    const { eventStore, id, isMask, isDataLoaded, validationData, stickyButton } = this.props;
    let prefix = undefined;
    if (stickyButton) {
      prefix = this._onRenderPrefix;
    }

    let mappedValidationData = this.mapValidationData(validationData);

    return (
      <>
        <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} style={{...this.props.customShimmerStyles}} ariaLabel={L("Loading content")}>
          {isMask || mappedValidationData.mask ? (
            <MaskedTextField
              styles={{
                field: {
                  width: `${this.props.width}px`,
                  height: `33px`,
                  ...this.props.customInputStyles,
                }
              }}
              onRenderSuffix={prefix}
              {...this.props}
              // onChange={(a, b) => {
              //   this.maskedChange(b);
              // }}
              errorMessage={eventStore!.getErrorMessageFor(id) || this.props.errorMessage}
              value={this.props.value || this.props.defaultValue || ''}
              className={`${classNames.textField} ${stickyButton ? classNames.suffix : ''} ${this.props.customClassNames && this.props.customClassNames}`}
              maskFormat={this.maskFormat}
              {...mappedValidationData}
            />
          ) : (
            <TextField
              styles={{
                field: {
                  width: `${this.props.width}px`,
                  height: `33px`,
                  ...this.props.customInputStyles,
                }
              }}
              className={`${classNames.textField} ${stickyButton ? classNames.suffix : ''} ${this.props.customClassNames && this.props.customClassNames}`}
              onRenderSuffix={prefix}
              errorMessage={eventStore!.getErrorMessageFor(id) || this.props.errorMessage}
              {...this.props}
              {...mappedValidationData}
            />
          )}
        </Shimmer>
      </>
    );
  }
}