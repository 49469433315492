import { IShimmerStyleProps, IShimmerStyles } from "@fluentui/react";
import { myTheme } from "../styles/theme";

export function getShimmerStyles(props: IShimmerStyleProps): IShimmerStyles {
    return {
        shimmerWrapper: [{
            backgroundColor: myTheme.palette.themeLighter,
            //marginTop: '20px',
            height: '33px',
            width: '252px !important',
            borderRadius: '3px'
        }],
        shimmerGradient: [{
            // width: '252px',
            height: '33px',
            backgroundColor: myTheme.palette.themeLighter,
            backgroundImage:
                `linear-gradient(to right, rgba(255, 255, 255, 0) 0%, ${myTheme.palette.themeTertiary} 50%, rgba(255, 255, 255, 0) 100%)`,
        }],
    };
};