import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { TravelCountryDto } from './dto/travelCountryDto';

export class TravelCountryService extends CrudServiceBase<TravelCountryDto> {
    constructor() {
        super(Endpoint.TravelCountry);
        this.internalHttp = httpApi;
    }

    public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = { ...this.defaultRequest, sorting: 'Name' }): Promise<PagedResultDto<TravelCountryDto>> {
        if (!pagedFilterAndSortedRequest.sorting) {
            pagedFilterAndSortedRequest.sorting = 'Name';
        }
        let result = await this.internalHttp.get(Endpoint.TravelCountry.GetAll(), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
    

    public async create(createCountryTravelInput: TravelCountryDto) {
        isUserLoggedIn();
        
        let result: any = {};
        let travelCountryTravelInputClone: any = {...createCountryTravelInput};
        
        delete travelCountryTravelInputClone['id'];
        
        result = await this.internalHttp.post(Endpoint.TravelCountry.Create(), travelCountryTravelInputClone as TravelCountryDto);
            
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateCountryTravelInput: TravelCountryDto) {
        isUserLoggedIn();
        
        let result: any = {};
        result = await this.internalHttp.put(Endpoint.TravelCountry.Update(), updateCountryTravelInput as TravelCountryDto);
            
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByCountry(countryId: number): Promise<TravelCountryDto> {
        isUserLoggedIn();
        
        let result = await this.internalHttp.get(this.endpoint.Custom('/GetByCountry'), { params: countryId });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new TravelCountryService();