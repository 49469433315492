import { isUserLoggedIn } from '../../utils/authUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { CalculationDto } from './dto/calculationDto';

export class CalculationService extends CrudServiceBase<CalculationDto> {
    constructor() {
        super(Endpoint.Calculation);
        this.internalHttp = httpApi;
    }

    public async get(calculationDto: CalculationDto): Promise<CalculationDto> {
        isUserLoggedIn();

        const filteredModel = getPartialModel(calculationDto, ['id']);

        let result = await this.internalHttp.get(this.endpoint.Get(), { params: filteredModel });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getHistory(entityDto: CalculationDto, pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<CalculationDto> {
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetHistory?Id=${entityDto.id}`), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async delete(entityStringDto: CalculationDto) {
        isUserLoggedIn();
        let result = await this.internalHttp.delete(this.endpoint.Delete(), { params: {id: entityStringDto.id} });
        return result.data;
    }
}

export default new CalculationService();