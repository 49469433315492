import { observable, action } from 'mobx';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { CrudServiceBase } from '../services/base/crudServiceBase';
import { PagedResultRequestDto } from '../services/base/pagedResultRequestDto';
import { EntityStringDto, NamedEntityStringDto } from '../services/dto/entityStringDto';
import { isUserLoggedIn } from '../utils/authUtils';
import { IDropdownOption } from '@fluentui/react';

// var _ = require('lodash');
export class CrudConsts {
  static PAGE_SIZE = 9999;
  static LAZY_LOAD_PAGE_SIZE = 25;
  // Load 'all' - probably there never will be more entires, 
  // Todo make this places possible to load lazy with filters
  static BULK_SIZE = 1000;
}

export class CrudStoreBase<TDto extends EntityStringDto, TCreateDto = TDto> {
  defaultValue: TCreateDto;
  crudService: CrudServiceBase<TDto, TCreateDto>;
  
  constructor(service: CrudServiceBase<TDto, TCreateDto>, defaultValue: TCreateDto) {
    this.crudService = service;
    this.defaultValue = defaultValue;
    this.createDefault();
  }

  @observable dataSet!: PagedResultDto<TDto>;
  @observable model!: TDto | TCreateDto;
  @observable isFullDataset: boolean = false;

  page: number = 0;
  defaultRequest: any = { keyword: "", maxResultCount: CrudConsts.PAGE_SIZE, skipCount: 0, sorting: "" };

  @action
  createDefault(): any {
    let text = JSON.stringify(this.defaultValue);
    this.model = JSON.parse(text) as TCreateDto;
    return this.model;
  }

  @action
  async create(createInput: TCreateDto) {
    isUserLoggedIn();
    return await this.crudService.create(createInput);
  }

  @action
  async update(updateInput: TDto) {
    isUserLoggedIn();
    await this.crudService.update(updateInput);
    try {
      if (this.dataSet && this.dataSet.items)
        this.dataSet.items
          .filter((x: TDto) => x.id === updateInput.id)
          .map((x: TDto) => {
            return (x = updateInput);
          });
    } catch (e) {
      console.error(e);
    }
  }

  @action
  async delete(entityStringDto: TDto) {
    isUserLoggedIn();
    await this.crudService.delete(entityStringDto);
    this.dataSet.items = this.dataSet.items.filter((x: TDto) => x.id !== entityStringDto.id);
    this.dataSet.totalCount = this.dataSet.items.length;
  }

  @action
  async get(entityStringDto: TDto) {
    isUserLoggedIn();
    this.clearModel();

    let result = await this.crudService.get(entityStringDto);
    this.model = result;
    return this.model;
  }

  @action
  clearModel() {
    this.model = this.defaultValue as TCreateDto;
  }

  @action
  async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest) {
    isUserLoggedIn();
    console.info("getAll." + this.crudService.endpoint.dataSet);
    if (this.dataSet && pagedFilterAndSortedRequest.skipCount === 0) {
      this.dataSet.totalCount = -1;
      this.dataSet.items = [];
    }

    if (this.dataSet && this.dataSet.totalCount === this.dataSet.items.length) {
      console.info("Ignoring GetAll requests.");
      return;
    }

    let result = await this.crudService.getAll(pagedFilterAndSortedRequest);
    
    if (this.dataSet && this.dataSet.items) {
      this.dataSet.totalCount = result.totalCount;
      this.dataSet.items = this.dataSet.items.concat(result.items);
      return;
    }

    this.dataSet = {...result};
  }

  @action
  async getUpdated(ids: string[]) {
    isUserLoggedIn();
    let items = Array.from(this.dataSet.items);
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      let entity: EntityStringDto = { id: id };
      let updated = await this.crudService.get(entity);
      let existingId = this.dataSet.items.findIndex(x => x.id === id);
      if (existingId === -1) {
        this.dataSet.totalCount++;
        items.unshift(updated);
      }
      else
        items[existingId] = updated;
    }
    this.dataSet.items = items;
  }

  @action
  async getAllLazy(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest) {
    isUserLoggedIn();
    console.info("getAllContinously." + this.crudService.endpoint.dataSet);
    if (this.dataSet && this.dataSet.totalCount <= this.dataSet.items.length) {
      let page = this.page > 0 ? (this.page - 1) : 0;
      let request = { keyword: "", maxResultCount: CrudConsts.PAGE_SIZE, skipCount: page * CrudConsts.PAGE_SIZE };
      let result = await this.crudService.getAll(request);
      result.items.forEach(x => {
        if (this.dataSet.items.findIndex(y => y.id === x.id) === -1) {
          this.dataSet.items.push(x);
        }
      })
      // todo not see new element when is added - everything is incremented but row is not shown 
      this.dataSet = { totalCount: result.totalCount, items: this.dataSet.items };
      return;
    }

    // let requestsCounter = 0;

    // while ((!this.dataSet || (typeof this.dataSet !== 'undefined' && this.dataSet.totalCount > this.dataSet.items.length)) && requestsCounter <= 5) {
    //   // TODO throttle this requests somehow
    //   // _.throttle(() => {
    //   //   this.getAllLazyThrottled();
    //   // }, 1000);

    //   await this.getAllLazyThrottled();

    //   requestsCounter++;
    // }
  }

  async getAllLazyThrottled() {
    let request = { keyword: "", maxResultCount: CrudConsts.PAGE_SIZE, skipCount: this.page * CrudConsts.PAGE_SIZE };
    this.page++;
    let result = await this.crudService.getAll(request);
    if (this.dataSet) {
      this.dataSet.items = this.dataSet.items.concat(result.items);
    } else {
      if (result.items) {
        this.dataSet = result;
      } else {
        this.dataSet = { items: [], totalCount: 0 };
      }
    }
  }

  getOptions(callbackfn?: (value: TDto) => boolean): IDropdownOption[] {
    if (this.dataSet) {
      let items = this.dataSet.items;
      if (callbackfn) {
        items = items.filter(callbackfn);
      }

      return items.map(this.mapToOption);
    }
    return [];
  }

  mapToOption(item: TDto): IDropdownOption {
    const namedItem = item as any as NamedEntityStringDto;
    return {
      key: item.id,
      text: namedItem && !!namedItem.name ? namedItem.name : item.id.toString()
    }
  }

  async getColumnValue(payload: any) {
    return;
  }
}