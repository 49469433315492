
import { mergeStyleSets} from '@fluentui/merge-styles';
import { L } from '../../lib/abpUtility';
import { myTheme } from '../../styles/theme';

export const createOrUpdateClassNames = mergeStyleSets({
  panelActions: {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    selectors: {
      button: {
        marginRight: '15px'
      }
    }
  },
  loadSpinner: {
    display: 'inline-flex',
    marginRight: 'auto',
    selectors: {
        '& .ms-Spinner-label': {
            color: myTheme.palette.themePrimary,
        }
    }
  },
})

export const checkTextField = (rule: any, value: any, callback: any) => {
  let regEx = /^/;
  let message = L('Wrong format');
  switch (rule.field) {
    case 'zipCode':
      regEx = /^([0-9]{2})(-[0-9]{3})$/;
      message = L('Wrong zip code format');
      break;
    case 'email':
    case 'emailAddress':
      regEx = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      message = L('Wrong email format');
      break;
    case 'phone':
      regEx = /^([0-9]{3})(\s[0-9]{3})(\s[0-9]{3})$|^([0-9]{3})([0-9]{3})([0-9]{3})$/;
      message = L('Wrong phone format');
      break;
    case 'nip':
      regEx = /^([0-9]{10})$/;
      message = L('Wrong NIP');
      break;
    case 'kitSponsor':
      regEx = /^https?:\/\/\w+(\.\w+)*(:[0-9]+)?(\/.*)?$/;
      message = L('Wrong URL');
      break;
    default:
      regEx = /^/;
      message = L('Wrong format');
      break;
  }
  regEx.test(value) ? callback() : callback(L(message));
};


export default createOrUpdateClassNames;