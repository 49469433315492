import { PropsWithChildren } from "react";

export interface ILabelContainerComponentProps{
    alignItemsToRight?: boolean;
    marginTop?: string;
    customStyles?: any;
    flexWrap?: string;
}

export const LabelContainerComponent = (props: PropsWithChildren<ILabelContainerComponentProps>) => (
    <div style={{
        flexShrink: 2,
        flexDirection:'row', 
        display:'flex',
        alignItems: 'flex-start',
        justifyContent: props.alignItemsToRight ? 'flex-end' : 'flex-start',
        borderRadius:'25px',
        padding: '0px',
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: '0px',
        marginTop: props.marginTop ? props.marginTop : '20px',
        width: 'auto',
        flexWrap: props.flexWrap ? 'wrap' : 'nowrap',
        ...props.customStyles,
    }}>{props.children}</div>
);