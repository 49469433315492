import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { AgentClaimAmeStatusDto } from './dto/agentClaimAmeStatusDto';

export class AgentClaimAmeStatusService extends CrudServiceBase<AgentClaimAmeStatusDto> {
    constructor() {
        super(Endpoint.AgentClaimAmeStatus);
        this.internalHttp = httpApi;
    }

    public async getByClaimId(claimId: string) {
        let result = await httpApi.get(this.endpoint.Custom(`GetByClaimId?claimId=${claimId}`, true));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createClaimAmeStatusInput: AgentClaimAmeStatusDto) {
        isUserLoggedIn();

        const filteredClaimHistoryInput: any = {
            ...createClaimAmeStatusInput,
            appraiserFV: createClaimAmeStatusInput.appraiserFV,
            claimId: Number(createClaimAmeStatusInput.claimId),
            pricing: createClaimAmeStatusInput.pricing,
            approvedQuotation: createClaimAmeStatusInput.approvedQuotation
        }
        delete filteredClaimHistoryInput['id'];
        delete filteredClaimHistoryInput['claim'];
        delete filteredClaimHistoryInput['creationTime'];
        delete filteredClaimHistoryInput['lastModificationTime'];
        delete filteredClaimHistoryInput['creatorUserId'];
        delete filteredClaimHistoryInput['lastModifierUserId'];

        let result = await httpApi.post(this.endpoint.Create(), filteredClaimHistoryInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new AgentClaimAmeStatusService();