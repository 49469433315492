export default class Stores {
  static AuthenticationStore: string = 'authenticationStore';
  static RoleStore: string = 'roleStore';
  static TenantStore: string = 'tenantStore';
  static UserStore: string = 'userStore';
  static SessionStore: string = 'sessionStore';
  static AccountStore: string = 'accountStore';
  static SearchStore: string = 'searchStore';
  static EventStore: string = 'eventStore';
  static UserCrudStore: string = 'userCrudStore';
  static ApkAttachedFilesStore: string = 'apkAttachedFilesStore';
  static ProductAttributeStore: string = 'productAttributeStore';
  static CustomerGroupStore: string = 'customerGroupStore';
  static LanguageStore: string = 'languageStore';
  static CountryStore: string = 'countryStore';
  static CountryItemsStore: string = 'countryItemsStore';

  // lib candidate
  static ChatStore: string = 'chatStore';
  static WebHookStore: string = 'webHookStore';

  // Domain specific
  static RatingStore: string = 'ratingStore';
  static ProductStore: string = 'productStore';
  static ProductTypeStore: string = 'productTypeStore';
  static ClaimStore: string = 'claimStore';
  static AgentClaimStore: string = 'agentClaimStore';
  static AgentClaimHistoryStore: string = 'agentClaimHistoryStore';
  static AgentClaimAmeStatusStore: string = 'agentClaimAmeStatusStore';
  static InsuranceCompanyStore: string = 'insuranceCompanyStore';
  static InsurerStore: string = 'insurerStore';
  static InsurancePolicyStore: string = 'insurancePolicyStore';
  static IndividualOfferStore: string = 'individualOfferStore';
  static OrderStore: string = 'orderStore';
  static CalculationStore: string = 'calculationStore';
  static LoyaltyPointsHistoryStore: string = 'loyaltyPointsHistoryStore';
  static PatternStore: string = 'patternStore';
  static CommunityStore: string = 'communityStore';
  static ClientStore: string = 'clientStore';
  static ClientLeasingStore: string = 'clientLeasingStore';
  static ContestStore: string = 'contestStore';
  static ClientContestStore: string = 'clientContestStore'
  static PrizeStore: string = 'prizeStore';
  static TestSetStore: string = 'testSetStore';
  static VehicleStore: string = 'vehicleStore';
  static PaymentStore: string = 'paymentStore';
  static SportDisciplineStore: string = 'sportDisciplineStore';
  static SportInsuranceCoverageStore: string = 'sportInsuranceCoverageStore';
  static TravelCountryStore: string = 'travelCountryStore';
  static TravelCountryCoverageStore: string = 'travelCountryCoverageStore';
  static InsurerAvailabilityStore: string = 'insurerAvailabilityStore';
  static OcTerminationStore: string = 'ocTerminationStore';
}