import { Spinner, SpinnerSize } from '@fluentui/react';
import { myTheme } from '../../styles/theme';

const Loading = () => (
  <div style={{ paddingTop: 100, textAlign: 'center' }}>
    <Spinner theme={myTheme} size={SpinnerSize.large} />
  </div>
);

export default Loading;
