import { PatternDto } from './dto/patternDto';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';

export class PatternService extends CrudServiceBase<PatternDto> {
  constructor() {
    super(Endpoint.Pattern);
  }
}

export default new PatternService();