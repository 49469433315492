import { action, observable } from 'mobx';

class SearchStore {
    @observable searchText!: string | undefined

    @action
    async setText(text: string | undefined) {
        this.searchText = text
    }
}
export default SearchStore;