import { isUserLoggedIn } from '../../utils/authUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { AgentClaimDto } from './dto/agentClaimDto';

export class AgentClaimService extends CrudServiceBase<AgentClaimDto> {
    constructor() {
        super(Endpoint.AgentClaim);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: AgentClaimDto) {
        isUserLoggedIn();

        let newCreateUserInput = getPartialModel(createUserInput, [], ['id', 'client', 'policy', 'agent', 'daysSinceClaimReport', 'claimStatusUpdateDate', 'daysSinceStatusChanged', 'policyNumber', 'insurer', 'claimStatus', 'lastModificationTime', 'lastModifierUserId']);

        let result = await this.internalHttp.post(this.endpoint.Create(), newCreateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateUserInput: AgentClaimDto) {
        isUserLoggedIn();

        let newUpdateUserInput = getPartialModel(updateUserInput, [], ['client', 'policy', 'agent', 'daysSinceClaimReport', 'claimStatusUpdateDate', 'daysSinceStatusChanged', 'policyNumber', 'insurer', 'claimStatus', 'lastModificationTime', 'lastModifierUserId']);

        let result = await this.internalHttp.put(this.endpoint.Update(), newUpdateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getColumnValue(payload: any) {
        isUserLoggedIn();
        let result = await httpApi.post(this.endpoint.Custom(`GetColumnValue`), payload);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new AgentClaimService();