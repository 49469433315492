const config = (window as any)._env_;
const appVersion = (window as any).version;

export const AppConsts = {
  userManagement: {
    defaultAdminUserName: 'admin',
  },
  localization: {
    defaultLocalizationSourceName: 'TopAbp',
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token',
  },
  appBaseUrl: config.REACT_APP_APP_BASE_URL,
  remoteServiceBaseUrl: config.REACT_APP_REMOTE_SERVICE_BASE_URL,
  grandNodeBaseUrl: config.REACT_APP_GRAND_NODE_BASE_URL,
  version: appVersion,
  authority: config.AUTHORITY,
  clientId: config.CLIENTID,
  scope: config.SCOPE,
  database: config.DATABASE,
  allowedContent: config.ALLOWED_CONTENT,
  logoPath: config.LOGO_PATH,
  logoAlt: config.LOGO_ALT,
  instaLink: config.INSTA_LINK,
  fbLink: config.FB_LINK,
  leftHeaderText: config.LEFT_HEADER_TEXT,
  rightHeaderText: config.RIGHT_HEADER_TEXT,
  defaultSerachBarDelay: config.DEFAULT_SEARCH_BAR_DELAY_IN_MS,
  defaultInputsDelay: config.DEFAULT_INPUTS_DELAY_IN_MS,
  disableChat: config.DISABLE_CHAT,
  isConfigForAG: config.IS_CONFIG_FOR_AG,
  isConfigForProduction: config.IS_CONFIG_FOR_PRODUCTION,
  showFilterByRoleOnUserList: config.SHOW_FILTER_BY_ROLE_ON_USER_LIST,
  showArchivedTabOnUserList: config.SHOW_ARCHIVED_TAB_ON_USER_LIST,
  isConfigForUAT: config.IS_CONFIG_FOR_UAT,
  faviconPath: config.FAVICON_PATH,
};

export default AppConsts;