import { CrudStoreBase } from './crudStoreBase';
import { ProductDto } from '../services/product/productDto';
import productService from '../services/product/productService';
import { ProductType } from '../services/product/productTypeEnums';

class ProductStore extends CrudStoreBase<ProductDto>{
  constructor() {
    super(productService, defaultProduct)
  }
}

export const defaultProduct = {
  id: '',
  Id: '',
  Name: '',
  SeName: '',
  ShortDescription: '',
  FullDescription: '',
  ProductTypeId: ProductType.SimpleProduct,
  ProductLayoutId: '',
  VendorId: '',
  Published: false,
  ProductAttributeMappings: [],
  CreatedOnUtc: '',
  UpdatedOnUtc: ''
}

export const defaultKeysToIdMapper = {
  attributeNameToIdList: [],
  attributeValueToOptionIdList: [],
  productKeyToProductIdList: [],
  productIdToTypeList: [],
  productIdToMapNameList: [],
}

export const defaultKeyWithId = {
  key: '',
  id: '',
}

export default ProductStore;