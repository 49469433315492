import { CrudStoreBase } from './crudStoreBase';
import { CountryDto } from '../services/country/countryDto';
import CountryService from '../services/country/countryService';

class CountryStore extends CrudStoreBase<CountryDto>{
	constructor() {
		super(CountryService, defaultCountry)
	}
}

export const defaultCountry = {
	id: '',
	name: '',
	allowsBilling: false,
	allowsShipping: false,
	twoLetterIsoCode: '',
	threeLetterIsoCode: '',
	numericIsoCode: 0,
	subjectToVat: false,
	published: true,
	displayOrder: 0,
}

export default CountryStore;