import * as React from 'react';
import {IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps, TagPickerBase, Shimmer} from '@fluentui/react';
import { mergeStyles } from '@fluentui/react';
import { L } from '../../lib/abpUtility';
import { LabelContainerComponent } from './labelContainerComponent';
import { LabelComponent } from './labelComponent';
import { getShimmerStyles } from '../../utils/stylesUtils';
import {additionalTheme} from "../../styles/theme";

const rootClass = mergeStyles({
    maxWidth: 500,
});

const inputProps: IInputProps = {
    onBlur: (ev: React.FocusEvent<HTMLInputElement>) => { return true },
    onFocus: (ev: React.FocusEvent<HTMLInputElement>) => { return true },
    'aria-label': 'Tag picker',
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: L('Suggested time'),
    noResultsFoundText: L('No time found'),
};

const generateTags = (step: number): ITag[] => {
    var array: string[] = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += step) {
            array.push(`${i}:${normalize(j)}`)
        }
    }

    return array.map(item => ({ key: item, name: item }))
}

const normalize = (input: number): string => {
    if (input > 9)
        return `${input}`;
    return `0${input}`;
}

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
        return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
};

const filterSelectedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
    let testTags = generateTags(15);
    return filterText ? testTags.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : [];
};

const onEmptyInputFocus = (selectedItems?: ITag[]): ITag[] => {
    return generateTags(15);
};

const getTextFromItem = (item: ITag) => item.name;

export interface ITimePickerProps {
    label: string;
    value?: string | undefined;
    disabled?: boolean;
    isDataLoaded?: boolean;
    validationData?: any;
    onChange?: (value: string | undefined) => void;
}

export const TimePicker: React.FunctionComponent<ITimePickerProps> = (props: ITimePickerProps) => {
    const picker = React.useRef<IBasePicker<ITag>>(null);

    const { onChange, disabled } = props;
    const onItemSelected = React.useCallback((item: ITag | undefined): ITag | null => {
        // eslint-disable-next-line 
        if (!item || picker.current && listContainsTagList(item, picker.current.items)) {
            return null;
        }
        if (onChange) {
            onChange(item?.name)
        }
        return item!;
    }, [onChange]);

    const mapValidationData = (validationData: any): any => {
        let mappedValidationData: any = {};
    
        let notDefaultBehaviourForKeys: string[] = ['multiline', 'errorMessage', 'readOnly', 'disabled'];
        
        for(const key in validationData) {
            if (Object.prototype.hasOwnProperty.call(validationData, key)) {
                if(key === 'multiline') {
                    mappedValidationData['multiline'] = 'true';
                    mappedValidationData['rows'] = validationData[key];
                }
                
                if(key === 'errorMessage') {
                    mappedValidationData[key] = L(validationData[key]);
                }

                if(key === 'readOnly' || key === 'disabled') {
                    mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
                }
                
                if(!notDefaultBehaviourForKeys.includes(key)) {
                    mappedValidationData[key] = validationData[key];
                }
            }
        }
    
        return mappedValidationData;
    }

    let defaultValues = generateTags((15)).filter(x => x.key === props.value);

    const TimePickerForwarded = React.forwardRef((props: any, ref: any) => {
        let mappedValidationData = mapValidationData(props.validationData);

        return <div style={{
                borderRadius: '2px',
                border: '1px',
                borderStyle:'solid', 
                borderColor:additionalTheme.grey,
                width: '250px'
            }}>
                <TagPickerBase
                    styles={{input: { height: '30px', width: '245px', border: '0px' }}}
                    ref={ref}
                    removeButtonAriaLabel="Remove"
                    componentRef={picker}
                    defaultSelectedItems={defaultValues}
                    onEmptyInputFocus={onEmptyInputFocus}
                    onResolveSuggestions={filterSelectedTags}
                    onItemSelected={onItemSelected}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    itemLimit={1}
                    disabled={disabled}
                    inputProps={{
                        ...inputProps,
                        defaultVisibleValue: props.props.value,
                    }}
                    // value={props.props.value}
                    {...mappedValidationData}
                />
            </div>
    });

    const ref = React.createRef();
    return <div className={rootClass} >
        <LabelContainerComponent>
            <LabelComponent label={props.label}/>
            <Shimmer isDataLoaded={props.isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
                <TimePickerForwarded ref={ref} props={props} />
            </Shimmer>
        </LabelContainerComponent>
    </div>
};
