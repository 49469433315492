import { CrudStoreBase } from './crudStoreBase';
import { ClaimDto } from '../services/claim/dto/claimDto';
import ClaimService from '../services/claim/claimService';

class ClaimStore extends CrudStoreBase<ClaimDto>{
  constructor() {
    super(ClaimService, defaultClaim)
  }
}

export const defaultClaim = {
  id: '',
  claimDate: '',
  policyId: 0,
  customerId: '',
  policyType: -1,
  comment: '',
  bankName: '',
  accountNumber: '',
  insurerSiteLink: '',
  additionalInformation: '',
}

export const defaultChildrenClaim = {
  ...defaultClaim,
  claimCauseList: [],
  anotherCause: '',
  claimCircumtances: '',
}

export const defaultHomeClaim = {
  ...defaultClaim,
  listOfDamages: [],
  causeOfDamage: '',
}

export const defaultLifeClaim = {
  ...defaultClaim,
}

export const defaultTravelClaim = {
  ...defaultClaim,
  claimCauseList: [],
  anotherCause: "",
  claimCircumtances: "",
}

export const defaultVehicleClaim = {
  ...defaultClaim,
  accidentPlace: "",
  policeNotified: false,
  policeAddress: "",
  vehicleInspectPlace: "",
  repairedAtASO: false,
}


export default ClaimStore;