import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { SportDisciplineDto } from './dto/sportDisciplineDto';

export class SportDisciplineService extends CrudServiceBase<SportDisciplineDto> {
    constructor() {
        super(Endpoint.SportDiscipline);
        this.internalHttp = httpApi;
    }

    public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = { ...this.defaultRequest, sorting: 'Name' }): Promise<PagedResultDto<SportDisciplineDto>> {
        if (!pagedFilterAndSortedRequest.sorting) {
            pagedFilterAndSortedRequest.sorting = 'Name';
        }
        let result = await this.internalHttp.get(Endpoint.SportDiscipline.GetAll(), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createSportInput: SportDisciplineDto) {
        let result: any = {};
        let sportInputClone: any = {...createSportInput};
      
        delete sportInputClone['id'];
      
        result = await this.internalHttp.post(Endpoint.SportDiscipline.Create(), sportInputClone as SportDisciplineDto);
            
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateSportInput: SportDisciplineDto) {
        let result: any = {};
        result = await this.internalHttp.put(Endpoint.SportDiscipline.Update(), updateSportInput as SportDisciplineDto);
            
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new SportDisciplineService();