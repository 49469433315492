import * as React from 'react';
import { ComboBox, IComboBox, IComboBoxOption, mergeStyleSets, Shimmer } from '@fluentui/react';
import Stores from '../../stores/storeIdentifier';
import { inject } from 'mobx-react';
import EventStore from '../../stores/eventStore';
import { myTheme } from '../../styles/theme';
import { LabelContainerComponent } from './labelContainerComponent';
import { LabelComponent } from './labelComponent';
import { getShimmerStyles } from '../../utils/stylesUtils';
import { L } from '../../lib/abpUtility';

var _ = require('lodash');

const classNames = mergeStyleSets({
    requiredMarker: {
        color: 'rgb(164, 38, 44)',
        marginLeft: '5px',
    }
});

export interface IComboBoxBaseProps {
    id?: string | undefined
    label?: string
    options: IComboBoxOption[]
    value?: string | number | undefined;
    onChange?: (value: string | number | undefined) => void;
    onInputValueChange?: (value: string | undefined) => void;
    required?: boolean
    eventStore?: EventStore
    initialValue?: string | number | undefined
    disabled?: boolean,
    placeholder?: string,
    customClassName?: any;
    isDataLoaded?: boolean;
    customDropdownWidth?: string;
    customLabelStyles?: any;
    allowFreeform?: boolean;
    autoComplete?: string;
    validationData?: any;
}

export interface IComboBoxBaseState {
    value: string | number | undefined
}

@inject(Stores.EventStore)
export class ComboBoxBase extends React.Component<IComboBoxBaseProps, IComboBoxBaseState> {
    private debouncedOnChange = _.debounce(this.props.onChange, 500);
    private debouncedOnInputValueChange = _.debounce(this.props.onInputValueChange, 500);

    constructor(props: IComboBoxBaseProps) {
        super(props)

        this.state = {
            value: ""
        }
    }

    private mapValidationData(validationData: any): any {
        let mappedValidationData: any = {};
    
        let notDefaultBehaviourForKeys: string[] = ['multiline', 'errorMessage', 'readOnly', 'disabled'];
        
        for(const key in validationData) {
            if (Object.prototype.hasOwnProperty.call(validationData, key)) {
                if(key === 'multiline') {
                    mappedValidationData['multiline'] = 'true';
                    mappedValidationData['rows'] = validationData[key];
                }
                
                if(key === 'errorMessage') {
                    mappedValidationData[key] = L(validationData[key]);
                }
                if(key === 'readOnly' || key === 'disabled') {
                    mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
                }
        
                if(!notDefaultBehaviourForKeys.includes(key)) {
                    mappedValidationData[key] = validationData[key];
                }
            }
        }
    
        return mappedValidationData;
    }

    static getDerivedStateFromProps(props: any, state: any) {
        let val = props.initialValue || props.value;
        if (state.value !== val && props.initialValue !== props.value && props.onChange) {
            try {
                props.onChange(val);
            } catch(error) {
                console.error(error);
            }
        }
        return { value: val };
    }

    componentDidMount() {
        try {
            if (this.props.value && this.props.value !== 0) {
                this.setState({ value: this.props.value })
                if (this.props.onChange)
                    this.props.onChange(this.props.value)
            }
        } catch(error) {
            console.error(error);
        }
    }

    pendingValueChange = (option?: IComboBoxOption, index?: number, value?: string) => {
        if(this.props.onInputValueChange && typeof value === 'string')
            this.debouncedOnInputValueChange(value);

        if(this.props.onChange && typeof value === 'string')
            this.debouncedOnChange(value);
    }

    change = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if(option) {
            this.setState({ value: option?.key });

            if (this.props.onChange) {
                this.props.onChange(option?.key);
            }
        } else if(value) {
            this.setState({ value: value });
    
            if (this.props.onChange && typeof value === 'string') {
                this.props.onChange(value);
            }
        } else {
            this.setState({ value: '' });

            if (this.props.onChange) {
                this.props.onChange('');
            }
        }
    }

    render() {
        const { label, options, value, required, eventStore, id, initialValue, disabled, placeholder, customClassName,
            isDataLoaded, customDropdownWidth, customLabelStyles, validationData } = this.props;
        const selectedKey = initialValue || value;

        let mappedValidationData = this.mapValidationData(validationData);

        const combobox = <ComboBox
            className={customClassName && customClassName}
            style={{width: customDropdownWidth ? customDropdownWidth : "250px"}}
            theme={myTheme} 
            errorMessage={eventStore!.getErrorMessageFor(id)} 
            disabled={disabled} 
            required={required} 
            selectedKey={selectedKey} 
            options={options} 
            onChange={this.change}
            onPendingValueChanged={this.pendingValueChange}
            allowFreeform={true}
            autoComplete={'off'} 
            placeholder= {placeholder}
            {...mappedValidationData}
        />;

        return label && label.length > 0 ?
            <LabelContainerComponent>
                <LabelComponent customStyles={customLabelStyles ? customLabelStyles : {}} label={label || ''}/>
                <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
                    { combobox }
                </Shimmer>
                {(required && required === true) && <span className={classNames.requiredMarker}>*</span>}
            </LabelContainerComponent>
            : 
            <Shimmer isDataLoaded={isDataLoaded} styles={getShimmerStyles} ariaLabel="Loading content">
                { combobox }
                {(required && required === true) && <span className={classNames.requiredMarker}>*</span>}
            </Shimmer>;
    }
}