import {CrudServiceBase} from "../base/crudServiceBase";
import Endpoint from "../endpoint";
import {ClientContestDto} from "./dto/clientContestDto";
import {httpApi} from "../httpService";
import {isUserLoggedIn} from "../../utils/authUtils";
import { getPartialModel } from "../../utils/modelUtils";


export class ClientContestService extends CrudServiceBase<ClientContestDto> {
    constructor() {
        super(Endpoint.ClientContest);
        this.internalHttp = httpApi
    }

    public async update(updateUserInput: ClientContestDto) {
        isUserLoggedIn();
        
        let newUpdateUserInput: ClientContestDto = getPartialModel(updateUserInput, [], ['client', 'contest']);
        
        if(typeof newUpdateUserInput.oloPoints !== 'number') {
            newUpdateUserInput.oloPoints = 0;
        }
        if(typeof newUpdateUserInput.policyId !== 'number' || newUpdateUserInput.policyId === 0) {
            newUpdateUserInput.policyId = null;
        }
        if(typeof newUpdateUserInput.receivedPrizeId !== 'number' || newUpdateUserInput.receivedPrizeId === 0) {
            newUpdateUserInput.receivedPrizeId = null;
        }
        if(typeof newUpdateUserInput.contestId !== 'number' || newUpdateUserInput.contestId === 0) {
            newUpdateUserInput.contestId = null;
        }
        if(newUpdateUserInput.policy !== null && Object.keys(newUpdateUserInput.policy).length === 0) {
            newUpdateUserInput.policy = null;
        }
        if(newUpdateUserInput.receivedPrize !== null && Object.keys(newUpdateUserInput.receivedPrize).length === 0) {
            newUpdateUserInput.receivedPrize = null;
        }

        let result = await this.internalHttp.put(this.endpoint.Update(), newUpdateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getParticipants(contestId: number) {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetParticipants?contestId=${contestId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByClientId(customerId: number) {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByClientId?clientId=${customerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getDetails(id: number) {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`Get?Id=${id}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new ClientContestService()