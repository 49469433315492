import { mergeStyleSets} from '@fluentui/merge-styles';

export const gnInputIconStyles = mergeStyleSets({
  icon: {
    fontSize: '22px', 
    marginLeft: `15px`, 
    alignSelf: 'center', 
    cursor: 'pointer',
    userSelect: 'none',
    transitions: 'all 100ms',
    selectors: {
      '&:hover': {
        transform: 'scale(1.1)',
      },
      '&:active': {
        transform: 'scale(0.9)',
      }
    }
  },
});