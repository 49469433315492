import { DefaultButton, IDropdownOption, IChoiceGroupOption, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, mergeStyleSets } from '@fluentui/react'; 
import * as React from 'react';
import { L } from '../../lib/abpUtility';
import { myTheme } from '../../styles/theme';
import { createOrUpdateClassNames } from '../BaseComponents/createOrUpdate';
import { CheckBoxBase, DropdownBase } from '../BaseComponents';
import { DatePickerBase } from '../BaseComponents/datePickerBase';
import { Container } from '../../stores/storeInitializer';
import { MultiDropdownBase } from '../BaseComponents/multiDropdownBase';
import { TimePicker } from '../BaseComponents/timePicker';
import { LabeledTextField } from '../../components/LabeledTextField';
import { Controls } from './controls';
import { CheckBoxOptions } from '../BaseComponents/CheckBoxOptions';
import { ChoiceGroupBase } from '../BaseComponents/ChoiceGroupBase';
import { IContentViewProps } from './IContentViewProps';
import { IContentViewState } from './IContentViewState';
import { TableInputsBase } from './TableInputsBase';
import { ComboBoxBase } from './comboBoxBase';

const classNames = mergeStyleSets({
    hide: {
        display: 'none',
    }
});

export class ContentViewModelProperty {
    id: string = '';
    isRequired: boolean = false;
    disabled: boolean = false;
    type: string = Controls.Text;
    label: string = "Label";
    options: any = {
        dropdown: [] as IDropdownOption[],
        choicegroup: [] as IChoiceGroupOption[]
    };
    group: string = "Default";
    additionalOptions: any = {
        isDataLoaded: false as boolean,
        rows: 1 as number,
        textType: "text" as string,
        validationData: {} as any,
        hide: false as boolean,
        customPayload: undefined as any,
        parseToBoolean: false as boolean,
        additionalMethod: (data: any) => { return false }
    };

    constructor(id: string, label: string, type: string = Controls.Text, isRequired: boolean = false, options: any = { dropdown: [] as IDropdownOption[], choicegroup: [] as IChoiceGroupOption[] }, disabled: boolean = false, additionalOptions?: any) {
        this.id = id;
        this.label = label;
        this.type = type;
        this.isRequired = isRequired;
        this.options = options;
        this.disabled = disabled;
        if(additionalOptions && additionalOptions.isDataLoaded)
            this.additionalOptions.isDataLoaded = additionalOptions.isDataLoaded!;
        if(additionalOptions && additionalOptions.rows)
            this.additionalOptions.rows = additionalOptions.rows!;
        if(additionalOptions && additionalOptions.textType)
            this.additionalOptions.textType = additionalOptions.textType!;
        if(additionalOptions && additionalOptions.validationData)
            this.additionalOptions.validationData = additionalOptions.validationData!;
        if(additionalOptions && additionalOptions.hide)
            this.additionalOptions.hide = additionalOptions.hide!;
        if(additionalOptions && additionalOptions.tooltipText)
            this.additionalOptions.tooltipText = additionalOptions.tooltipText!;
        if(additionalOptions && additionalOptions.customPayload)
            this.additionalOptions.customPayload = additionalOptions.customPayload!;
        if(additionalOptions && additionalOptions.additionalMethod)
            this.additionalOptions.additionalMethod = additionalOptions.additionalMethod!;
        if(additionalOptions && additionalOptions.parseToBoolean)
            this.additionalOptions.parseToBoolean = additionalOptions.parseToBoolean!;
    }
}

export class ContentViewModelDefifnition {
    properties: ContentViewModelProperty[] = [];
    add(prop: ContentViewModelProperty, group: string = 'Default') {
        prop.group = group;
        this.properties.push(prop);
    }
}

export default class ContentViewBase<P extends IContentViewProps = IContentViewProps, S extends IContentViewState = IContentViewState> extends React.Component<P, S>  {
    protected asyncActionInProgress: boolean = false;
    protected loadSpinnerCustomLabel: string | null = null;
    
    constructor(props: P) {
        super(props);

        this.state = {
            // @ts-ignore
            model: { error: {}, value: {} },
            ...this.state
        }
    }

    static getDerivedStateFromProps(props: IContentViewProps, state: IContentViewState) {
        return { ...state, model: ContentViewBase.getModelFromProps(props, state) }
    }

    static getModelFromProps(props: IContentViewProps, state: IContentViewState): any {
        state.model.value = props.payload && props.payload.model ? props.payload.model : (props.payload ? props.payload : { error: {}, value: {} });
        return state.model;
    }

    protected toggleAsyncActionInProgressFlag(newState: boolean, forceUpdate: boolean) {
        if(typeof newState === 'boolean') {
            this.asyncActionInProgress = newState;

            if(forceUpdate === true) {
                this.forceUpdate();
            }
        }
    }

    render() {
        return <>
            {this.renderContent()}
            {this.renderMessage()}
            {this.props.renderFooter && this.props.renderFooter.show ? this.renderFooter(this.props.renderFooter?.options) : ''}
        </>;
    }

    async onConfirm(): Promise<Boolean> {
        let definition = this.getDefinition();

        let output = true;
        let newModel: ContentViewModel;
        for (const element of definition.properties) {
            let value = this.state.model.value[element.id];

            if ((!value) && element.isRequired) {
                newModel = this.state.model;
                newModel.error[element.id] = L('ThisFieldIsRequired');
                this.setState({ model: newModel });
                output = false;
            } else {
                newModel = this.state.model;
                newModel.error[element.id] = '';
                this.setState({ model: newModel });
            }
        }

        return output;
    }

    _onBack = () => {
        this.setState({ model: { error: {}, value: {} } });
        window.history.back();
    }

    _onConfirm = async () => {
        this.toggleAsyncActionInProgressFlag(true, true);

        if (await this.onConfirm()) {
            this.setState({ model: { error: {}, value: {} } })
        }
    }

    _onCloseMessage = () => {
        if (this.state.message) {
            this.state.message!.text = "";
            this.forceUpdate();
        }
    }

    renderContent() {
        return <></>
    }

    renderMessage() {
        if (Container.EventBus.HttpError && (!this.state.message || this.state.message.text !== Container.EventBus.HttpError)) {
            this.setState({ message: {text: Container.EventBus.HttpError, type: MessageBarType.error} }, () => {
                this.renderMessage();
            });
            // return <div className={createOrUpdateClassNames.panelActions} >
            //     <MessageBar
            //         style={{whiteSpace: 'pre-line'}}
            //         messageBarType={MessageBarType.error}
            //         onDismiss={this._onCloseMessage}
            //         dismissButtonAriaLabel={L("Close")}
            //         isMultiline={true}
            //     >
            //         {Container.EventBus.HttpError}
            //     </MessageBar>
            // </div>
        } else if (this.state.message && this.state.message!.text.length > 0) {
            return <div className={createOrUpdateClassNames.panelActions} >
                <MessageBar
                    messageBarType={this.state.message.type}
                    onDismiss={this._onCloseMessage}
                    dismissButtonAriaLabel={L("Close")}
                    style={{whiteSpace: 'pre-line'}}
                    styles={{ root: { width: 'fit-content' } }}
                >
                    {this.state.message.text}
                </MessageBar>
            </div>
        }
        return <></>
    }

    renderFooter = (options: any) => {
        let footerContent;
        if(options && options.backOnly) {
            footerContent = <>{this.renderBack()}</>;
        } else {
            footerContent = <>
                {this.renderBack()}
                {this.renderConfirm()}
            </>;
        }

        return (
            <div className={createOrUpdateClassNames.panelActions}>
                {footerContent}
                {this.asyncActionInProgress && 
                    <Spinner label={typeof this.loadSpinnerCustomLabel === 'string' ? this.loadSpinnerCustomLabel : L('Please wait...')}
                            className={createOrUpdateClassNames.loadSpinner} size={SpinnerSize.large} ariaLive="assertive" labelPosition="right" />}
            </div>
        );
    };

    renderConfirm = () => {
        return <PrimaryButton theme={myTheme} onClick={this._onConfirm} text={L('Save')} disabled={this.asyncActionInProgress} />
    };

    renderBack = () => {
        return <DefaultButton text={L('Back')} iconProps={{ iconName: 'Back' }} onClick={this._onBack} allowDisabledFocus />;
    };

    renderModel(group: string = "Default") {
        const { error, value } = this.state.model;
        let definition = this.getDefinition();
        return definition.properties.filter(x => x.group === group).map(element => {
            return this.renderElement(element, error, value);
        });
    }

    renderElement(element: ContentViewModelProperty, error: any, value: any, tableInputData?: any) {
        switch (element.type) {
            case Controls.Text:
                return <LabeledTextField key={element.id} required={element.isRequired} label={L(element.label)} errorMessage={error[element.id]}
                    rows={element.additionalOptions.rows} multiline={element.additionalOptions.rows > 1} value={value[element.id]} type={element.additionalOptions.textType}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    onChange={(e) => {
                        let target = e.target as any;
                        let newModel = this.state.model;
                        let newValue: string | number | undefined;
                        if(target && (typeof target.value === 'string' || typeof target.value === 'number')) {
                            newValue = target.value;
                        } else if(typeof e === 'string') {
                            newValue = e;
                        }

                        if(element.additionalOptions && element.additionalOptions.validationData && !!element.additionalOptions.validationData.maxlength &&
                            typeof newValue ==='string' && newValue.length > element.additionalOptions.validationData.maxlength) 
                        {
                            return;
                        }

                        let splittedId: string[] = element.id.split('.');

                        if(splittedId.length > 1 && (typeof newValue === 'string' || typeof newValue === 'number')) {
                            let deeperModelValue = [];
                            deeperModelValue[0] = newModel.value;
                            for(let i = 1; i <= splittedId.length; i++) {
                                deeperModelValue[i] = deeperModelValue[i-1][splittedId[i-1]];
                                if(i === splittedId.length) {
                                    deeperModelValue[i] = newValue;
                                }
                            }

                            let tempNewModel: any;
                            for(let i = deeperModelValue.length - 1; i >= 0; i--) {
                                let tempValue: any = deeperModelValue[i - 1];
                                if(tempValue) {
                                    tempValue[splittedId[i - 1]] = deeperModelValue[i];
                                    
                                    tempNewModel = tempValue;
                                }
                            }

                            newModel.value = tempNewModel;
                        } else if(typeof newValue === 'string' || typeof newValue === 'number') {
                            newModel.value[element.id] = newValue;
                        }

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }} />;
            case Controls.Date:
                return <DatePickerBase key={element.id} required={element.isRequired} label={L(element.label)} value={value[element.id]}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    errorMessage={error[element.id]}
                    onChange={(e) => {
                        let newModel = this.state.model;
                        newModel.value[element.id] = e;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }} />;
            case Controls.Time:
                return <TimePicker key={element.id} label={L(element.label)} value={value[element.id]} disabled={element.disabled} 
                    isDataLoaded={element.additionalOptions.isDataLoaded} onChange={(e) => {
                        let newModel = this.state.model;
                        newModel.value[element.id] = e;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }} />;
            case Controls.Picker:
                return <DropdownBase key={element.id} required={element.isRequired} label={L(element.label)} options={element.options.dropdown} value={value[element.id]}
                    disabled={element.disabled} customClassName={element.additionalOptions.hide ? classNames.hide : ''} validationData={element.additionalOptions.validationData}
                    isDataLoaded={element.additionalOptions.hide ? true : element.additionalOptions.isDataLoaded} onChange={(e) => {
                        let newModel = this.state.model;
                        newModel.value[element.id] = e;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }} />;
            case Controls.MultiPicker:
                return <MultiDropdownBase customDropdownWidth='300px' key={element.id} label={L(element.label)} options={element.options.dropdown} value={value[element.id]}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    onChange={(e) => {
                        let newModel = this.state.model;
                        newModel.value[element.id] = e;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }} />;
            case Controls.CheckBox:
                return <CheckBoxBase key={element.id} label={L(element.label)} value={value[element.id]}
                    disabled={element.disabled}
                    onChange={(e) => {
                        let newModel = this.state.model;
                        newModel.value[element.id] = e;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }} />;
            case Controls.CheckBoxOptions:
                return <CheckBoxOptions key={element.id} label={L(element.label)} options={element.options.dropdown}
                    disabled={element.disabled}
                    onChange={(e) => {
                        let newModel = this.state.model;
                        newModel.value[element.id] = e;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }
                        
                        this.setState({ model: newModel });
                    }}
                />;
            case Controls.ChoiceGroup:
                return <ChoiceGroupBase key={element.id} label={L(element.label)} value={value[element.id]}
                    disabled={element.disabled} options={element.options.choicegroup}
                    onChange={(e) => {
                        let newModel = this.state.model;
                        let newValue: boolean | string = e ? e.key : "";

                        if(element.additionalOptions.parseToBoolean === true && typeof newValue === 'string') {
                            newValue = newValue === 'true' ? true : false;
                        }

                        newModel.value[element.id] = newValue;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }}
                />;
            case Controls.TableInputs:
                if(tableInputData) {
                    return <TableInputsBase key={element.id} label={L(element.label)} options={element.options.tableInputs} inputsForTable={tableInputData.templateInputsForTable[element.id]}
                        rowsCount={element.options.tableInputs.tableRowsCountFromInputId && value[element.options.tableInputs.tableRowsCountFromInputId] 
                        ? parseInt(value[element.options.tableInputs.tableRowsCountFromInputId]) : -1} isDataLoaded={element.additionalOptions.isDataLoaded}
                        value={value[element.id]}
                        onChange={(jsonValue) => {
                            let newModel = this.state.model;
                            newModel.value[element.id] = jsonValue;

                            if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                                element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                            }

                            this.setState({ model: newModel });
                        }} />;
                } else {
                    return <></>;
                }
            case Controls.ComboBox:
                return <ComboBoxBase customDropdownWidth='300px' key={element.id} required={element.isRequired} label={L(element.label)} options={element.options} value={value[element.id]}
                    disabled={element.disabled} customClassName={element.additionalOptions.hide ? classNames.hide : ''} autoComplete={'off'} allowFreeform={true}
                    isDataLoaded={element.additionalOptions.hide ? true : element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    onChange={(e: any) => {
                        let newModel = this.state.model;
                        newModel.value[element.id] = e;

                        if(element.additionalOptions && element.additionalOptions.additionalMethod) {
                            element.additionalOptions.additionalMethod(newModel.value[element.id], element.additionalOptions.customPayload);
                        }

                        this.setState({ model: newModel });
                    }}
                    onInputValueChange={(value: string | undefined) => {
                        element.additionalOptions.additionalMethod(value);
                    }} />;
            default:
                return <></>;
        }
    }

    getDefinition(): ContentViewModelDefifnition {
        return new ContentViewModelDefifnition();
    }
}
export class ContentViewModel {
    error: any;
    value: any;
}