import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { OcTerminationDto } from './dto/ocTerminationDto';

export class OcTerminationService extends CrudServiceBase<OcTerminationDto> {
    constructor() {
        super(Endpoint.TerminationOCContract);
        this.internalHttp = httpApi;
    }

    public async getTerminationOCContractList(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest) {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom('GetTerminationOCContractList'), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async generateTerminationOcContract(terminationFormData: any) {
        let result = await this.internalHttp.post(this.endpoint.Custom(`GenerateTerminationOCContract`), terminationFormData);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new OcTerminationService();