import { CrudStoreBase } from './crudStoreBase';
import { ClientDto } from '../services/client/dto/clientDto';
import { ClientTypeEnum } from '../services/client/clientTypeEnums';
import { defaultUser } from './userCrudStore';
import { ClientMaritalStatusEnum } from '../services/client/clientMaritalStatusEnums';
import clientService from '../services/client/clientService';

class ClientStore extends CrudStoreBase<ClientDto>{
	constructor() {
		super(clientService, defaultClient)
	}

    public async getColumnValue(payload: any) {
        return clientService.getColumnValue(payload);
    }
}

export const defaultClient: ClientDto = {
	id: '',
    agreementsPayload: '',
    agreementsStatus: null,
    emailAdditional: '',
    customerId: '',
    dateOfBirth: '',
    company: '',
    streetAddress: '',
    streetAddress2: '',
    zipPostalCode: '',
    city: '',
    county: '',
    country: '',
    countryId: '60f0391b217c7a758d6a3a29',
    stateProvinceId: '',
    phone: '',
    pesel: '',
    nip: '',
    regon: '',
    pkDs: [],
    mainPKD: '',
	clientType: ClientTypeEnum.Individual,
    note: '',
    nationality: 'PL',
    userId: 0,
    user: defaultUser,
    superAgentId: null,
    maritalStatus: ClientMaritalStatusEnum.Single,
}

export default ClientStore;