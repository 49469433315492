import RoleStore from './roleStore';
import TenantStore from './tenantStore';
import UserStore from './userStore';
import SessionStore from './sessionStore';
import AuthenticationStore from './authenticationStore';
import AccountStore from './accountStore';
import SearchStore from './searchStore';
import EventStore from './eventStore';
import ClaimStore from './claimStore';
import ProductStore from './productStore';
import ProductTypeStore from './productTypeStore';
import InsuranceCompanyStore from './insuranceCompanyStore';
import OrderStore from './orderStore';
import ProductAttributeStore from './productAttributeStore';
import UserCrudStore from './userCrudStore';
import CustomerGroupStore from './customerGroupStore';
import InsurancePolicyStore from './insurancePolicyStore';
import IndividualOfferStore from './individualOfferStore';
import LanguageStore from './languageStore';
import CountryStore from './countryStore';
import LoyaltyPointsHistoryStore from './loyaltyPointsHistoryStore';
import PatternStore from './patternStore';
import RatingStore from './ratingStore';
import CommunityStore from './communityStore';
import ClientStore from './clientStore';
import ClientLeasingStore from './clientLeasingStore';
import TestSetStore from './testSetStore';
import CalculationStore from './calculationStore';
import VehicleStore from './vehicleStore';
import ApkAttachedFilesStore from './apkAttachedFilesStore';
import CountryItemsStore from './countryItemsStore';
import PaymentStore from './paymentStore';
import InsurerStore from './insurerStore';
import SportDisciplineStore from './sportDisciplineStore';
import SportInsuranceCoverageStore from './sportInsuranceCoverageStore';
import TravelCountryStore from './travelCountryStore';
import TravelCountryCoverageStore from './travelCountryCoverageStore';
import InsurerAvailabilityStore from './insurerAvailabilityStore';
import ContestStore from './contestStore';
import PrizeStore from './prizeStore';
import ClientContestStore from "./clientContestStore";
import AgentClaimStore from './agentClaimStore';
import AgentClaimHistoryStore from './agentClaimHistoryStore';
import AgentClaimAmeStatusStore from './agentClaimAmeStatusStore';
import OcTerminationStore from './ocTerminationStore';

export class Container {
  static EventBus: EventStore;
  static stores: any

  static getStore<TStore>(name: string): TStore {
    name = name[0].toLowerCase() + name.substr(1, name.length - 1)
    return (Container.stores[name]) as TStore;
  }
}

export default function initializeStores() {
  Container.EventBus = new EventStore();
  Container.stores = {
    authenticationStore: new AuthenticationStore(),
    roleStore: new RoleStore(),
    tenantStore: new TenantStore(),
    userStore: new UserStore(),
    sessionStore: new SessionStore(),
    accountStore: new AccountStore(),
    searchStore: new SearchStore(),
    eventStore: Container.EventBus,
    userCrudStore: new UserCrudStore(),
    apkAttachedFilesStore: new ApkAttachedFilesStore(),
    productAttributeStore: new ProductAttributeStore(),
    customerGroupStore: new CustomerGroupStore(),
    languageStore: new LanguageStore(),
    countryStore: new CountryStore(),
    countryItemsStore: new CountryItemsStore(),

    // lib candidate
    // chatStore: new ChatStore(),
    // webHookStore: new WebHookStore(),

    // domain specific
    ratingStore: new RatingStore(),
    productStore: new ProductStore(),
    claimStore: new ClaimStore(),
    agentClaimStore: new AgentClaimStore(),
    agentClaimHistoryStore: new AgentClaimHistoryStore(),
    agentClaimAmeStatusStore: new AgentClaimAmeStatusStore(),
    productTypeStore: new ProductTypeStore(),
    insuranceCompanyStore: new InsuranceCompanyStore(),
    insurerStore: new InsurerStore(),
    insurancePolicyStore: new InsurancePolicyStore(),
    individualOfferStore: new IndividualOfferStore(),
    orderStore: new OrderStore(),
    calculationStore: new CalculationStore(),
    loyaltyPointsHistoryStore: new LoyaltyPointsHistoryStore(),
    patternStore: new PatternStore(),
    communityStore: new CommunityStore(),
    clientStore: new ClientStore(),
    clientLeasingStore: new ClientLeasingStore(),
    clientContestStore: new ClientContestStore(),
    contestStore: new ContestStore(),
    prizeStore: new PrizeStore(),
    testSetStore: new TestSetStore(),
    vehicleStore: new VehicleStore(),
    paymentStore: new PaymentStore(),
    sportDisciplineStore: new SportDisciplineStore(),
    sportInsuranceCoverageStore: new SportInsuranceCoverageStore(),
    travelCountryStore: new TravelCountryStore(),
    travelCountryCoverageStore: new TravelCountryCoverageStore(),
    insurerAvailabilityStore: new InsurerAvailabilityStore(),
    ocTerminationStore: new OcTerminationStore(),
  };

  return Container.stores;
}
