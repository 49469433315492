import { TravelCountryDto } from '../services/travelCountry/dto/travelCountryDto';
import travelCountryService from '../services/travelCountry/travelCountryService';
import { CrudStoreBase } from './crudStoreBase';

class TravelCountryStore extends CrudStoreBase<TravelCountryDto>{
  constructor() {
    super(travelCountryService, defaultTravelCountry)
  }
}

export const defaultTravelCountry = {
    id: '',
    name: '',
}

export default TravelCountryStore;