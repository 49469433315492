import { mergeStyleSets, MessageBar, MessageBarType } from "@fluentui/react";
import { useEffect, useState } from "react";

export interface AsyncActionMessage {
    text: string;
    show: boolean;
    type: MessageBarType;
};

const classNames = mergeStyleSets({
    messageBarWrapper: {
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 180,
        left: 20,
        zIndex: 999,
        width: 'auto',
        height: 'auto',
    },
    messageBarWithOutline: {
        border: '2px solid rgba(0, 0, 0, 0.7)',
        borderRadius: '10px',
    },
    displayOnTheRight: {
        left: 'auto !important',
        right: 20,
    },
    messageBarWrapperDisplay: {
        display: 'flex',
    },
    messageBar: {
        opacity: 0,
        fontSize: 20,
        maxWidth: 250,
        transition: 'opacity 200ms',
    },
    messageBarFadeIn: {
        opacity: 1,
    },
});

export interface IFloatingMessageBarDismissableProps {
    text: string;
    type: MessageBarType;
    show: boolean;
    multiline?: boolean;
    displayOnTheRight?: boolean;
    addOutline?: boolean;
    onShowChange: (value: boolean) => void;
}

const FloatingMessageBarDismissable = (props: IFloatingMessageBarDismissableProps) => {
    const [animate, setAnimate] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [timeoutFunc, setTimeoutFunc] = useState<any>(null);
    const [animateTimeoutFunc, setAnimateTimeoutFunc] = useState<any>(null);

    useEffect(() => {
        if(timeoutFunc !== null) {
            clearTimeout(timeoutFunc);
        }

        if(props.show === true) {
            setShow(true);
        } else if(props.show === false && show === true) {
            setTimeoutFunc(setTimeout(() => {
                setShow(false);
            }, 200));
        }
        // eslint-disable-next-line
    }, [props.show]); 

    useEffect(() => {
        if(animateTimeoutFunc !== null) {
            clearTimeout(animateTimeoutFunc);
        }

        if(show === true) {
            setAnimateTimeoutFunc(setTimeout(() => {
                setAnimate(true);
            }, 20));
        } else {
            setAnimate(false);
        }
        // eslint-disable-next-line
    }, [show]); 

    return <div className={`${classNames.messageBarWrapper}
            ${(props.displayOnTheRight && props.displayOnTheRight === true) && classNames.displayOnTheRight}
            ${show && classNames.messageBarWrapperDisplay}`}
        >
            <MessageBar
                messageBarType={props.type}
                isMultiline={props.multiline ? props.multiline : true}
                onDismiss={() => setShow(false)}
                dismissButtonAriaLabel="Zamknij"
                className={`${classNames.messageBar} ${(animate && props.show === true) && classNames.messageBarFadeIn} ${props.addOutline && classNames.messageBarWithOutline}`}
            >
                {props.text}
            </MessageBar>
        </div>;
};

export default FloatingMessageBarDismissable;