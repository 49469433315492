import { IColumn } from "@fluentui/react";
import { ITableColumn } from "../scenes/BaseComponents/ITableColumn";

export function utilMapToColumn(tableColumns: ITableColumn[], onColumnClick?: any) {
    let columns: IColumn[] = [];

    for (let i = 0; i < tableColumns.length; i++) {
        const element = tableColumns[i];
        const key = `column${i}`;

        if (i === 0) {
            columns.push({
                key: key,
                name: element.name,
                fieldName: element.fieldName,
                minWidth: element.minWidth || 150,
                maxWidth: element.maxWidth || 200,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: element.disableHeader ? undefined : (onColumnClick ? onColumnClick : undefined),
                onRender: element.onRender,
                data: element.data || 'string',
                isPadded: true,
            });
        } else {
            columns.push({
                key: key,
                name: element.name,
                onRender: element.onRender,
                fieldName: element.fieldName,
                minWidth: element.minWidth || 150,
                maxWidth: element.maxWidth || 200,
                isResizable: true,
                onColumnClick: element.disableHeader ? undefined : (onColumnClick ? onColumnClick : undefined),
                data: element.data || 'string',
                isPadded: true,
            });
        }
    }
    return columns;
}