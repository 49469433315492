import { createTheme } from '@fluentui/react';

export const myTheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#f3f9fd',
    themeLighter: '#d0e7f8',
    themeLight: '#a9d3f2',
    themeTertiary: '#5ca9e5',
    themeSecondary: '#1a86d9',
    themeDarkAlt: '#006cbe',
    themeDark: '#005ba1',
    themeDarker: '#004377',
    neutralLighterAlt: '#f3f3f3',
    neutralLighter: '#efefef',
    neutralLight: '#e5e5e5',
    neutralQuaternaryAlt: '#d6d6d6',
    neutralQuaternary: '#cccccc',
    neutralTertiaryAlt: '#c4c4c4',
    neutralTertiary: '#b4b2b0',
    neutralSecondary: '#9b9997',
    neutralSecondaryAlt: '#9b9997',
    neutralPrimaryAlt: '#83817e',
    neutralPrimary: '#201f1e',
    neutralDark: '#52504e',
    black: '#3a3836',
    white: '#f9f8f8',
    red: '#DC1A23',
    orange: '#FEA34F',
    green: '#2FAE62',
  },
});

export const additionalTheme = {
  grey: '#676767',
  lighterGrey: '#707070',
  white: '#fff',
  darkerWhite: '#F6F6F6',
  lightBlue:'#33A1E5',
  lighterRed: '#FB4B4B',
  darkerRed: '#A9253B',
  darkerBlue: '#233762',
  yellow: '#ffff00',
  brown: '#804004'
}
