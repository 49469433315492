import { CommunityDto } from './dto/communityDto';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';

export class CommunityService extends CrudServiceBase<CommunityDto> {
  constructor() {
    super(Endpoint.Community);
    this.internalHttp = httpApi;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<CommunityDto>> {
    let result = await this.internalHttp.get(Endpoint.Community.GetAll(), { params: pagedFilterAndSortedRequest });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async get(entityDto: CommunityDto): Promise<CommunityDto> {
    let result = await this.internalHttp.get(Endpoint.Community.Get(), { params: entityDto });
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }

  public async create(createUserInput: CommunityDto) {
    let result: any = {};
    let userInputClone: any = {...createUserInput};
  
    delete userInputClone['id'];
  
    result = await this.internalHttp.post(Endpoint.Community.Create(), userInputClone as CommunityDto);
        
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }
}



export default new CommunityService();