import { TestSetDto } from '../services/testSet/dto/testSetDto';
import TestSetService from '../services/testSet/testSetService';
import { CrudStoreBase } from './crudStoreBase';

class TestSetStore extends CrudStoreBase<TestSetDto>{
  constructor() {
    super(TestSetService, defaultTestSet)
  }
}

export const defaultTestSet = {
  id: '',
  name: "",
  payload: "",
  result: "",
  comment: "",
  isSuccess: false,
  errorDetails: "",
  lastTestDate: "",
  insurerName: "",
  segment: "",
  additionalData: "",
  resultCount: "",
  gnProductId: "",
}

export default TestSetStore;