import { CrudStoreBase } from './crudStoreBase';
import { ClientDto } from '../services/client/dto/clientDto';
import ClientService from '../services/client/clientService';
import { ClientTypeEnum } from '../services/client/clientTypeEnums';
import { defaultUser } from './userCrudStore';
import { ClientMaritalStatusEnum } from '../services/client/clientMaritalStatusEnums';

class ClientLeasingStore extends CrudStoreBase<ClientDto>{
	constructor() {
		super(ClientService, defaultClient)
	}

    public async getAll() {
		await ClientService.getAllLeasing().then((result: any) => {
			this.dataSet = result;
		}).catch((error: any) => {
			console.error(error);
		});
	}
}

const defaultClient: ClientDto = {
	id: '',
    agreementsPayload: '',
    agreementsStatus: null,
    emailAdditional: '',
    customerId: '',
    dateOfBirth: '',
    company: '',
    streetAddress: '',
    streetAddress2: '',
    zipPostalCode: '',
    city: '',
    county: '',
    country: '',
    countryId: '60f0391b217c7a758d6a3a29',
    stateProvinceId: '',
    phone: '',
    pesel: '',
    nip: '',
    regon: '',
    pkDs: [{}],
	clientType: ClientTypeEnum.Individual,
    note: '',
    nationality: 'PL',
    userId: 0,
    user: defaultUser,
    superAgentId: null,
    maritalStatus: ClientMaritalStatusEnum.Single,
}

export default ClientLeasingStore;