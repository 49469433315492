import { CrudStoreBase } from './crudStoreBase';
import { ProductAttributeDto } from '../services/productAttribute/productAttributeDto';
import productAttributeService from '../services/productAttribute/productAttributeService';

class ProductAttributeStore extends CrudStoreBase<ProductAttributeDto>{
  constructor() {
    super(productAttributeService, defaultProductAttribute)
  }
}

export const defaultProductAttribute = {
  id: "",
  Id: "",
  name: "",
  description: "",
  predefinedProductAttributeValues: []
}

export default ProductAttributeStore;