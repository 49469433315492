import * as React from 'react';
import { Checkbox, mergeStyleSets } from '@fluentui/react';
import { myTheme } from '../../styles/theme';
import { L } from '../../lib/abpUtility';

const classNames = mergeStyleSets({
    requiredMarker: {
        color: 'rgb(164, 38, 44)',
        marginLeft: '5px',
    }
});

export interface ICheckBoxProps {
    label: string
    value?: boolean | undefined;
    disabled?: boolean;
    required?: boolean;
    validationData?: any;
    customClassName?: any;
    onChange?: (value: boolean | undefined) => void;
    containerCustomStyles?: any;
}

export interface ICheckBoxState {
    value: boolean | undefined
}

export class CheckBoxBase extends React.Component<ICheckBoxProps, ICheckBoxState> {
    change = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        this.setState({ value: checked });
        if (this.props.onChange)
            this.props.onChange(checked);
    };

    private mapValidationData(validationData: any): any {
        let mappedValidationData: any = {};
    
        let notDefaultBehaviourForKeys: string[] = ['errorMessage', 'readOnly', 'disabled'];
        
        for(const key in validationData) {
            if (Object.prototype.hasOwnProperty.call(validationData, key)) {
                if(key === 'errorMessage') {
                    mappedValidationData[key] = L(validationData[key]);
                }
                if(key === 'readOnly' || key === 'disabled') {
                    mappedValidationData[key] = (validationData[key] === 'true' ? true : false);
                }
        
                if(!notDefaultBehaviourForKeys.includes(key)) {
                    mappedValidationData[key] = validationData[key];
                }
            }
        }
    
        return mappedValidationData;
    }
    
    render() {
        const { label, value, disabled, required, validationData, customClassName, containerCustomStyles } = this.props;

        if(!this.state || this.state.value !== value) {
            this.setState({ value: value });
        }

        let mappedValidationData = this.mapValidationData(validationData);

        return <div style={{ marginTop: 20, ...(required && required === true ? {display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-start'} : {}), ...containerCustomStyles }}>
            <Checkbox disabled={disabled} theme={myTheme} 
                checked={this.state && !!this.state.value ? this.state.value : false}
                label={label} onChange={this.change}
                className={customClassName}
                required={required}
                {...mappedValidationData}
            />
            {(required && required === true) && <span className={classNames.requiredMarker}>*</span>}
        </div>;
    }
}
