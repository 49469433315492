import { transformPropsFirstLetterToUpperCase } from "../../utils/modelUtils";
import { CrudServiceBaseTransformed } from "../base/crudServiceBaseTransformed";
import { PagedResultRequestDto } from "../base/pagedResultRequestDto";
import { PagedResultDto } from "../dto/pagedResultDto";
import Endpoint from "../endpoint";
import { httpApi } from "../httpService";
import { CountryDto } from "./countryDto";

export class CountryService extends CrudServiceBaseTransformed<CountryDto> {
    constructor() {
        super(Endpoint.Country);
        this.internalHttp = httpApi;
    }

    public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto): Promise<PagedResultDto<CountryDto>> {
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetAll?$orderby=DisplayOrder,Name&$filter=Published`), { params: pagedFilterAndSortedRequest });
        let toReturn;

        if(result.data.value && result.data.value.length > 0) {
            result.data.value.forEach((element: any) => {
                if(element.Id) { element.id = element.Id; }
            });
            toReturn = result.data.value;
        } else if(result.data && result.data.result && result.data.result.items && result.data.result.items.length > 0) {
            result.data.result.items.forEach((element: any) => {
                if(element.Id) { element.id = element.Id; }
            });
            toReturn = result.data.result.items;
        } else if(result.data) {
            if(Array.isArray(result.data)) {
                result.data.forEach((element: any) => {
                    if(element.Id) { element.id = element.Id; }
                });
            }
            toReturn = result.data;
        }

        toReturn = transformPropsFirstLetterToUpperCase(toReturn);

        return {
            totalCount: toReturn.length,
            items: toReturn
        };
    }
}

export default new CountryService();