import * as React from "react";
import { IPanelProps, mergeStyleSets, Panel, PanelType } from '@fluentui/react';
import { myTheme } from "../../styles/theme";
import {HeaderHeight, FooterHeight, SiderWidthWithMargines} from "../../styles/aboutApp"

export const classNames = mergeStyleSets({
  customPanel: {
    selectors: {
      '& .ms-Panel-commands': {
        zIndex: 1
      },
      '& .ms-Panel-footer': {
        background: myTheme.palette.white
      }
    }
  }
});

class CustomPanel extends React.Component<IPanelProps> {
  render() {
    return (
      <Panel 
        theme={myTheme}
        {...this.props}
        type={PanelType.custom}
        style={{marginTop: HeaderHeight, marginBottom: FooterHeight, marginLeft:SiderWidthWithMargines}}
        isFooterAtBottom={true}
        className={classNames.customPanel}
      >
        {this.props.children}
      </Panel>
    );
  }
}

export default CustomPanel;