import { InsurerAvailabilityDto } from '../services/insurerAvailability/dto/insurerAvailabilityDto';
import insurerAvailabilityService from '../services/insurerAvailability/insurerAvailabilityService';
import { CrudStoreBase } from './crudStoreBase';
import { defaultInsurer } from './insurerStore';

class InsurerAvailabilityStore extends CrudStoreBase<InsurerAvailabilityDto>{
	constructor() {
		super(insurerAvailabilityService, defaultInsurerAvailability)
	}
}

export const defaultInsurerAvailability: InsurerAvailabilityDto = {
	id: '',
    productName: '',
    productId: '',
    availability: false,
	insurerId: '',
    insurer: defaultInsurer,
}

export default InsurerAvailabilityStore;