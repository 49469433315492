import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { RatingDto } from './ratingDto';
import { httpApi } from '../httpService';

export class RatingService extends CrudServiceBase<RatingDto> {
    constructor() {
        super(Endpoint.Rating);
        this.internalHttp = httpApi;
    }
}

export default new RatingService();