import { isUserLoggedIn } from '../../utils/authUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultDto } from '../dto/pagedResultDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { InsurerDto, MobilePaymentSettingsDto } from './dto/insurerDto';

export class InsurerService extends CrudServiceBase<InsurerDto> {
    constructor() {
        super(Endpoint.Insurer);
        this.internalHttp = httpApi;
    }

    public async update(updateUserInput: InsurerDto) {
        isUserLoggedIn();

        const filteredEntityStringDto: any = getPartialModel(updateUserInput, [], ['creationTime', 'creatorUserId', 'lastModificationTime', 'lastModifierUserId']);

        if(!Array.isArray(filteredEntityStringDto.mobilePaymentSettings)) {
            const cloneMobilePaymentSettings: MobilePaymentSettingsDto[] = [];
            for(let key in Object.keys(filteredEntityStringDto.mobilePaymentSettings)) {
                cloneMobilePaymentSettings.push(filteredEntityStringDto.mobilePaymentSettings[key]);
            }
            filteredEntityStringDto.mobilePaymentSettings = cloneMobilePaymentSettings;
        }
        
        let result = await this.internalHttp.put(this.endpoint.Update(), filteredEntityStringDto);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getInsurersByPolicyType(policyType: string): Promise<PagedResultDto<InsurerDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetInsurersByPolicyType?policyType=${policyType}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new InsurerService();