import { CrudStoreBase } from './crudStoreBase';
import { defaultClient } from './clientStore';
import { defaultInsurancePolicy } from './insurancePolicyStore';
import { defaultUser } from './userCrudStore';
import { PolicyType } from '../services/policy/policyTypeEnums';
import agentClaimService from '../services/agentClaim/agentClaimService';
import { AgentClaimDto } from '../services/agentClaim/dto/agentClaimDto';

class AgentClaimStore extends CrudStoreBase<AgentClaimDto>{
  constructor() {
    super(agentClaimService, defaultAgentClaim)
  }

  public async create(createUserInput: AgentClaimDto) {
    return agentClaimService.create(createUserInput);
  }

  public async getColumnValue(payload: any) {
    return agentClaimService.getColumnValue(payload);
  }
}

export const defaultAgentClaim = {
  id: '',
  claimNumber: '',
  insurer: '',
  clientId: 0,
  client: defaultClient,
  policyNumber: '',
  policyId: null,
  policy: defaultInsurancePolicy,
  claimSubject: '',
  claimType: PolicyType.Vehicle,
  applicationDate: '',
  claimDate: '',
  emailAddress: '',
  note: '',
  compensationPaid: false,
  invoicePaid: false,
  daysSinceStatusChanged: null,
  claimStatus: '',
  claimStatusUpdateDate: '',
  daysSinceClaimReport: null,
  agentId: 0,
  agent: defaultUser,
  insuranceClaimType: ''
}

export default AgentClaimStore;