import { CrudStoreBase } from './crudStoreBase';
import { AgentClaimAmeStatusDto } from '../services/agentClaimAmeStatus/dto/agentClaimAmeStatusDto';
import { defaultAgentClaim } from './agentClaimStore';
import AgentClaimAmeStatusService from '../services/agentClaimAmeStatus/agentClaimAmeStatusService';

class AgentClaimAmeStatusStore extends CrudStoreBase<AgentClaimAmeStatusDto>{
  constructor() {
    super(AgentClaimAmeStatusService, defaultAgentClaimAmeStatus)
  }

  public async getByClaimId(claimId: string) {
		await AgentClaimAmeStatusService.getByClaimId(claimId).then((result: any) => {
			this.dataSet = {
				totalCount: result.items.length, 
				items: result.items
			};
		}).catch((error: any) => {
			console.error(error);
		});
	}

  public async getAll(request: any) {
    if(request && request.claimId) {
        return this.getByClaimId(request.claimId);
    }
  }

  public async create(createClaimAmeStatusInput: AgentClaimAmeStatusDto) {
		return AgentClaimAmeStatusService.create(createClaimAmeStatusInput);
	}
}

export const defaultAgentClaimAmeStatus = {
  id: '',
  claimId: 0,
  claim: defaultAgentClaim,
  appraiserFV: 0,
  pricing: 0,
  approvedQuotation: 0,
  lastModificationTime: '',
  lastModifierUserId: 0,
  creationTime: '',
  creatorUserId: 0,
  currency: ''
}

export default AgentClaimAmeStatusStore;