import { CrudStoreBase } from './crudStoreBase';
import { PaymentDto } from '../services/payment/dto/paymentDto';
import paymentService from '../services/payment/paymentService';

class PaymentStore extends CrudStoreBase<PaymentDto>{
	constructor() {
		super(paymentService, defaultPayment)
	}
}

export const defaultPayment: PaymentDto = {
    id: '',
	orderId: '',
    orderGuid: '',
    policyId: 0,
    policy: {},
    amountPaid: 0,
    status:	'',
    paymentMessage: '',
}

export default PaymentStore;