import { isUserLoggedIn } from "../../utils/authUtils";
import { CrudServiceBase } from "../base/crudServiceBase";
import Endpoint from "../endpoint";
import { httpApi } from "../httpService";
import { IndividualOfferDto } from "./individualOfferDto";

export class IndividualOfferService extends CrudServiceBase<IndividualOfferDto> {
    constructor() {
        super(Endpoint.IndividualOffer);
        this.internalHttp = httpApi;
    }

    public async create(createIndividualOfferInput: IndividualOfferDto) {
        isUserLoggedIn();
        
        const filteredcreateIndividualOfferInput: any = {
            ...createIndividualOfferInput,
        };
        
        delete filteredcreateIndividualOfferInput['id'];
        delete filteredcreateIndividualOfferInput['paymentDates'];
        delete filteredcreateIndividualOfferInput['fees'];

        let result = await httpApi.post(this.endpoint.Create(), filteredcreateIndividualOfferInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
    
    public async createPolicyFromOffer(createPolicyFromOfferInput: IndividualOfferDto) {
        isUserLoggedIn();

        const filteredcreatePolicyFromOfferInput: any = {
            ...createPolicyFromOfferInput
        };

        delete filteredcreatePolicyFromOfferInput['calculationId']
        delete filteredcreatePolicyFromOfferInput['status']
        delete filteredcreatePolicyFromOfferInput['insurerName']
        delete filteredcreatePolicyFromOfferInput['insuranceCoverage']
        delete filteredcreatePolicyFromOfferInput['installments']
        delete filteredcreatePolicyFromOfferInput['total']
        delete filteredcreatePolicyFromOfferInput['id']

        let result = await httpApi.post(this.endpoint.Custom(`CreatePolicyFromOffer`), filteredcreatePolicyFromOfferInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByCalculationId(calculationId: number) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByCalculationId?calculationId=${calculationId}`, true));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async sendIndividualOffer(id: number) {
        isUserLoggedIn()
        let result = await httpApi.post(this.endpoint.Custom(`SendIndividualOffer?individualOfferAttachedFileId=${id}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new IndividualOfferService();