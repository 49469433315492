import { CrudStoreBase } from './crudStoreBase';
import ratingService from '../services/rating/ratingService';
import { RatingDto } from '../services/rating/ratingDto';

class RatingStore extends CrudStoreBase<RatingDto>{
  constructor() {
    super(ratingService, defaultRating)
  }
}

export const defaultRating = {
  id: "",
  title: "",
  userUuid: "",
  userEmail: "",
  categoryId: 0,
  text: "",
  lastModificationTime: "",
  lastModifierUserId: 0,
  creationTime: "",
  creatorUserId: 0
}

export default RatingStore;