import { CommunityDto } from '../services/community/dto/communityDto';
import CommunityService from '../services/community/communityService';
import { CrudStoreBase } from './crudStoreBase';

class CommunityStore extends CrudStoreBase<CommunityDto>{
  constructor() {
    super(CommunityService, defaultCommunity)
  }
}

export const defaultCommunity = {
  id: '',
  numberOfUsers: 0,
  numberOfPurchasedPolicies: 0,
  averageTimeOfConcludingPolicyAgreement: 0,
  numberOfClaims: 0,
  appRank: 0,
}

export default CommunityStore;