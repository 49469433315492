import { CrudStoreBase } from './crudStoreBase';
import { InsurerDto } from '../services/insurer/dto/insurerDto';
import insurerService from '../services/insurer/insurerService';

class InsurerStore extends CrudStoreBase<InsurerDto>{
	constructor() {
		super(insurerService, defaultInsurer)
	}

    public async getInsurersByPolicyType(policyType: string) {
		this.dataSet = {
			totalCount: 0, 
			items: []
		};
		await insurerService.getInsurersByPolicyType(policyType).then((result: any) => {
			this.dataSet = {
				totalCount: result.items.length, 
				items: result.items
			};
		}).catch((error: any) => {
			console.error(error);
		});
	}
}

export const defaultInsurer: InsurerDto = {
	id: '',
    name: '',
    fullName: '',
    address: '',
    logoLink: '',
    mobilePaymentSettings: [],
    phoneNumber: '',
    emailAddress: '',
    isAgentSales: false,
    isDirectSales: false,
    isSendProposal: false,
	isAgencySales: false,
	isSendPdfFromCalculation: false,
	zipPostalCode: '',
	city: ''
}

export default InsurerStore;