import { CrudStoreBase } from './crudStoreBase';
import { LoyaltyPointsHistoryDto } from '../services/loyaltyPointsHistory/loyaltyPointsHistoryDto';
import LoyaltyPointsHistoryService from '../services/loyaltyPointsHistory/loyaltyPointsHistoryService';

class LoyaltyPointsHistoryStore extends CrudStoreBase<LoyaltyPointsHistoryDto>{
	constructor() {
		super(LoyaltyPointsHistoryService, defaultLoyaltyPointsHistory)
	}

	public async create(createUserInput: LoyaltyPointsHistoryDto) {
		return LoyaltyPointsHistoryService.create(createUserInput);
	}

	public async get(entityDto: LoyaltyPointsHistoryDto) {
		return LoyaltyPointsHistoryService.get(entityDto);
	}
}

export const defaultLoyaltyPointsHistory = {
	id: '',
	CustomerId: '',
	StoreId: '',
	Points: 0,
	PointsBalance: 0,
	UsedAmount: 0,
	Message: '',
	CreatedOnUtc: '',
	UsedWithOrderId: '',
}

export default LoyaltyPointsHistoryStore;