import { action, observable } from 'mobx';

import IsTenantAvaibleOutput from '../services/account/dto/isTenantAvailableOutput';
import accountService from '../services/account/accountService';
import { RegisterCustomerDto } from '../services/account/dto/registerCustomerDto';
import { EntityDto } from '../services/dto/entityDto';
import { RequestResetPassword } from '../services/account/dto/requestResetPassword';
import ResetPasswordInput from '../services/account/dto/resetPasswordInput';

class AccountStore {

  @observable tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput();
  @observable customer!: RegisterCustomerDto;

  @action
  public isTenantAvailable = async (tenancyName: string) => {
    this.tenant = await accountService.isTenantAvailable({ tenancyName: tenancyName });
  };

  @action
  public searchByNip = async (nip: string) => {
    return await accountService.searchByNip(nip);
  };

  @action
  public registerCustomer = async (customerDetail: RegisterCustomerDto) => {
    this.customer = await accountService.registerCustomer(customerDetail);
  };

  @action
  public getCustomer = async (customer: EntityDto) => {
    this.customer = await accountService.getCustomer(customer)
  }
  @action
  public updateCustomer = async (customerDetail: RegisterCustomerDto) => {
    this.customer = await accountService.updateCustomer(customerDetail);
  };

  @action
  public confirmEmail = async (search: string) => {
    await accountService.confirmEmail(search);
  };

  @action
  public resetPassword = async (input: ResetPasswordInput) => {
    await accountService.resetPassword(input);
  };



  @action
  public requestResetPassword = async (email: RequestResetPassword) => {
    await accountService.requestResetPassword(email);
  }


  @action
  public getHtml = async (): Promise<any> => {
    var response = await accountService.getHtml();
    return response
  }

  @action
  public createCustomer = async () => {
    this.customer = {
      login: '',
      password: '',
      email: '',
      name: '',
      surname: '',
      companyName: '',
      NIP: '',
      BDO: '',
      contactName: '',
      contactSurname: '',
      phone: '',
      id: 0
    };
  };
}

export default AccountStore;
