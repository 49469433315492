import { isUserLoggedIn } from '../../utils/authUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { InsurerAvailabilityDto } from './dto/insurerAvailabilityDto';

export class InsurerAvailabilityService extends CrudServiceBase<InsurerAvailabilityDto> {
    constructor() {
        super(Endpoint.InsurerAvailability);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: InsurerAvailabilityDto) {
        isUserLoggedIn();

        const filteredEntityStringDto: any = getPartialModel(createUserInput, [], ['id', 'mobilePaymentSettings', 'lastModifierUserId', 'creatorUserId', 'lastModificationTime']);

        let result = await this.internalHttp.post(this.endpoint.Create(), filteredEntityStringDto);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateUserInput: InsurerAvailabilityDto) {
        isUserLoggedIn();

        const filteredEntityStringDto: any = getPartialModel(updateUserInput, [], 
            ['mobilePaymentSettings', 'lastModifierUserId', 'creatorUserId', 'lastModificationTime'], true);
        
        let result = await this.internalHttp.put(this.endpoint.Update(), filteredEntityStringDto);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByInsurer(insurerId: string) {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetByInsurer/?insurerId=${insurerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new InsurerAvailabilityService();