import { isUserLoggedIn } from "../../utils/authUtils";
import { CrudServiceBase } from "../base/crudServiceBase";
import { PagedResultRequestDto } from "../base/pagedResultRequestDto";
import { PagedResultDto } from "../dto/pagedResultDto";
import Endpoint from "../endpoint";
import { httpApi } from "../httpService";
import { CountryFluentListDto } from "./countryFluentListDto";

export class CountryItemsService extends CrudServiceBase<CountryFluentListDto> {
    constructor() {
        super(Endpoint.CountryItems);
        this.internalHttp = httpApi;
    }

    public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<CountryFluentListDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetCountryListItems`), { params: { ...pagedFilterAndSortedRequest } });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new CountryItemsService();