import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { AgentClaimHistoryDto } from './dto/agentClaimHistoryDto';

declare var abp: any;

export class AgentClaimHistoryService extends CrudServiceBase<AgentClaimHistoryDto> {
    constructor() {
        super(Endpoint.AgentClaimHistory);
        this.internalHttp = httpApi;
    }

    public async getByClaimId(claimId: string) {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetByClaimId?claimId=${claimId}`, true));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createClaimHistoryInput: AgentClaimHistoryDto) {
        isUserLoggedIn();
        let userId = abp.session.userId;

        const filteredClaimHistoryInput: any = {
            ...createClaimHistoryInput,
            claimId: Number(createClaimHistoryInput.claimId),
            creationTime: new Date(),
            creatorUserId: userId,
        }
        delete filteredClaimHistoryInput['id'];

        let result = await httpApi.post(this.endpoint.Create(), filteredClaimHistoryInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new AgentClaimHistoryService();