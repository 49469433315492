import { mergeStyleSets} from '@fluentui/merge-styles';
import {additionalTheme, myTheme} from './theme';

export const fluentTableClassNames = mergeStyleSets({
  contentContainer: {
    display: 'block',
    maxWidth: '99%',
    height: '300px',
    maxHeight: '300px',
    position: 'relative',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px',
    marginTop: '20px',
    minWidth: '640px',
    padding: '20px',
      marginRight:'20px'
  },
  fontBold: {
    fontWeight: '800',
  },
  summaryAttributeWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
    width: 'auto',
  },
  summaryAttribute: {
      display: 'flex',
      alignItems: 'center',
      color: `${additionalTheme.white} !important`,
      padding: '5px 10px',
      margin: '0 0 0 10px !important',
      background: myTheme.palette.themeDarker,
      fontSize: '0.8rem',
      whiteSpace: 'pre-line',
      borderRadius: '9.5px',
      height: '22px',
      selectors: {
        '&:last-child': {
          borderRight: 'none',
          }
      }
  },
});