import {additionalTheme} from "../../styles/theme";

export interface ILabelComponentProps {
    label: string;
    required?: boolean;
    displayRequired?: boolean;
    customStyles?: any;
    className?: any;
}

export const LabelComponent = (props: ILabelComponentProps) => (
    <div className={props.className ? props.className : ''}
        style={{ 
            width: '350px',
            minWidth: '350px',
            minHeight: '33px',
            color: additionalTheme.grey,
            fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
            fontSize: '16px',
            marginRight: '30px',
            boxSizing: 'border-box',
            position: 'relative',
            ...props.customStyles
        }}
    >
        {props.label}
        {/* {(props.required && props.displayRequired) && <sup style={{color: 'rgb(164, 38, 44)', marginLeft: '5px'}}>{L('required')}</sup>} */}
        {(props.required && props.displayRequired) && <span style={{color: 'rgb(164, 38, 44)', position: 'absolute', top: 0, right: -10, fontSize: '14px', fontWeight: 400}}>*</span>}
    </div>
);