import { CrudStoreBase } from './crudStoreBase';
import { ProductTypeDto } from '../services/productType/productTypeDto';
import productTypeService from '../services/productType/productTypeService';

class ProductTypeStore extends CrudStoreBase<ProductTypeDto>{
  constructor() {
    super(productTypeService, defaultProductType)
  }
}

export const defaultProductType = {
  id: '',
  Id: '',
  Name: '',
  // Description: '',
  // BottomDescription: '',
  // CategoryLayoutId: string,
  // MetaKeywords: string,
  // MetaDescription: string,
  // MetaTitle: string,
  // SeName: string,
  // ParentCategoryId: string,
  // PictureId: string,
  // PageSize: number,
  // AllowCustomersToSelectPageSize: boolean,
  // PageSizeOptions: string,
  // ShowOnHomePage: boolean,
  // FeaturedProductsOnHomePage: boolean,
  // IncludeInMenu: boolean,
  Published: false,
  DisplayOrder: 0,
  // Flag: string,
  // FlagStyle: string,
  // Icon: string,
  // HideOnCatalog: boolean,
  // ShowOnSearchBox: boolean,
  // SearchBoxDisplayOrder: number
}

export default ProductTypeStore;