import { IDropdownOption } from '@fluentui/react';
import http from './httpService';

class AbpUserConfigurationService {
  public async getAll() {
    const result = await http.get('/AbpUserConfiguration/GetAll');
    return result;
  }

  async GetDataResult(path: string): Promise<IDropdownOption[]> {
    const result = await http.get(path);
    return !!result.data && !!result.data.result ? result.data.result : result.data;
  }
}

export default new AbpUserConfigurationService();
