import { parseJsonStringToDto } from '../../utils/inputUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { PolicyType } from '../policy/policyTypeEnums';
import { ClaimChildrenDto } from './dto/claimChildrenDto';
import { ClaimDto } from './dto/claimDto';
import { ClaimHomeDto } from './dto/claimHomeDto';
import { ClaimLifeDto } from './dto/claimLifeDto';
import { ClaimTravelDto } from './dto/claimTravelDto';
import { ClaimVehicleDto } from './dto/claimVehicleDto';

export class ClaimService extends CrudServiceBase<ClaimDto> {
    constructor() {
        super(Endpoint.Claim);
        this.internalHttp = httpApi;
    }

    public async getChildren(entityDto: ClaimChildrenDto): Promise<ClaimChildrenDto> {
        let result = await this.internalHttp.get(Endpoint.ClaimChildren.Get(), { params: entityDto });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getHome(entityDto: ClaimHomeDto): Promise<ClaimHomeDto> {
        let result = await this.internalHttp.get(Endpoint.ClaimHome.Get(), { params: entityDto });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getLife(entityDto: ClaimLifeDto): Promise<ClaimLifeDto> {
        let result = await this.internalHttp.get(Endpoint.ClaimLife.Get(), { params: entityDto });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getTravel(entityDto: ClaimTravelDto): Promise<ClaimTravelDto> {
        let result = await this.internalHttp.get(Endpoint.ClaimTravel.Get(), { params: entityDto });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getVehicle(entityDto: ClaimVehicleDto): Promise<ClaimVehicleDto> {
        let result = await this.internalHttp.get(Endpoint.ClaimVehicle.Get(), { params: entityDto });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createUserInput: ClaimDto) {
        let result: any = {};
        let userInputClone: any = {...createUserInput};

        delete userInputClone['id'];

        const policyType = PolicyType[userInputClone.policyType] as unknown as PolicyType;

        if(userInputClone['claimCauseList']) {
            userInputClone['claimCauseList'] = parseJsonStringToDto(userInputClone['claimCauseList'], 'ClaimCauseDto');
        }

        if(userInputClone['listOfDamages']) {
            userInputClone['listOfDamages'] = parseJsonStringToDto(userInputClone['listOfDamages'], 'ClaimDamageDto');
        }

        if(userInputClone['repairedAtASO'] === 'true') userInputClone['repairedAtASO'] = true;
        if(userInputClone['repairedAtASO'] === 'false') userInputClone['repairedAtASO'] = false;
        if(userInputClone['policeNotified'] === 'true') userInputClone['policeNotified'] = true;
        if(userInputClone['policeNotified'] === 'false') userInputClone['policeNotified'] = false;

        switch(policyType) {
            case PolicyType.Children:
                result = await this.internalHttp.post(Endpoint.ClaimChildren.Create(), userInputClone as ClaimChildrenDto);
            break;
            case PolicyType.Home:
                result = await this.internalHttp.post(Endpoint.ClaimHome.Create(), userInputClone as ClaimHomeDto);
            break;
            case PolicyType.Life:
                result = await this.internalHttp.post(Endpoint.ClaimLife.Create(), userInputClone as ClaimLifeDto);
            break;
            case PolicyType.Travel:
                result = await this.internalHttp.post(Endpoint.ClaimTravel.Create(), userInputClone as ClaimTravelDto);
            break;
            case PolicyType.Vehicle:
                result = await this.internalHttp.post(Endpoint.ClaimVehicle.Create(), userInputClone as ClaimVehicleDto);
            break;
            default:
                return;
        }
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateUserInput: ClaimDto) {
        let result: any;

        if(updateUserInput['claimCauseList']) {
            updateUserInput['claimCauseList'] = parseJsonStringToDto(updateUserInput['claimCauseList'], 'ClaimCauseDto');
        }

        if(updateUserInput['listOfDamages']) {
            updateUserInput['listOfDamages'] = parseJsonStringToDto(updateUserInput['listOfDamages'], 'ClaimDamageDto');
        }

        if(updateUserInput['repairedAtASO'] === 'true') updateUserInput['repairedAtASO'] = true;
        if(updateUserInput['repairedAtASO'] === 'false') updateUserInput['repairedAtASO'] = false;
        if(updateUserInput['policeNotified'] === 'true') updateUserInput['policeNotified'] = true;
        if(updateUserInput['policeNotified'] === 'false') updateUserInput['policeNotified'] = false;
        
        const policyType = PolicyType[updateUserInput.policyType] as unknown as PolicyType;

        switch(policyType) {
            case PolicyType.Children:
                result = await this.internalHttp.put(Endpoint.ClaimChildren.Update(), updateUserInput as ClaimChildrenDto);
            break;
            case PolicyType.Home:
                result = await this.internalHttp.put(Endpoint.ClaimHome.Update(), updateUserInput as ClaimHomeDto);
            break;
            case PolicyType.Life:
                result = await this.internalHttp.put(Endpoint.ClaimLife.Update(), updateUserInput as ClaimLifeDto);
            break;
            case PolicyType.Travel:
                result = await this.internalHttp.put(Endpoint.ClaimTravel.Update(), updateUserInput as ClaimTravelDto);
            break;
            case PolicyType.Vehicle:
                result = await this.internalHttp.put(Endpoint.ClaimVehicle.Update(), updateUserInput as ClaimVehicleDto);
            break;
        }
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new ClaimService();