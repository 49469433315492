import { IndividualOfferDto } from "../services/individualOffer/individualOfferDto";
import individualOfferService from "../services/individualOffer/individualOfferService";
import { CrudStoreBase } from "./crudStoreBase";

class IndividualOfferStore extends CrudStoreBase<IndividualOfferDto> {
    constructor() {
        super(individualOfferService, defaultIndividualOffer)
    }

    public async getByCalculationId(calculationId: number) {
        return individualOfferService.getByCalculationId(calculationId)
    }
}

export const defaultIndividualOffer: IndividualOfferDto = {
    calculationId: 0,
    id: "",
    status: "",
    insurerName: "",
    insuranceCoverage: [],
    installments: "",
    total: 0,
    policyNumber: "",
    bankAccountNumber: "",
    paymentDates: [],
    fees: [],
    startDate: "",
    endDate: "",
    individualOfferId: 0
}

export default IndividualOfferStore;