import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultDto } from '../dto/pagedResultDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { SportInsuranceCoverageDto } from './dto/sportInsuranceCoverageDto';

export class SportInsuranceCoverageService extends CrudServiceBase<SportInsuranceCoverageDto> {
    constructor() {
        super(Endpoint.SportInsuranceCoverage);
        this.internalHttp = httpApi;
    }

    public async getBySportId(sportId: string): Promise<PagedResultDto<SportInsuranceCoverageDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetBySportDiscipline?sportDisciplineId=${sportId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByInsurerId(insurerId: string): Promise<PagedResultDto<SportInsuranceCoverageDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByInsurerId?insurerId=${insurerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateSportInsuranceCoverage: any) {
        let result = await httpApi.put(this.endpoint.Update(), updateSportInsuranceCoverage);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createSportInsuranceCoverage: any) {
        let result = await httpApi.post(this.endpoint.Create(), createSportInsuranceCoverage);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getSportCoverageExcelByInsurerId(insurerId: string) {
        let result = await this.internalHttp.post(this.endpoint.Custom(`GetSportCoverageExcelByInsurerId?insurerId=${insurerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async createSportCoverageListForInsurerId(insurerId: number) {
        let result = await httpApi.post(this.endpoint.Custom(`CreateSportDisciplineCoverageListForInsurerId?insurerId=${insurerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new SportInsuranceCoverageService();