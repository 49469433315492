import * as React from 'react';
import { CommandBar, ICommandBarItemProps, ICommandBarStyles, Spinner, SpinnerSize } from '@fluentui/react';
import { Selection } from '@fluentui/react';
import { L } from '../../lib/abpUtility';
import './index.less';
import {
  mergeStyleSets,
  IContextualMenuItemStyles,
  memoizeFunction,
  IButtonStyles,
  concatStyleSets,
  IButtonProps,
  CommandBarButton,
} from '@fluentui/react';
import { myTheme } from '../../styles/theme';

export interface ICrudPermissons {
  create?: boolean,
  update?: boolean,
  delete?: boolean,
  customActions?: boolean,
}

export interface ICustomActionProps {
  displayFor: string;
  buttonText: string;
  buttonIcon?: string;
  buttonBackground?: string;
  buttonBorder?: string;
  buttonColor?: string;
  buttonIconColor?: string;
  buttonDisabled?: boolean;
  buttonClassNames?: string;
}

export interface ICommandBarBaseProps<T> {
  selection?: Selection;
  models?: T[];
  create?: () => void;
  update?: (item: T) => void;
  delete?: (item: T) => void;
  deleteMany?: (item: T[]) => void;
  updateMany?: (item: T[]) => void;
  setButtons?: () => any,
  customActions?: { (item: T, that?: any): void }[];
  disableGeneric?: boolean;
  permissons?: ICrudPermissons;
  customActionsProps?: ICustomActionProps[];
  customStyles?: any;
}
const buttonWidth = '146px';
const buttonHeight = '42px';

export const classNames = mergeStyleSets({
  customCommandbar: {
    marginTop:'20px',
    marginBottom:'20px',
    flex: 1,
    display:'flex',
    height: buttonHeight,
    transition: 'all .3s linear',
    selectors: {
      '& .ms-CommandBar': {
        paddingLeft: '0px',
        height: buttonHeight,
        background: 'transparent'
      },
      '& .ms-Button': {
        width: buttonWidth,
      },
      '& .ms-Button-label': {
        fontSize: 16
      }
    },
  },
  customCommandbarMsg: {
    flex: 1,
    display:'flex',
    justifyContent: 'flex-end',
    marginTop: '30px',
    height: buttonHeight,
    transition: 'all .3s linear',
    selectors: {
      '& .ms-CommandBar': {
        paddingLeft: '0px',
        height: buttonHeight,
        background: 'transparent'
      },
      '& .ms-Button': {
        width: buttonWidth,
        background: myTheme.palette.white
      },
      '& .ms-Button-label': {
        fontSize: 16
      }
    },
  },
  customCommandbarAbout: {
    flex: 1,
    display:'flex',
    marginTop: '20px',
    height: buttonHeight,
    transition: 'all .3s linear',
    selectors: {
      '& .ms-CommandBar': {
        paddingLeft: '0px',
        height: buttonHeight,
        background: 'transparent'
      },
      '& .ms-Button-label': {
        fontSize: 16
      }
    },
  },
  buttons: {
    marginRight: '30px',
    selectors: {
      '& .ms-Button-flexContainer': {
        flexDirection: 'row-reverse'
      },
      '.ms-Button-textContainer': {
        flexGrow: 'unset'
      },
      ':hover .ms-Icon': {
        color: myTheme.palette.white
      }
    }
  },
  buttonsTextBlack: {
    background:myTheme.palette.white,
    color: myTheme.palette.black,
    selectors: {
      '.ms-Icon': {
        color: myTheme.palette.black
      },
      ':hover .ms-Icon': {
        color: myTheme.palette.black
      }
    }
  },
  customActionButton: {
    background: myTheme.palette.white,
    width: 'auto !important'

  },
  commandBarWrapper: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '90%',
  },
  loadSpinner: {
    display: 'inline-flex',
    marginTop: '30px',
    marginRight: 'auto',
    selectors: {
        '& .ms-Spinner-label': {
            color: myTheme.palette.themePrimary,
        }
    }
  },
});

const itemStyles: Partial<IContextualMenuItemStyles> = {
  label: { fontSize: 18 },
};

const getCommandBarButtonStyles = memoizeFunction(
  (originalStyles: IButtonStyles | undefined): Partial<IContextualMenuItemStyles> => {
    if (!originalStyles) {
      return itemStyles;
    }

    return concatStyleSets(originalStyles, itemStyles);
  }
);

export const CustomButton: React.FunctionComponent<IButtonProps> = (props) => {
  return <CommandBarButton {...props} styles={getCommandBarButtonStyles(props.styles)} />;
};

export class CommandBarBase<T, TProps extends ICommandBarBaseProps<T> = ICommandBarBaseProps<T>> extends React.Component<TProps> {
  private customActionButtonsDisabled: boolean = false;
  private buttonNames = typeof this.props.setButtons === 'function' ? this.props.setButtons() : {
    'newItem': {text: 'New', icon: 'Add'},
    'delete': {text: 'Delete'},
    'edit': {text: 'Edit'},
    'newMsg': {text: 'Msg', icon: ''},
  };

  private doesPropExist(propNames: string[], specifiedProp?: any): boolean {
    let tempPrevProp = specifiedProp ? specifiedProp : this.props;
    let result = true;

    propNames.forEach(name => {
      if(tempPrevProp[name]) {
        tempPrevProp = tempPrevProp[name];
      } else {
        result = false;
      }
    });

    return result;
  }

  protected getNew(): ICommandBarItemProps {
    return {
      key: 'newItem',
      text: L(this.buttonNames['newItem'].text),
      iconProps: { iconName: this.buttonNames['newItem'].icon },
      className: classNames.buttons,
      style: {
        color: myTheme.palette.white,
        backgroundColor: myTheme.palette.themePrimary,
      },
      buttonStyles: {
        icon: {
          color: myTheme.palette.white,
        }
      },
      onClick: () => {
        if (this.props.create) this.props.create()
      },
    };
  }

  protected getDelete(): ICommandBarItemProps {
    return {
      key: 'delete',
      text: L(this.buttonNames['delete'].text),
      iconProps: { iconName: this.buttonNames['delete'].icon },
      className: classNames.buttons,
      buttonIcon: 'none',
      style: {
        color: myTheme.palette.white,
        backgroundColor: myTheme.palette.red,
      },
      buttonStyles: {
        icon: {
          color: myTheme.palette.white,
        }
      },
      onClick: () => {
        if (this.props.delete) this.props.delete(this.getItem());
      },
    };
  }

  protected getUpdateMany(): ICommandBarItemProps {
    return {
      key: 'edit',
      text: L(this.buttonNames['edit'].text),
      iconProps: { iconName: this.buttonNames['edit'].icon },
      className: `${classNames.buttons} ${classNames.buttonsTextBlack}`,
      style: {
        color: myTheme.palette.black,
        border: `1px solid ${myTheme.palette.black}`,
      },
      onClick: () => {
        let items = this.getItems();
        if (this.props.updateMany) this.props.updateMany(items);
        else console.warn('this.props.updateMany is not a function');
      },
    };
  }

  protected getDeleteMany(): ICommandBarItemProps {
    return {
      key: 'delete',
      text: L(this.buttonNames['delete'].text),
      iconProps: { iconName: this.buttonNames['delete'].icon },
      className: classNames.buttons,
      style: {
        color: myTheme.palette.white,
        backgroundColor: myTheme.palette.red,
      },
      buttonStyles: {
        icon: {
          color: myTheme.palette.white,
        }
      },
      onClick: () => {
        let items = this.getItems();
        if (this.props.deleteMany) this.props.deleteMany(items);
      },
    };
  }

  protected getEdit(): ICommandBarItemProps {
    return {
      key: 'edit',
      text: L(this.buttonNames['edit'].text),
      iconProps: { iconName: this.buttonNames['edit'].icon },
      className: `${classNames.buttons} ${classNames.buttonsTextBlack}`,
      style: {
        border: `1px solid ${myTheme.palette.black}`,
      },
      onClick: () => {
        let item = this.getItem();
        if (this.props.update) this.props.update(item);
      },
    };
  }

  protected getMsg(): ICommandBarItemProps {
    return {
      key: 'newMsg',
      text: L(this.buttonNames['newMsg'].text),
      className: classNames.buttons,
      style: {
        color: myTheme.palette.black,
      },
      onClick: () => {
        if (this.props.create) this.props.create();
      },
    };
  }

  protected getForNone(): ICommandBarItemProps[] {
    if (this.isEnabled()) {
      let array: ICommandBarItemProps[] = [];

      if (this.props.permissons && this.props.permissons.create)
        array.push(this.getNew());
      if (this.props.permissons && this.props.permissons.customActions)
        array = [...array, ...this.getCustomActions('none')];

      return array;
    }
    return [];
  }

  protected getForSingle(): ICommandBarItemProps[] {
    if (this.isEnabled()) {
      let array: ICommandBarItemProps[] = [];
      if (this.props.permissons && this.props.permissons.update)
        array.push(this.getEdit())
      if (this.props.permissons && this.props.permissons.delete)
        array.push(this.getDelete())
      if (this.props.permissons && this.props.permissons.customActions)
        array = [...array, ...this.getCustomActions('single')];

      return array;
    }
    return [];
  }

  protected getCustomActions(displayFor: string): ICommandBarItemProps[] {
    let customActionPropsArr: ICommandBarItemProps[] = [];

    this.props.customActionsProps?.forEach((customActionProps: ICustomActionProps, index: number) => {
      if(this.doesPropExist(['displayFor'], customActionProps) && customActionProps['displayFor'] === displayFor) {
        customActionPropsArr.push({
          key: `customAction${index}`,
          text: this.doesPropExist(['buttonText'], customActionProps) ? customActionProps?.buttonText : L('Custom action'),
          iconProps: { iconName: this.doesPropExist(['buttonIcon'], customActionProps) ? customActionProps?.buttonIcon : 'AddNotes' },
          className: `${classNames.buttons} ${classNames.buttonsTextBlack} ${this.doesPropExist(['buttonClassNames'], customActionProps) ? customActionProps?.buttonClassNames : classNames.customActionButton}`,
          disabled: this.doesPropExist(['buttonDisabled'], customActionProps) ? customActionProps?.buttonDisabled : this.customActionButtonsDisabled,
          style: {
            border: this.doesPropExist(['buttonBorder'], customActionProps) ? customActionProps?.buttonBorder : `1px solid ${myTheme.palette.black}`,
            background: this.doesPropExist(['buttonBackground'], customActionProps) ? customActionProps?.buttonBackground : '',
            color: this.doesPropExist(['buttonColor'], customActionProps) ? customActionProps?.buttonColor : '',
          },
          buttonStyles: {
            icon: {
              color: this.doesPropExist(['buttonIconColor'], customActionProps) ? `${customActionProps?.buttonIconColor} !important` : '',
            }
          },
          onClick: () => {
            let item = this.getItem();
            if(this.props.customActions && this.props.customActions[index]) {
              this.props.customActions[index](item, this);
            }
          },
        });
      }
    });

    return customActionPropsArr;
  }

  protected getForMany(): ICommandBarItemProps[] {
    let array: ICommandBarItemProps[] = [];
    if (this.isEnabled()) {
      if (this.props.permissons && this.props.permissons.delete)
        array.push(this.getDeleteMany());
      if (this.props.permissons && this.props.permissons.customActions)
        array = [...array, ...this.getCustomActions('multiple')];

        return array;
    }
    return [];
  }

  private toggleCustomActionButton(newState: boolean, forceUpdate?: boolean) {
    if(typeof newState === 'boolean') {
      this.customActionButtonsDisabled = newState;

      if(forceUpdate === true) {
        this.forceUpdate();
      }
    }
  }

  private isEnabled() {
    return !this.props.disableGeneric;
  }

  protected getItem(): T {
    const { selection, models: items } = this.props;
    if (selection) {
      return selection.getSelection()[0] as T;
    } else if (items) {
      return items[0];
    }
    throw new Error('No item in selection');
  }

  protected getItems(): T[] {
    const { selection, models: items } = this.props;
    if (selection) {
      return selection.getSelection().map((element) => element as T);
    } else if (items) {
      return items!;
    }
    return [];
  }

  protected getSelectedCount(): number {
    const { selection, models: items } = this.props;
    if (selection) {
      return selection.getSelectedCount();
    } else if (items) {
      return items.length;
    }
    return 0;
  }

  render() {
    let items = [this.getNew()];
    const selectionCount = this.getSelectedCount();
    switch (selectionCount) {
      case 0:
        items = this.getForNone();
        break;
      case 1:
        items = this.getForSingle();
        break;
      default:
        items = this.getForMany();
        break;
    }
    let commandBarStyles: ICommandBarStyles = {
      primarySet: {
        marginBottom: '5px'
      },
    }

    return <div className={classNames.commandBarWrapper} style={this.props.customStyles}>
      <CommandBar theme={myTheme} items={items} className={classNames.customCommandbar} buttonAs={CustomButton} styles={commandBarStyles} />
      {this.customActionButtonsDisabled && <Spinner label={L('Please wait...')} className={classNames.loadSpinner} size={SpinnerSize.large} ariaLive="assertive" labelPosition="right" />}
    </div>;
  }
}