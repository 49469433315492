import { isUserLoggedIn } from "../../utils/authUtils";
import { CrudServiceBase } from "../base/crudServiceBase";
import { PagedResultDto } from "../dto/pagedResultDto";
import Endpoint from "../endpoint";
import { httpApi } from "../httpService";
import { VehicleDto } from "./vehicleDto";

export class VehicleService extends CrudServiceBase<VehicleDto> {
    constructor() {
        super(Endpoint.Vehicle);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: VehicleDto) {
        isUserLoggedIn();
        let result = await this.internalHttp.post(this.endpoint.Create(), createUserInput);
        return result.hasOwnProperty("data") && result.data.hasOwnProperty("result") ? result.data.result : result.data;
    }

    public async createVehicle(createUserInput: VehicleDto) {
        isUserLoggedIn();
        let result = await this.internalHttp.post(this.endpoint.Custom(`CreateVehicle`), createUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByClientId(clientId: string): Promise<PagedResultDto<VehicleDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByOwnerId?ownerId=${clientId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getByVin(vin: string): Promise<PagedResultDto<VehicleDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetByVin?vin=${vin}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new VehicleService();