declare var abp: any;

export function getGrandNodeLanguage(dataSet: any): any { 
    let languageToReturn = {};

    if(dataSet && dataSet.items.length > 0) {
        dataSet.items.some((language: any) => {
            if(getAbpLanguage() === language.UniqueSeoCode.toLowerCase()) {
                languageToReturn = language;
                return true;
            }
            return false;
        });
    }

    return languageToReturn;
}

export function getLocaleName(Locales: any, gnLanguage: any): string {
    let localeName: string = "";

    if(!!Locales) {
        Locales.some((locale: any) => {
            if(locale.LanguageId === gnLanguage.Id && locale.LocaleKey === 'Name') {
                localeName = locale.LocaleValue;
                return true;
            }
            return false;
        });
    }

    return localeName;
}

export function getAbpLanguage(): string {
    return abp.localization.currentLanguage.name;
}