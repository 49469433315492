import { CrudStoreBase } from './crudStoreBase';
import { CountryFluentListDto } from '../services/country/countryFluentListDto';
import countryItemsService from '../services/country/countryItemsService';

class CountryItemsStore extends CrudStoreBase<CountryFluentListDto>{
	constructor() {
		super(countryItemsService, defaultCountryItems)
	}
}

export const defaultCountryItems = {
	id: '',
	name: '',
	code: '',
}

export default CountryItemsStore;