import { MessageBarType } from '@fluentui/react';
import { L } from '../../../lib/abpUtility';
import { defaultProduct } from '../../../stores/productStore';
import { Types } from '../../BaseComponents/controls';
import PanelBase, { IPanelProps } from './panelBase';

export interface IGenericPanelProps extends IPanelProps {
    modalType: string;
    payload?: any;
    store?: any;
    isBulkOperation?: boolean;
    createOrUpdate: (values: any) => Promise<void>;
}

export class GenericPanel extends PanelBase<IGenericPanelProps> {
    getModel() {
        return this.state.model.value;
    }

    // async onConfirm(): Promise<Boolean> {
    //     let confirmed = await super.onConfirm();
    //     if (!confirmed) return false;
    //     // todo handle http errors
    //     await this.props.createOrUpdate(this.getModel());
    //     this.toggleAsyncActionInProgressFlag(false, true);
    //     return true;
    // }

    async onConfirm(): Promise<Boolean> {
        let confirmed = await super.onConfirm();
        if (!confirmed) return false;

        // todo handle http errors
        let asyncResult: any;
        if(this.props.createOrUpdate && typeof this.props.createOrUpdate === 'function') {
            asyncResult = await this.props.createOrUpdate(this.state.model.value);
            this.toggleAsyncActionInProgressFlag(false, false);
        } else {
            asyncResult = await this.createOrUpdate(this.state.model.value);
            this.toggleAsyncActionInProgressFlag(false, true);
        }

        if(!!asyncResult) {
            return true;
        } else {
            return false;
        }
    }

    async createOrUpdate(values: any) {
        if (values && values.id) {
            // update
            await this.props.store.update(values);
            if (this.props.store.dataSet) {
                await this.props.store.getUpdated([values.id]);
            }
        } else {
            // create
            let result = await this.props.store.create(values);
            if (result && result.id) {
                await this.props.store.getUpdated([values.id]);
            }
        }
        this.setState({ message: { text: L('Saved sucessfully.'), type: MessageBarType.success } });
    }
}

export class ProductDefinition {
    static Get(): ITypeDefinition {
        return {
            name: "Product",
            type: Types.Object,
            members: DefinitionParser.generateDefinition(defaultProduct)
        }
    }
}

export class DefinitionParser {
    static generateDefinition(object: any): ITypeDefinition[] {
        let array: ITypeDefinition[] = [];
        for (let prop in object) {
            if (!object.hasOwnProperty(prop)) continue;
            array.push({
                name: prop,
                type: typeof (object[prop]),
                members: []
            });
        }
        return array;
    }
}

export interface ITypeDefinition {
    // Name of type which will be referenced everywhere - defautl endpoint Path it is ! YODA
    name: string;
    // Type like DateTime, String, Number, Boolean, Reference Type One(Object), Reference Type Many(Array)
    // if it's reference ask it's many or one
    type: string;
    // Controls mean, that you want to change control type to some custome one
    control?: string;
    // Endpoint override path - if null name property is path
    endpoint?: string;
    members: ITypeDefinition[];
}