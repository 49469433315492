import { DefaultButton, PanelType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { L } from '../../../lib/abpUtility';
import { myTheme } from '../../../styles/theme';
import { createOrUpdateClassNames } from '../../BaseComponents/createOrUpdate';
import CustomPanel from '../../BaseComponents/customPanel';
import ContentViewBase, { ContentViewModel } from '../../BaseComponents/contentViewBase';
import { IContentViewProps } from '../../BaseComponents/IContentViewProps';
import { Container } from '../../../stores/storeInitializer';
import { IContentViewState } from '../../BaseComponents/IContentViewState';

export default class PanelBase<P extends IPanelProps> extends ContentViewBase<IPanelProps>  {
    constructor(props: P) {
        super(props);
        
        this.state = {
            // @ts-ignore
            model: { error: {}, value: {} },
            ...this.state
        };
    }

    static getDerivedStateFromProps(props: IPanelProps, state: IPanelState) {
        return { ...state, model: PanelBase.getModelFromProps(props, state) };
    }

    static getModelFromProps(props: IPanelProps, state: IPanelState): any {
        state.model.value = props.payload.model ? props.payload.model : props.payload;
        return state.model;
    }

    render() {
        const { isOpen } = this.props;
        Container.EventBus.customErrorHandling = isOpen;

        return <CustomPanel
            headerText={L(this.getPanelTitle())}
            isOpen={isOpen}
            onDismiss={this._onCancel}
            closeButtonAriaLabel={L('Close')}
            onRenderFooterContent={this.renderFooter}
            type={this.getPanelType()}
        >
            {this.renderContent()}
        </CustomPanel>
    }

    async onConfirm(): Promise<Boolean> {
        let definition = this.getDefinition();

        let output = true;
        let newModel: PanelModel;
        for (const element of definition.properties) {
            let value = this.state.model.value[element.id];
            if ((!value) && element.isRequired) {
                newModel = this.state.model;
                newModel.error[element.id] = L('ThisFieldIsRequired');
                this.setState({ model: newModel });
                output = false;
            }
            else {
                newModel = this.state.model;
                newModel.error[element.id] = '';
                this.setState({ model: newModel });
            }
        }

        return output;
    }

    _onCancel = () => {
        this.props.onClose(true);
        this.setState({ model: { error: {}, value: {} } });
    }

    _onConfirm = async () => {
        this.toggleAsyncActionInProgressFlag(true, true);

        let result = await this.onConfirm();

        if(result) {
            this.props.onClose(false);
            this.setState({ model: { error: {}, value: {} } });
        } else if(result === false) {
            this.toggleAsyncActionInProgressFlag(false, true);
        }
    }

    renderFooter = () => {
        return (
            <div className={createOrUpdateClassNames.panelActions}>
                {this.renderConfirm()}
                {this.renderCancel()}
                {this.asyncActionInProgress && <Spinner label={L('Please wait...')} className={createOrUpdateClassNames.loadSpinner} size={SpinnerSize.large} ariaLive="assertive" labelPosition="right" />}
            </div>
        );
    };

    renderConfirm = () => {
        return <PrimaryButton theme={myTheme} onClick={this._onConfirm} text={L('Save')} disabled={this.asyncActionInProgress} />
    };

    renderCancel = () => {
        return <DefaultButton theme={myTheme} onClick={this._onCancel} text={L('Cancel')} />
    };

    getPanelType(): PanelType {
        return PanelType.medium;
    }

    getPanelTitle(): string {
        return L("TITLE");
    }
}

export interface IPanelProps extends IContentViewProps {
    isOpen: boolean;
    history?: any;
    customData?: any;
    onClose: (calledFromButton: boolean) => void;
    createOrUpdate: (values: any) => Promise<void>;
}

export interface IPanelState extends IContentViewState {}

export class PanelModel extends ContentViewModel {}