import { CrudStoreBase } from './crudStoreBase';
import { CalculationDto } from '../services/calculation/dto/calculationDto';
import calculationService from '../services/calculation/calculationService';
import { defaultClient } from './clientStore';

class CalculationStore extends CrudStoreBase<CalculationDto>{
  constructor() {
    super(calculationService, defaultCalculation)
  }
}

export const defaultCalculation = {
  id: '',
  client: defaultClient,
  clientId: 0,
  creationTime: '',
  creatorUserId: 0,
  deleterUserId: null,
  deletionTime: null,
  isDeleted: false,
  lastModificationTime: null,
  lastModifierUserId: null,
  payload: '',
  vehicleRegistrationNumber: '',
}

export default CalculationStore;