export const Controls = {
    Text: "Text",
    Date: "Date",
    Picker: "Picker",
    MultiPicker: "MultiPicker",
    CheckBox: "CheckBox",
    CheckBoxOptions: "CheckBoxOptions",
    ChoiceGroup: "ChoiceGroup",
    Time: "Time",
    TableInputs: "TableInputs",
    ComboBox: "ComboBox",
    CountriesComboBox: "CountriesComboBox",
    CountrySearchList: "CountrySearchList",
    SportSearchList: "SportSearchList",
}

export const Types = {
    Bool: "Bool",
    Number: "Number",
    String: "String",
    Date: "Date",
    Time: "Time",
    Object: "Object",
    Array: "Array"
}

export const ModalTypes = {
    Create: "create",
    Update: "update",
}