import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { CreateRoleInput } from './dto/createRoleInput';
import { RoleDto } from './dto/roleDto';

export class RoleService extends CrudServiceBase<RoleDto, CreateRoleInput> {
    constructor() {
        super(Endpoint.Role);
    }
}

export default new RoleService();