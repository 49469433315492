import { CrudStoreBase } from './crudStoreBase';
import { SportInsuranceCoverageDto } from '../services/sportInsuranceCoverage/dto/sportInsuranceCoverageDto';
import sportInsuranceCoverageService from '../services/sportInsuranceCoverage/sportInsuranceCoverageService';
import { defaultInsurer } from './insurerStore';
import { defaultSportDiscipline } from './sportDisciplineStore';

class SportInsuranceCoverageStore extends CrudStoreBase<SportInsuranceCoverageDto>{
  constructor() {
    super(sportInsuranceCoverageService, defaultSportInsuranceCoverage)
  }

  public async getByInsurerId(insurerId: string) {
		await sportInsuranceCoverageService.getByInsurerId(insurerId).then((result: any) => {
			this.dataSet = result;
		}).catch((error: any) => {
			console.error(error);
		});
	}
}

export const defaultSportInsuranceCoverage = {
  id: '',
  sportDisciplineId: 0,
  sportDiscipline: defaultSportDiscipline,
  insurerId: 0,
  insurer: defaultInsurer,
  coverageType: '',
}

export default SportInsuranceCoverageStore;