import { isUserLoggedIn } from '../../utils/authUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import { PagedResultRequestDto } from '../base/pagedResultRequestDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ClientDto } from './dto/clientDto';
import { ClientStructureDto } from './dto/clientStructureDto';

declare var abp: any;

export class ClientService extends CrudServiceBase<ClientDto> {
    constructor() {
        super(Endpoint.Client);
        this.internalHttp = httpApi;
    }

    public async getAllDeletedInLast30Days(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<ClientDto>> {
        isUserLoggedIn();
        let result = await this.internalHttp.get(this.endpoint.Custom('GetAllDeletedInLast30Days'), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createUserInput: ClientDto) {
        isUserLoggedIn();
        let newCreateUserInput = getPartialModel(createUserInput, [], ['agreementsPayload', 'agreementsStatus']);
        newCreateUserInput['id'] = 0;
        newCreateUserInput['user']['id'] = 0;
        newCreateUserInput['userId'] = abp.session.userId;

        if(typeof newCreateUserInput['superAgentId'] !== 'number') {
            newCreateUserInput['superAgentId'] = null;
        }

        if(newCreateUserInput['pkDs'] && !Array.isArray(newCreateUserInput['pkDs'])) {
            newCreateUserInput['pkDs'] = Object.values(newCreateUserInput['pkDs']);
        }

        if(newCreateUserInput['user'] && !Array.isArray(newCreateUserInput['user']['roleNames']) && typeof newCreateUserInput['user']['roleNames'] === 'object' && 
            Object.keys(newCreateUserInput['user']['roleNames']).length === 0
        ) {
            newCreateUserInput['user']['roleNames'] = [];
        }

        if(newCreateUserInput['user'] && !Array.isArray(newCreateUserInput['user']['organizationUnitIds']) && typeof newCreateUserInput['user']['organizationUnitIds'] === 'object' && 
            Object.keys(newCreateUserInput['user']['organizationUnitIds']).length === 0
        ) {
            newCreateUserInput['user']['organizationUnitIds'] = [];
        }
        
        let result = await this.internalHttp.post(Endpoint.Client.Create(), newCreateUserInput);
        
        if (!!result.data && result.data.hasOwnProperty('result')) {
            result.data.result.clientId = result.data.result.id;
            await this.updateClientStructure(result.data.result);
            return result.data.result;
        } else {
            return result.data;
        }
    }

    public async createOrUpdate(createUserInput: ClientDto) {
        isUserLoggedIn();
        let newCreateUserInput = getPartialModel(createUserInput, [], ['agreementsPayload', 'agreementsStatus']);

        if(newCreateUserInput['user'] && !Array.isArray(newCreateUserInput['user']['roleNames']) && typeof newCreateUserInput['user']['roleNames'] === 'object' && 
            Object.keys(newCreateUserInput['user']['roleNames']).length === 0
        ) {
            newCreateUserInput['user']['roleNames'] = [];
        }

        if(newCreateUserInput['user'] && !Array.isArray(newCreateUserInput['user']['organizationUnitIds']) && typeof newCreateUserInput['user']['organizationUnitIds'] === 'object' && 
            Object.keys(newCreateUserInput['user']['organizationUnitIds']).length === 0
        ) {
            newCreateUserInput['user']['organizationUnitIds'] = [];
        }

        if(typeof newCreateUserInput['superAgentId'] !== 'number') {
            newCreateUserInput['superAgentId'] = null;
        }

        if(newCreateUserInput['pkDs'] && !Array.isArray(newCreateUserInput['pkDs'])) {
            newCreateUserInput['pkDs'] = Object.values(newCreateUserInput['pkDs']);
        }

        for(let key in newCreateUserInput) {
            if(newCreateUserInput.hasOwnProperty(key)) {
                if(typeof newCreateUserInput[key] === 'undefined') {
                    newCreateUserInput[key] = "";
                }
            }
        }

        let result = await this.internalHttp.post(Endpoint.Client.Custom(`CreateOrUpdate`), newCreateUserInput);
        return !!result.data ? result.data : result;
    }

    public async update(updateUserInput: ClientDto) {
        isUserLoggedIn();
        let newUpdateUserInput = getPartialModel(updateUserInput, [], ['owner', 'agreementsPayload', 'agreementsStatus', 'lastModificationTime', 'lastModifierUserId', 'creatorUserId', 'creationTime'], false);

        if(typeof newUpdateUserInput['superAgentId'] !== 'number') {
            newUpdateUserInput['superAgentId'] = null;
        }

        let result = await this.internalHttp.put(this.endpoint.Update(), newUpdateUserInput);

        if(newUpdateUserInput['pkDs'] && !Array.isArray(newUpdateUserInput['pkDs'])) {
            newUpdateUserInput['pkDs'] = Object.values(newUpdateUserInput['pkDs']);
        }
        
        if (!!result.data && result.data.hasOwnProperty('result')) {
            result.data.result.clientId = result.data.result.id;
            await this.updateClientStructure(result.data.result);
            return result.data.result;
        } else {
            return result.data;
        }
    }

    public async updateClientStructure(clientStructureInput: ClientStructureDto) {
        isUserLoggedIn();
        let result = await this.internalHttp.put(Endpoint.Client.Custom(`UpdateClientStructure`), clientStructureInput);
        return !!result.data && result.data.hasOwnProperty('result') ? result.data.result : result.data;
    }

    public async copyClient(clientId: number) {
        isUserLoggedIn();
        let result = await this.internalHttp.post(this.endpoint.Custom(`Copy/?clientId=${clientId}`));
        return !!result.data && result.data.hasOwnProperty('result') ? result.data.result : result.data;
    }

    public async restoreClient(clientId: number) {
        isUserLoggedIn();
        return await this.internalHttp.post(this.endpoint.Custom(`Restore/?clientId=${clientId}`));
    }

    public async getAttachedFiles(clientId: string) {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetAttachedFiles/?clientId=${clientId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getAllLeasing() {
        isUserLoggedIn();
        let result = await httpApi.get(this.endpoint.Custom(`GetAllLeasing`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getColumnValue(payload: any) {
        isUserLoggedIn();
        let result = await httpApi.post(this.endpoint.Custom(`GetColumnValue`), payload);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new ClientService();